import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { WebAdvisorAdd } from "@/types/Api";

import AdvisorDetailForm from "../../../-components/DetailsForms/AdvisorDetailForm";
import { useOASISAdvisor } from "../../../-data/hooks";
import { OASISAdvisorDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/advisor/_details/$id/",
)({
  component: () => <AdvisorDetailPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: OASISAdvisorDetailLoader,
});

function AdvisorDetailPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/advisor/_details/$id/",
  });
  const { data } = useOASISAdvisor(id);
  const form = useForm<WebAdvisorAdd>({ defaultValues: data });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  return (
    <MainDashboard>
      <AdvisorDetailForm
        onEdit={() =>
          void navigate({ to: "/oasis/advisor/$id/edit", params: { id } })
        }
        onClose={() => void navigate({ to: "/oasis/advisor" })}
        title={t("menu.details")}
        form={form}
      />
    </MainDashboard>
  );
}
