import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { MainDashboard } from "@/components/MainDashboard";
import { useOnRowTable } from "@/hooks/useOnRowTable";

import {
  useListTargetCustomer,
  useListTargetCustomerFilter,
} from "../-data/hooks";
import { targetCustomerLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/customers/_list/")({
  component: () => <TargetCustomerListPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: targetCustomerLoader,
});

function TargetCustomerListPage() {
  const { data, isFetching } = useListTargetCustomer();
  const { filter: targetCustomerFilter, setFilter: setTargetCustomerFilter } =
    useListTargetCustomerFilter();
  const navigate = useNavigate();
  const onRow = useOnRowTable({ to: "/customers/$id" });

  const handlePaginateClick = (page: number) => {
    setTargetCustomerFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };
  const { t } = useTranslation("dashboard");

  const handleChange = (search: string) => {
    setTargetCustomerFilter((prev) => {
      return {
        ...prev,
        search,
      };
    });
  };
  const handleAddNew = () => {
    void navigate({ to: "/customers/add" });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          search={targetCustomerFilter.search}
          onAddNewClick={handleAddNew}
          title={t("menu.list")}
          onChange={handleChange}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: targetCustomerFilter.page,
            onChange: handlePaginateClick,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/customers/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: "Name",
              key: "name",
              render: (_, record) => {
                return <Typography.Text>{record.name}</Typography.Text>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
