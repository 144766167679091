import { createFileRoute, useParams } from "@tanstack/react-router";
import { Table } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { DATE_ONLY } from "@/lib/constants";
import { calculateTableRecordByIndex, upperFirstLetter } from "@/lib/utils";

import WorkshopsHeader from "../../../-components/ListHeaders/WorkshopsHeader";
import {
  useOASISWorkshops,
  useOASISWorkshopsFilter,
} from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/skill/_workshops/$id/",
)({
  component: () => <OASISSkillDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function OASISSkillDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/skill/_workshops/$id/",
  });
  const { filter, setFilter } = useOASISWorkshopsFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useOASISWorkshops(+id);
  const dataSource = useDataSource(data?.results);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <WorkshopsHeader
          skillId={Number(id)}
          onSearchChange={handleSearchChange}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, __, index) =>
                calculateTableRecordByIndex(index, filter?.page),
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => record.en_name || record.name,
            },
            {
              title: t("menu.OASIS.type"),
              key: "type",
              render: (_, record) =>
                upperFirstLetter(record.type.replace("_", "-").toLowerCase()),
            },
            {
              title: t("menu.date"),
              key: "cohort",
              render: (_, record) => dayjs(record.date).format(DATE_ONLY),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
