import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";

import SpeakerDetailForm from "../-components/SpeakerDetailForm";
import { useSpeakerDetails } from "../../-data/hooks";
import { eventSpeakerDetailLoader } from "../../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/events/speakers/$id/")({
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: eventSpeakerDetailLoader,
  component: () => <WrapSpeakerDetail />,
});

const WrapSpeakerDetail = () => {
  const { id } = useParams({ from: "/_auth/_dashboard/events/speakers/$id/" });
  const { data } = useSpeakerDetails(id);
  const form = useForm({
    defaultValues: data,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({ to: "/events/speakers" });
  };
  const handleEdit = () => {
    void navigate({
      to: "/events/speakers/$id/edit",
      params: { id: data?.id?.toString() ?? "" },
    });
  };

  return (
    <MainDashboard>
      <SpeakerDetailForm
        onEdit={handleEdit}
        onClose={handleClose}
        form={form}
        title={t("menu.details")}
      />
    </MainDashboard>
  );
};
