import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { DATE_ONLY } from "@/lib/constants";
import { WebAdvisingGroupAdd } from "@/types/Api";

import AdvisingGroupForm from "../../../-components/DetailsForms/AdvisingGroupForm";
import { advisingGroupResolver } from "../../../-data";
import {
  useOASISAdvisingGroup,
  useUpdateOASISAdvisingGroup,
} from "../../../-data/hooks";
import { OASISAdvisingGroupLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/advising-group/_details/$id/edit",
)({
  component: () => <EditAdvisingGroupPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: OASISAdvisingGroupLoader,
});

function EditAdvisingGroupPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/advising-group/_details/$id/edit",
  });
  const { data } = useOASISAdvisingGroup(id);
  const form = useForm<WebAdvisingGroupAdd>({
    defaultValues: {
      ...data,
      advising_members: data?.members?.map((item) => item.id),
      generic_advisor: data?.generic_advisor.id,
      academic_advisor: data?.academic_advisor?.id,
      peer_advisor: data?.peer_advisor?.id,
    },
    resolver: advisingGroupResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateOASISAdvisingGroup();

  const handleSubmit = (params: WebAdvisingGroupAdd) => {
    mutate(
      { id, data: { ...params, date: dayjs(params.date).format(DATE_ONLY) } },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t(
                "menu.OASIS.advisingGroup",
                "advising group",
              ).toLowerCase(),
            }),
          });
          void navigate({ to: "/oasis/advising-group/$id", params: { id } });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t(
                "menu.OASIS.advisingGroup",
                "advising group",
              ).toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <AdvisingGroupForm
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
        isEdit
        onClose={() => void navigate({ to: "/oasis/advising-group" })}
        title={t("menu.edit")}
        form={form}
      />
    </MainDashboard>
  );
}
