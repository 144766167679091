import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";

import { useCategoriesFilter } from "../-data/hooks";

interface Props {
  isLoading?: boolean;
}

const NewCategoryHeader = ({ isLoading }: Props) => {
  const { t } = useTranslation("dashboard");
  const { setFilter: setCategoriesFilter } = useCategoriesFilter();
  const navigate = useNavigate();

  const handleSearch = (search: string) => {
    setCategoriesFilter((prev) => {
      return {
        ...prev,
        page: 1,
        search,
      };
    });
  };

  const handleAddNewClick = () => {
    void navigate({ to: "/news/category/add" });
  };

  return (
    <ListHeader
      isLoading={isLoading}
      title={t("menu.categories")}
      onChange={handleSearch}
      onAddNewClick={handleAddNewClick}
    />
  );
};

export default NewCategoryHeader;
