import { Form, Input, Modal, Select, Typography } from "antd";
import dayjs from "dayjs";
import { Controller, UseFormReturn } from "react-hook-form";

import { DatePicker } from "@/components";
import { DATE_ONLY } from "@/lib/constants";
import { NotiRecurrenceForm } from "@/types";

import { RECURRENCE } from "../../-data";
import WeekDays from "./WeekDays";

interface Props {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  recurrenceForm: UseFormReturn<NotiRecurrenceForm>;
  recurrenceText?: string;
}

const RecurrenceModal = ({
  open,
  onCancel,
  onOk,
  recurrenceText,
  recurrenceForm,
}: Props) => {
  const handleOk = () => {
    void recurrenceForm.handleSubmit(onOk)();
  };

  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      bodyStyle={{ paddingBlock: 16 }}
    >
      <Typography.Text className="font-semibold text-xl">
        Repeat Setting
      </Typography.Text>
      <div className="mt-3 flex flex-col gap-4">
        <Form layout="vertical">
          <Controller
            control={recurrenceForm.control}
            name="start"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      Start
                    </Typography.Text>
                  }
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                  required
                >
                  <DatePicker
                    value={value ? dayjs(value, DATE_ONLY) : undefined}
                    onChange={(e) => {
                      onChange(e ? e.format(DATE_ONLY).toString() : null);
                    }}
                    picker="date"
                    className="w-full"
                  />
                </Form.Item>
              );
            }}
          />
          <div>
            <Typography.Text className="font-medium">
              Repeat every
            </Typography.Text>
            <div className="grid grid-cols-6 gap-2">
              <Controller
                control={recurrenceForm.control}
                name="repeatEvery"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Form.Item
                      validateStatus={error ? "error" : ""}
                      required
                      className="whitespace-nowrap w-full mb-2"
                    >
                      <Input
                        value={value}
                        onChange={(e) => {
                          if (!Number.isNaN(+e.target.value)) {
                            onChange(+e.target.value);
                          }
                        }}
                        className="col-span-1 mt-1 text-center"
                      />
                    </Form.Item>
                  );
                }}
              />
              <Controller
                control={recurrenceForm.control}
                name="type"
                render={({ field }) => {
                  return (
                    <Form.Item className=" mt-1 mb-2 col-span-5">
                      <Select
                        {...field}
                        options={[{ label: "Week", value: RECURRENCE.WEEKLY }]}
                      />
                    </Form.Item>
                  );
                }}
              />
            </div>
          </div>
          <Controller
            control={recurrenceForm.control}
            name="weekdays"
            render={({ fieldState: { error } }) => {
              return (
                <Form.Item
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <WeekDays recurrenceForm={recurrenceForm} />
                </Form.Item>
              );
            }}
          />
          <div>
            <Controller
              control={recurrenceForm.control}
              name="end"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Form.Item
                    label={
                      <Typography.Text className="font-medium">
                        End
                      </Typography.Text>
                    }
                    validateStatus={error ? "error" : ""}
                    help={error?.message}
                    required
                  >
                    <DatePicker
                      onChange={(e) => {
                        onChange(e ? e.format(DATE_ONLY).toString() : null);
                      }}
                      value={value ? dayjs(value, DATE_ONLY) : undefined}
                      picker="date"
                      className="w-full"
                    />
                  </Form.Item>
                );
              }}
            />
          </div>
          <div>
            <span>{recurrenceText}</span>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default RecurrenceModal;
