import { GlobalOutlined } from "@ant-design/icons";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Image, Button, Typography, Dropdown, message } from "antd";
import type { MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { PendingComponent } from "@/components";
import { authAtom, useAuth } from "@/jotai-atoms/oidc";

const { Paragraph, Title } = Typography;

const items: MenuProps["items"] = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "vi",
    label: "Vietnamese",
  },
];

const querySchema = yup.object({
  error: yup.string().optional(),
});
const throwError = (error: string) => {
  void message.error(error);
};
export const Route = createFileRoute("/_public/auth/login")({
  validateSearch: (search) =>
    querySchema.validateSync(search, { stripUnknown: true }),
  component: () => <Login />,
  pendingComponent: () => <PendingComponent />,
  loader: async ({ context: { globalStore } }) => {
    const auth = globalStore.get(authAtom);
    const user = await auth.getUser();
    if (user) {
      return redirect({ to: "/" });
    }
  },
});

const Login = () => {
  const auth = useAuth();
  const { t } = useTranslation("login");

  const loginWithSso = () => {
    auth.signinRedirect().catch((error: unknown) => {
      throwError("Something wrong happened, please try again later!");
      console.error(error);
    });
  };
  return (
    <div className="relative flex h-full justify-center bg-[#F0F2F5]">
      <Dropdown
        className="absolute right-3 top-2 z-10 cursor-pointer"
        menu={{ items, selectable: true, defaultSelectedKeys: ["en"] }}
        placement="bottomLeft"
      >
        <Button size="large" type="text" icon={<GlobalOutlined />}></Button>
      </Dropdown>
      <div className="my-[108px] flex w-full flex-col items-center gap-8 bg-[url('/BackgroundLogin.png')] bg-contain">
        <div className="text-center">
          <Image
            className="mb-3"
            width={156}
            src="/VinUniversity.png"
            alt=""
            preview={false}
          ></Image>
          <Paragraph className="opacity-45">{t("title")}</Paragraph>
        </div>
        <div className="border border-solid border-[#D9D9D9] bg-white p-6 text-center">
          <Title
            className="w-full border-b border-black border-opacity-5 text-center"
            level={4}
          >
            {t("signin.title")}
          </Title>
          <Paragraph className="pb-1.5 opacity-45">
            {t("signin.description")}
          </Paragraph>
          <Button type="primary" size="large" onClick={loginWithSso}>
            {t("signin.microsoft")}
          </Button>
        </div>
      </div>
    </div>
  );
};
