import { UploadOutlined } from "@ant-design/icons";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Typography,
  UploadFile,
  Card,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadPdf } from "@/components";
import { IAppConfigUpdate, useImage } from "@/hooks";
import { AppConfig } from "@/types/Api";

const { Title } = Typography;
interface IMobileConfig {
  type: "view" | "create";
  data: AppConfig | undefined;
  submitAction: {
    onSubmit: (data: IAppConfigUpdate, code: string) => void;
    isLoading: boolean;
  };
}

const ConfigMobile = ({ data, type, submitAction }: IMobileConfig) => {
  const { uploadImage } = useImage();
  const { t } = useTranslation("dashboard");
  const hasDefaultValue = type !== "create";
  const [isEditMode, setEditMode] = useState(!hasDefaultValue);
  const [wowHandbook, setWowHandbook] = useState<UploadFile[]>(
    data?.wow_handbook_url
      ? [
          {
            uid: crypto.randomUUID(),
            name: data.wow_handbook_url,
            status: "done",
            url: data.wow_handbook_url,
            thumbUrl: data.wow_handbook_url,
          },
        ]
      : [],
  );
  const [interStudentHandbook, setInterStudentHandbook] = useState<
    UploadFile[]
  >(
    data?.internation_student_handbook_url
      ? [
          {
            uid: crypto.randomUUID(),
            name: data.internation_student_handbook_url,
            status: "done",
            url: data.internation_student_handbook_url,
            thumbUrl: data.internation_student_handbook_url,
          },
        ]
      : [],
  );
  const [campusGuidelineHandbook, setCampusGuidelineHandbook] = useState<
    UploadFile[]
  >(
    data?.campus_guideline_handbook_url
      ? [
          {
            uid: crypto.randomUUID(),
            name: data.campus_guideline_handbook_url,
            status: "done",
            url: data.campus_guideline_handbook_url,
            thumbUrl: data.campus_guideline_handbook_url,
          },
        ]
      : [],
  );

  const onFinish = (values: IAppConfigUpdate) => {
    submitAction.onSubmit(
      {
        ...values,
        campus_guideline_handbook_url: campusGuidelineHandbook[0]?.url,
        international_student_handbook_url: interStudentHandbook[0]?.url,
        wow_handbook_url: wowHandbook[0].url,
      },
      data?.code ?? "",
    );
    setEditMode(false);
  };

  return (
    <div className="bg-white">
      <div className="flex justify-between px-6 pb-1.5 pt-4">
        <Title level={4}>{t("menu.config.mobile.title")}</Title>
        {!isEditMode && (
          <Button
            size="large"
            type="link"
            className="text-brand"
            icon={<EditOutlined />}
            onClick={() => {
              setEditMode(true);
            }}
          >
            {t("menu.config.mobile.editButton")}
          </Button>
        )}
      </div>
      <Divider className="m-0"></Divider>
      <Form
        name="mobile_config"
        layout="vertical"
        initialValues={{ remember: true }}
        className="m-6 grid grid-cols-2 gap-x-4"
        onFinish={onFinish}
      >
        <Form.Item
          name="emergency_contact"
          initialValue={hasDefaultValue ? data?.emergency_contact : undefined}
          className="col-span-2 mb-4"
        >
          <Form.List name="emergency_contact">
            {(fields, { add, remove }) => (
              <>
                <Card
                  title={t("menu.config.mobile.emergencyContact.title")}
                  size="small"
                  extra={
                    isEditMode ? (
                      <Button
                        className="border-brand bg-brand"
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        icon={<PlusOutlined />}
                      >
                        {t("menu.config.mobile.emergencyContact.addField")}
                      </Button>
                    ) : undefined
                  }
                >
                  {fields.length < 1 && (
                    <div className="flex justify-center">
                      <img
                        src="/NoData.svg"
                        alt={t("menu.config.mobile.emergencyContact.noData")}
                      />
                    </div>
                  )}
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <div key={key} className="grid grid-cols-2 gap-x-6">
                        <Form.Item
                          {...restField}
                          label={t(
                            "menu.config.mobile.emergencyContact.vietnameseName",
                          )}
                          className="w-full"
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "menu.config.mobile.emergencyContact.missingVietnameseName",
                              ),
                            },
                          ]}
                        >
                          <Input
                            className={hasDefaultValue ? "!text-gray-800" : ""}
                            disabled={!isEditMode}
                            placeholder={t(
                              "menu.config.mobile.emergencyContact.vietnameseName",
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t(
                            "menu.config.mobile.emergencyContact.englishName",
                          )}
                          className="w-full"
                          name={[name, "en_name"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "menu.config.mobile.emergencyContact.missingEnglishName",
                              ),
                            },
                          ]}
                        >
                          <Input
                            className={hasDefaultValue ? "!text-gray-800" : ""}
                            disabled={!isEditMode}
                            placeholder={t(
                              "menu.config.mobile.emergencyContact.englishName",
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("menu.phoneNumber")}
                          className="w-full col-span-2"
                          name={[name, "phone"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "menu.config.mobile.emergencyContact.missingPhoneNumber",
                              ),
                            },
                          ]}
                        >
                          <Input
                            className={hasDefaultValue ? "!text-gray-800" : ""}
                            disabled={!isEditMode}
                            placeholder={t("menu.phoneNumber")}
                          />
                        </Form.Item>

                        {isEditMode && (
                          <div className="col-span-2">
                            <Button
                              className="float-right"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                remove(name);
                              }}
                              type="primary"
                              danger
                            >
                              {t("menu.button.delete")}
                            </Button>
                          </div>
                        )}
                      </div>
                      {fields.length > 1 && fields.length - 1 !== key && (
                        <Divider className="mb-4" dashed />
                      )}
                    </>
                  ))}
                </Card>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          label={t("menu.config.mobile.campusGuidelineHandbook")}
          className="col-span-2"
        >
          <UploadPdf
            disabled={!isEditMode}
            onRemove={() => {
              setCampusGuidelineHandbook([]);
            }}
            customRequest={(options) => {
              void uploadImage(
                { file: options.file as File },
                {
                  onSuccess: (url) => {
                    setCampusGuidelineHandbook([
                      {
                        name: url,
                        url: url,
                        thumbUrl: url,
                        status: "done",
                        uid: crypto.randomUUID(),
                      },
                    ]);
                  },
                },
              );
            }}
            fileList={campusGuidelineHandbook}
          >
            {campusGuidelineHandbook.length === 0 && (
              <Button disabled={!isEditMode} icon={<UploadOutlined />}>
                {t("menu.config.mobile.clickToUpload")}
              </Button>
            )}
          </UploadPdf>
        </Form.Item>

        <Form.Item
          label={t("menu.config.mobile.internationalStudentHandbook")}
          className="col-span-2"
        >
          <UploadPdf
            disabled={!isEditMode}
            onRemove={() => {
              setInterStudentHandbook([]);
            }}
            customRequest={(options) => {
              void uploadImage(
                { file: options.file as File },
                {
                  onSuccess: (url) => {
                    setInterStudentHandbook([
                      {
                        name: url,
                        url: url,
                        thumbUrl: url,
                        status: "done",
                        uid: crypto.randomUUID(),
                      },
                    ]);
                  },
                },
              );
            }}
            fileList={interStudentHandbook}
          >
            {interStudentHandbook.length === 0 && (
              <Button disabled={!isEditMode} icon={<UploadOutlined />}>
                {t("menu.config.mobile.clickToUpload")}
              </Button>
            )}
          </UploadPdf>
        </Form.Item>

        <Form.Item
          label={t("menu.config.mobile.wowHandbook")}
          className="col-span-2"
        >
          <UploadPdf
            disabled={!isEditMode}
            onRemove={() => {
              setWowHandbook([]);
            }}
            customRequest={(options) => {
              void uploadImage(
                { file: options.file as File },
                {
                  onSuccess: (url) => {
                    setWowHandbook([
                      {
                        name: url,
                        url: url,
                        thumbUrl: url,
                        status: "done",
                        uid: crypto.randomUUID(),
                      },
                    ]);
                  },
                },
              );
            }}
            fileList={wowHandbook}
          >
            {wowHandbook.length === 0 && (
              <Button disabled={!isEditMode} icon={<UploadOutlined />}>
                {t("menu.config.mobile.clickToUpload")}
              </Button>
            )}
          </UploadPdf>
        </Form.Item>
        <Form.Item
          name="library_service_url"
          label={t("menu.config.mobile.libraryService")}
          initialValue={
            hasDefaultValue && data?.library_service_url
              ? data.library_service_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.libraryService")}
            />
          ) : (
            <a
              href={data?.library_service_url ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.library_service_url}
            </a>
          )}
        </Form.Item>
        <Form.Item
          name="student_policy_url"
          label={t("menu.config.mobile.studentPolicy")}
          initialValue={
            hasDefaultValue && data?.student_policy_url
              ? data.student_policy_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.studentPolicy")}
            />
          ) : (
            <a
              href={data?.student_policy_url ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.student_policy_url}
            </a>
          )}
        </Form.Item>
        <Form.Item
          name="faculty_policy_url"
          label={t("menu.config.mobile.facultyPolicy")}
          initialValue={
            hasDefaultValue && data?.faculty_policy_url
              ? data.faculty_policy_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.facultyPolicy")}
            />
          ) : (
            <a
              href={data?.faculty_policy_url ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.faculty_policy_url}
            </a>
          )}
        </Form.Item>
        <Form.Item
          name="code_of_conduct_url"
          label={t("menu.config.mobile.codeOfConduct")}
          initialValue={
            hasDefaultValue && data?.code_of_conduct_url
              ? data.code_of_conduct_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.codeOfConduct")}
            />
          ) : (
            <a
              href={data?.code_of_conduct_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.code_of_conduct_url}
            </a>
          )}
        </Form.Item>
        <Form.Item
          name="student_success_url"
          label={t("menu.config.mobile.studentSuccess")}
          initialValue={
            hasDefaultValue && data?.student_success_url
              ? data.student_success_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.studentSuccess")}
            />
          ) : (
            <a
              href={data?.student_success_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.student_success_url}
            </a>
          )}
        </Form.Item>
        <Form.Item
          name="campus_map_url"
          label={t("menu.config.mobile.campusMap")}
          initialValue={
            hasDefaultValue && data?.campus_map_url
              ? data.campus_map_url
              : undefined
          }
        >
          {isEditMode ? (
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              placeholder={t("menu.config.mobile.campusMap")}
            />
          ) : (
            <a
              href={data?.campus_map_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.campus_map_url}
            </a>
          )}
        </Form.Item>
        {isEditMode && (
          <div className="col-span-2">
            <Form.Item className="float-end">
              <Button
                size="large"
                htmlType="button"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                {t("menu.close")}
              </Button>
              <Button
                className="ml-5 border-brand bg-brand"
                type="primary"
                size="large"
                htmlType="submit"
              >
                {t("menu.save")}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ConfigMobile;
