import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { WebAdvisorAdd } from "@/types/Api";

import AdvisorDetailForm from "../../../-components/DetailsForms/AdvisorDetailForm";
import { advisorResolver } from "../../../-data";
import "../../../-data/hooks";
import { useOASISAdvisor, useUpdateOASISAdvisor } from "../../../-data/hooks";
import { OASISAdvisorDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/advisor/_details/$id/edit",
)({
  component: () => <AdvisorEditPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: OASISAdvisorDetailLoader,
});

function AdvisorEditPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/advisor/_details/$id/edit",
  });
  const { data } = useOASISAdvisor(id);
  const form = useForm<WebAdvisorAdd>({
    defaultValues: data,
    resolver: advisorResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateOASISAdvisor();

  const handleSubmit = (params: WebAdvisorAdd) => {
    mutate(
      { id, data: params },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.OASIS.advisor", "advisor").toLowerCase(),
            }),
          });
          void navigate({ to: "/oasis/advisor/$id", params: { id } });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.OASIS.advisor", "advisor").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <AdvisorDetailForm
        isEdit
        onClose={() => void navigate({ to: "/oasis/advisor" })}
        title={t("menu.details")}
        form={form}
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}
