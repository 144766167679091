import { QueryClient } from "@tanstack/react-query";
import {
  LoaderFnContext,
  RouterProvider,
  createRouter,
} from "@tanstack/react-router";
import "antd/dist/antd.variable.min.css";
import "ckeditor5/ckeditor5.css";
import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { globalStore } from "./jotai-atoms";
import "./lib/config/i18n";
import { queryClient } from "./lib/config/queryClient";
import { Providers } from "./providers";
import { routeTree } from "./routeTree.gen";

export interface RouterContext {
  title: string | null | undefined;
  queryClient: QueryClient;
  globalStore: typeof globalStore;
}

// Create a new router instance
export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultStaleTime: 0,
  context: {
    queryClient,
    title: null,
    globalStore,
  },
});

export interface LoaderType<TParams = unknown>
  extends LoaderFnContext<TParams, Record<string, unknown>, RouterContext> {}

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>,
);
