import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

import { CDN_URL } from "@/lib/constants";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { useFile } from "./file.hook";
import { useAppMutation } from "./useAppMutation";

export const useImage = () => {
  const { uploadFile, getFileUrl } = useFile();

  const uploadMultipleImages = useCallback(
    async (
      files: { file: File; overrideFilename?: string }[],
    ): Promise<string[]> => {
      await Promise.all(
        files.map((file) =>
          uploadFile({
            file: file.file,
            filename: file.overrideFilename,
          }),
        ),
      );
      return files.map((file) =>
        getFileUrl(file.overrideFilename ?? file.file.name),
      );
    },

    [uploadFile, getFileUrl],
  );
  const { mutateAsync: uploadMultipleImage } = useMutation({
    mutationFn: async (files: File[]) => {
      const queue = structuredClone(files);
      const results: string[] = [];
      while (queue.length > 0) {
        const file = queue.shift();
        if (file) {
          results.push(await uploadFile({ file }));
        } else {
          throw new Error("Something went wrong!");
        }
      }
      return results;
    },
  });

  const getImageUrl = useCallback(
    (path: string, size?: "100" | "500") => {
      if (!size) {
        return getFileUrl(path);
      }

      const items = path.split("/");
      const prefix = items.slice(0, -1);
      const filename = items[items.length - 1];
      return [...prefix, size, filename].join("/");
    },
    [getFileUrl],
  );

  const getOptimizedImageUrl = useCallback(
    (filename: string) => {
      const components = filename.split('/');
      const optimized = components.slice(0, components.length-1)
        // .concat(['500'])
        .concat(components.slice(components.length-1));
      const optimizedURL = optimized.join('/');
      return `${CDN_URL}/files/${optimizedURL}`;
    },
    [],
  );

  return {
    uploadImage: uploadFile,
    uploadMultipleImages,
    uploadMultipleImage,
    getImageUrl,
    getOptimizedImageUrl,
  };
};

export const useResizeImage = () => {
  return useAppMutation({
    mutationFn: async (
      params: Parameters<typeof fetchApiClient.images.resizeImage>[0],
    ) => {
      try {
        await fetchApiClient.images.resizeImage(params);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};
