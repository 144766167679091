import { ReactNode } from "react";

import { cn } from "@/lib/utils";

interface DaySelectorProps {
  children?: ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

const DaySelector = ({ children, isSelected, onClick }: DaySelectorProps) => {
  return (
    <div
      className={cn(
        "w-[36px] h-[36px] rounded-full flex items-center justify-center cursor-pointer",
        isSelected ? "bg-brand text-white" : "bg-transparent text-black",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default DaySelector;
