import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { formatFullDateTime } from "@/lib/utils";

import { NotificationStatisticType } from "../-data/store";
import NotificationStatus from "./NotificationStatus";

interface Props {
  statistic: NotificationStatisticType[];
}

const NotificationStatisticTable = ({ statistic }: Props) => {
  const { t } = useTranslation("dashboard");
  const columns = useRef<ColumnsType<NotificationStatisticType>>([
    {
      title: t("menu.notification.sentAt"),
      dataIndex: "sent_at",
      render: (_, record) => formatFullDateTime(record.sent_at),
      align: "center",
    },
    {
      title: t("menu.notification.total"),
      dataIndex: "total_device_count",
      align: "center",
    },
    {
      title: t("menu.notification.sent"),
      dataIndex: "success_device_count",
      align: "center",
    },
    {
      title: t("menu.notification.failures"),
      dataIndex: "fail_device_count",
      align: "center",
    },
    {
      title: t("menu.notification.status"),
      dataIndex: "status",
      render: (_, record) => <NotificationStatus status={record.status} />,
      align: "center",
    },
  ]);
  return (
    <Table
      pagination={false}
      className="border border-solid border-[#d9d9d9]"
      columns={columns.current}
      dataSource={statistic}
    />
  );
};

export default NotificationStatisticTable;
