import { createFileRoute, useParams } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { WebGroupEventMedia } from "@/types/Api";

import {
  useGroupEventMediaDetail,
  useUpdateEventMedia,
} from "../../../-data/hooks";
import { groupEventMediaDetailLoader } from "../../../-data/loaders";
import MediaDetail from "./-components/MediaDetail";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/groups/$id/media/$mediaId",
)({
  component: () => <WrapGroupEventDetail />,
  loader: groupEventMediaDetailLoader,
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
});

const WrapGroupEventDetail = () => {
  const { mediaId } = useParams({
    from: "/_auth/_dashboard/events/groups/$id/media/$mediaId",
  });
  const { data } = useGroupEventMediaDetail(mediaId);
  const { mutate: updateGroupEvent, isPending: isUpdateGroupEventLoading } =
    useUpdateEventMedia();
  const onSubmit = (data: WebGroupEventMedia & { id?: number }) => {
    updateGroupEvent(
      { ...data, id: Number(mediaId) },
      {
        onSuccess: () => {
          notification.success({
            message: "Update Group Event Media Success",
            description: "Group Event Media was successfully updated",
          });
        },
        onError: (err) => {
          notification.error({
            message: "Update group event media failed",
            description: (err as Error | undefined)?.message,
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <MediaDetail
        groupId={data?.group_event.toString() ?? ""}
        type="view"
        data={data}
        submitAction={{ onSubmit, isLoading: isUpdateGroupEventLoading }}
      />
    </MainDashboard>
  );
};
