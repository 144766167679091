import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Checkbox,
  Input,
  Select,
  Typography,
  UploadFile,
  Form,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DatePicker,
  LayoutDetailsFormProps,
  LayoutDetailsForm,
  UploadImage,
  Editor,
} from "@/components";
import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";

import { useAllBenefitTypes } from "../-data/hooks";
import { BenefitUpdate } from "../-data/validation";

const { RangePicker } = DatePicker;

const BenefitsForm = ({
  form,
  isSubmitLoading,
  onSubmit,
  title,
  onEdit,
  isEdit,
}: LayoutDetailsFormProps<BenefitUpdate>) => {
  const { uploadImage } = useImage();
  const formThumbnail = form.getValues("thumbnail");
  const [fileList, setFiledList] = useState<UploadFile[]>(
    formThumbnail
      ? [
          {
            uid: crypto.randomUUID(),
            name: formThumbnail,
            status: "done",
            url: formThumbnail,
            thumbUrl: formThumbnail,
          },
        ]
      : [],
  );
  const { data: benefitTypes } = useAllBenefitTypes();
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const { title: errorTitle, en_title } = form.formState.errors;

  useEffect(() => {
    if (errorTitle?.type === "required" && en_title?.type === "required") {
      notification.error({
        message: "Error",
        description: errorTitle.message,
      });
    }
  }, [
    en_title?.message,
    en_title?.type,
    errorTitle?.message,
    errorTitle?.type,
  ]);

  const handleClose = () => {
    void navigate({ to: "/benefits" });
  };

  const [startDate, endDate] = form.watch(["start_date", "end_date"]);

  const isDateError =
    form.formState.errors.start_date ?? form.formState.errors.end_date;

  return (
    <LayoutDetailsForm
      form={form}
      onClose={handleClose}
      isSubmitLoading={isSubmitLoading}
      title={title}
      onEdit={onEdit}
      onSubmit={onSubmit}
      isEdit={isEdit}
    >
      <Controller
        control={form.control}
        name="en_title"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
            >
              <Input
                {...field}
                disabled={!isEdit}
                value={field.value ?? ""}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="title"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.vnTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              required
              help={error?.message}
            >
              <Input
                {...field}
                status={error && "error"}
                disabled={!isEdit}
                value={field.value}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_content"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enContent")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="content"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.content")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="type"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.benefits.type.types")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Select
                {...field}
                showArrow
                maxTagTextLength={24}
                options={benefitTypes?.map((item) => {
                  return {
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    label: item.en_name || item.name,
                    value: item.id,
                  };
                })}
                placeholder={t("menu.select", {
                  what: t("menu.benefits.type.types"),
                })}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.date")}
          </Typography.Text>
        }
        validateStatus={isDateError ? "error" : ""}
        help={isDateError?.message}
        required
      >
        <RangePicker
          value={
            startDate && endDate
              ? [dayjs(startDate), dayjs(endDate)]
              : undefined
          }
          onChange={(_, values) => {
            const [start_date, end_date] = values;
            form.setValue("start_date", start_date);
            form.setValue("end_date", end_date);
          }}
          placeholder={[t("menu.startDate"), t("menu.endDate")]}
          className="w-full"
          disabled={!isEdit}
        />
      </Form.Item>
      <Controller
        control={form.control}
        name="published"
        render={({ field }) => {
          return (
            <Form.Item>
              <Checkbox {...field} disabled={!isEdit} checked={field.value}>
                {t("menu.published")}
              </Checkbox>
            </Form.Item>
          );
        }}
      />
      <div />
      <Controller
        control={form.control}
        name="en_eligibility"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.benefits.type.en_eligibility")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="eligibility"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.benefits.type.vn_eligibility")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_how_to_redeem"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.benefits.type.en_howToRedeem")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="how_to_redeem"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.benefits.type.vn_howToRedeem")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="thumbnail"
        render={({ fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.thumbnail")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <UploadImage
                disabled={!isEdit}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: isEdit,
                }}
                accept={SUPPORTED_IMAGE_TYPES.join(",")}
                onRemove={(file) => {
                  setFiledList((prev) => {
                    const result = prev.filter((item) => item.uid !== file.uid);
                    return [...result];
                  });
                  form.setValue("thumbnail", undefined);
                }}
                customRequest={(options) => {
                  void uploadImage(
                    { file: options.file as File },
                    {
                      onSuccess: (url) => {
                        form.setValue("thumbnail", url);
                        form.clearErrors("thumbnail");
                        setFiledList([
                          {
                            name: url,
                            url: url,
                            thumbUrl: url,
                            status: "done",
                            uid: crypto.randomUUID(),
                          },
                        ]);
                      },
                    },
                  );
                }}
                fileList={fileList}
                emptyComponent={isEdit ? null : undefined}
              >
                {isEdit && fileList.length === 0 && (
                  <div className="flex flex-col items-center gap-1">
                    <PlusOutlined className="" />
                    <span>{t("menu.upload")}</span>
                  </div>
                )}
              </UploadImage>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default BenefitsForm;
