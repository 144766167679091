import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";

import NotiTypesForm from "../../-components/NotiTypesForm";
import { useNotiTypesForm } from "../../-data/hooks";
import { notiTypeDetailsLoader } from "../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/types/$id/",
)({
  component: () => <NotiTypesDetailsPage />,
  loader: notiTypeDetailsLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const NotiTypesDetailsPage = () => {
  const { id } = useParams({
    from: "/_auth/_dashboard/notifications/types/$id/",
  });
  const { form } = useNotiTypesForm(id);
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({
      to: "/notifications/types",
      params: { id: id.toString() },
    });
  };

  const handleEdit = () => {
    void navigate({
      to: "/notifications/types/$id/edit",
      params: { id: id.toString() },
    });
  };

  return (
    <MainDashboard>
      <NotiTypesForm
        title={t("menu.details", { ns: "dashboard" })}
        form={form}
        onClose={handleClose}
        onEdit={handleEdit}
      />
    </MainDashboard>
  );
};
