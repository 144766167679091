import { createFileRoute } from "@tanstack/react-router";
import { useParams } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { transformHtmlContent } from "@/lib/utils";
import { GroupEventAdd } from "@/types/Api";

import GroupEventDetail from "../-components/GroupEventDetail";
import { useGroupEventDetails, useUpdateGroupEvent } from "../../-data/hooks";
import { groupEventDetailsLoader } from "../../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/events/groups/$id/")({
  component: () => <WrapGroupEventDetail />,
  loader: groupEventDetailsLoader,
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
});

const WrapGroupEventDetail = () => {
  const { id } = useParams({ from: "/_auth/_dashboard/events/groups/$id/" });
  const { data: groupEventDetails } = useGroupEventDetails(+id);
  const { mutate: updateGroupEvent, isPending: isUpdateGroupEventLoading } =
    useUpdateGroupEvent();

  const onSubmit = (data: GroupEventAdd & { id?: number }) => {
    updateGroupEvent(
      {
        ...data,
        description: transformHtmlContent(data.description),
        en_description: transformHtmlContent(data.en_description),
        id: Number(id),
      },
      {
        onSuccess: () => {
          notification.success({
            message: "Update Group Event Success",
            description: "Group Event was successfully updated",
          });
        },
        onError: (err) => {
          notification.error({
            message: "Update group event failed",
            description: (err as Error | undefined)?.message,
          });
        },
      },
    );
  };
  return (
    <MainDashboard>
      <GroupEventDetail
        data={groupEventDetails}
        submitAction={{ onSubmit, isLoading: isUpdateGroupEventLoading }}
        type="view"
      />
    </MainDashboard>
  );
};
