import { LoaderType } from "@/main";

import { targetCustomerQueries } from "../../customers/-data/store";
import { eventQueries } from "../../events/-data/store";
import { notificationAtoms, notificationQueries } from "./store";

export const notificationLoaders = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    notificationQueries.list(
      globalStore.get(notificationAtoms.filter.currentValueAtom),
    ),
  );
  void queryClient.ensureQueryData(notificationQueries.allNotiTypes());
};

export const notificationTypeLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    notificationQueries.listNotiTypes(
      globalStore.get(notificationAtoms.notiTypeFilter.currentValueAtom),
    ),
  );
};

export const notificationCreateLoader = ({
  context: { queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(targetCustomerQueries.all());
  void queryClient.ensureQueryData(eventQueries.all());
};

export const notificationDetailsLoader = (
  props: LoaderType<{ id: number | string }>,
) => {
  const {
    context: { queryClient, globalStore },
    params: { id },
  } = props;
  void queryClient.ensureQueryData(notificationQueries.details(id.toString()));
  void queryClient.ensureQueryData(
    notificationQueries.statistics(id.toString()),
  );
  void queryClient.ensureQueryData(
    notificationQueries.listNotiTypes(
      globalStore.get(notificationAtoms.notiTypeFilter.currentValueAtom),
    ),
  );
  notificationCreateLoader(props);
};

export const notiTypeDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(notificationQueries.notiTypesDetails(id));
};
