import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";

import BenefitsForm from "../../../-components/BenefitsForm";
import { useBenefitsDetails } from "../../../-data/hooks";
import { benefitsDetailLoader } from "../../../-data/loaders";
import { BenefitUpdate } from "../../../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/benefits/_list/(details)/$id/",
)({
  component: () => <BenefitsDetails />,
  loader: benefitsDetailLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const BenefitsDetails = () => {
  const { id } = useParams({ from: "/_auth/_dashboard/benefits/_list/$id/" });
  const { data } = useBenefitsDetails(Number(id));
  const form = useForm<BenefitUpdate>({
    defaultValues: {
      ...data,
      type: data?.type?.id,
    },
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleEdit = () => {
    void navigate({ to: "/benefits/$id/edit", params: { id } });
  };

  return (
    <MainDashboard>
      <BenefitsForm form={form} title={t("menu.details")} onEdit={handleEdit} />
    </MainDashboard>
  );
};
