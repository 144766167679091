import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Dropdown, notification, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { useUploadXlsx } from "@/hooks/useUploadXlsx";
import { cn, filterSelectOptions } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { OASIS_WORKSHOPS_OPTIONS } from "../../-data";
import { useOASISWorkshopsFilter } from "../../-data/hooks";
import { WorkshopsFilterState } from "../../-data/store";

interface Props {
  onSearchChange: (value: string) => void;
  skillId: number;
}

const WorkshopsHeader = ({ onSearchChange, skillId }: Props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { filter, setFilter } = useOASISWorkshopsFilter();
  const [workshopFilter, setWorkshopFilter] = useState<WorkshopsFilterState>({
    type: filter?.type,
  });
  const [open, setOpen] = useState(false);
  const { mutate, getFileName, isPending } = useUploadXlsx();

  const handleBeforeUpload = (file: File) => {
    mutate(
      { file },
      {
        onSuccess: (data) => {
          void fetchApiClient.oasisPrograms
            .importWorkshopsViaExcelFile({
              filename: getFileName(data),
              skill_id: skillId,
            })
            .then(() => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.OASIS.uploadSuccess"),
              });
            });
        },
      },
    );
    return false;
  };

  const shouldDisableResetBtn = !filter?.type;

  const handleAddNew = () => {
    void navigate({
      to: "/oasis/skill/$id/add",
      params: { id: String(skillId) },
    });
  };

  const handleResetFilter = () => {
    setFilter((prev) => ({ ...prev, page: 1, type: undefined }));
    setWorkshopFilter({ type: undefined });
    setOpen(false);
  };
  const handleApplyFilter = () => {
    setFilter((prev) => ({ ...prev, page: 1, type: workshopFilter?.type }));
    setOpen(false);
  };

  return (
    <ListHeader
      search={filter?.search}
      onChange={onSearchChange}
      onAddNewClick={handleAddNew}
      title={t("menu.OASIS.workshops")}
      upload={{
        isLoading: isPending,
        onUpload: handleBeforeUpload,
      }}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.OASIS.skill")}
                    </Typography.Text>
                    <Select
                      showArrow
                      allowClear
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: "type",
                      })}
                      className="w-full"
                      options={OASIS_WORKSHOPS_OPTIONS}
                      onChange={(type) => {
                        setWorkshopFilter((prev) => {
                          return {
                            ...prev,
                            type,
                          };
                        });
                      }}
                      value={workshopFilter?.type}
                      filterOption={filterSelectOptions}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "type",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default WorkshopsHeader;
