import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";

export const targetCustomerQueries = {
  list: (params: PaginateFilterState) =>
    queryOptions({
      queryKey: ["target-customer-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.targetCustomers.getListTargetCustomers(params);
        return response.data;
      },
      placeholderData: keepPreviousData,
    }),
  all: () =>
    queryOptions({
      queryKey: ["target-customer-list", "all"],
      queryFn: async () => {
        const response =
          await fetchApiClient.targetCustomers.getListTargetCustomers();
        return response.data;
      },
    }),
  details: (id: string) => {
    return queryOptions({
      queryKey: ["target-customer-details", id],
      queryFn: async () => {
        const response =
          await fetchApiClient.targetCustomers.getATargetCustomer(id);
        return response.data;
      },
    });
  },
  allRecipient: (id: string) => {
    return queryOptions({
      queryKey: ["target-customer-list", "all-recipient", id],
      queryFn: async () => {
        const response =
          await fetchApiClient.targetCustomers.getAllRecipientOfTargetCustomer(
            id,
          );
        return response.data;
      },
    });
  },
};

const targetCustomerFilterAtom = atomWithDebounce<PaginateFilterState>({
  page: 1,
  search: "",
  page_size: 10,
});

export const targetCustomerAtoms = {
  filter: targetCustomerFilterAtom,
  list: atomWithQuery((get) => {
    return targetCustomerQueries.list(
      get(targetCustomerFilterAtom.debouncedValueAtom),
    );
  }),
  all: atomWithQuery(() => targetCustomerQueries.all()),
  details: atomFamily((id: string) => {
    return atomWithQuery(() => targetCustomerQueries.details(id));
  }),
  allRecipient: atomFamily((id: string) => {
    return atomWithQuery(() => targetCustomerQueries.allRecipient(id));
  }),
};
