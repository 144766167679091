import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";

import { transformHtmlContent } from "@/lib/utils";
import { GroupEventAdd } from "@/types/Api";

import { useGroupIds } from "../-data/hooks";
import { useCreateGroupEvent } from "../-data/hooks";
import { MainDashboard } from "../../../../../components/MainDashboard";
import GroupEventDetail from "./-components/GroupEventDetail";

export const Route = createFileRoute("/_auth/_dashboard/events/groups/add")({
  component: () => <CreateGroupEvent />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

const CreateGroupEvent = () => {
  const navigate = useNavigate();
  const { mutate: createGroupEvent, isPending: isCreateGroupEventLoading } =
    useCreateGroupEvent();
  const onSubmit = (data: GroupEventAdd) => {
    createGroupEvent(
      {
        ...data,
        description: transformHtmlContent(data.description),
        en_description: transformHtmlContent(data.en_description),
      },
      {
        onSuccess: () => {
          notification.success({
            message: "Create Group Event Success",
            description: "Group Event created successfully",
          });
          window.scrollTo(0, 0);
          void navigate({ to: "/events/groups" });
        },
        onError: (err) => {
          notification.error({
            message: "Create Group Fail",
            description: (err as Error | undefined)?.message,
          });
        },
      },
    );
  };

  const groupIds = useGroupIds();

  return (
    <MainDashboard>
      <GroupEventDetail
        groupId={groupIds}
        submitAction={{ onSubmit, isLoading: isCreateGroupEventLoading }}
        type="create"
      ></GroupEventDetail>
    </MainDashboard>
  );
};
