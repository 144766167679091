import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { DATE_ONLY } from "@/lib/constants";
import { WebAdvisingGroupAdd } from "@/types/Api";

import AdvisingGroupForm from "../-components/DetailsForms/AdvisingGroupForm";
import { advisingGroupResolver } from "../-data";
import { useCreateOASISAdvisingGroup } from "../-data/hooks";
import { OASISAddAdvisingGroupLoader } from "../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/advising-group/add",
)({
  component: () => <AddAdvisingGroupPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  loader: OASISAddAdvisingGroupLoader,
});

function AddAdvisingGroupPage() {
  const form = useForm<WebAdvisingGroupAdd>({
    defaultValues: {},
    resolver: advisingGroupResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOASISAdvisingGroup();

  const handleSubmit = (params: WebAdvisingGroupAdd) => {
    mutate(
      {
        ...params,
        date: dayjs(params.date).format(DATE_ONLY),
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.create.success", {
              what: t(
                "menu.OASIS.advisingGroup",
                "advising group",
              ).toLowerCase(),
            }),
          });
          void navigate({ to: "/oasis/advising-group" });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.create.error", {
              what: t(
                "menu.OASIS.advisingGroup",
                "advising group",
              ).toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <AdvisingGroupForm
        isEdit
        onClose={() => void navigate({ to: "/oasis/advising-group" })}
        title={t("menu.edit")}
        form={form}
        onSubmit={handleSubmit}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
