import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { ISurveyForm } from "@/types";

const counsellingSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is required!"),
      options: yup.array().of(
        yup.object().shape({
          value: yup.string().required("Option value is required!"),
        }),
      ),
    }),
  ),
});

export const counsellingResolver = yupResolver(
  counsellingSchema,
) as unknown as Resolver<ISurveyForm>;
