import { createFileRoute, useParams } from "@tanstack/react-router";
import { Table } from "antd";
import { t } from "i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { formatFullDateTime } from "@/lib/utils";

import EventListRegisHeader from "../../-components/EventListRegisHeader";
import { EventRegistrationStatusMap } from "../../-data";
import {
  useEventRegistration,
  useEventRegistrationFilter,
} from "../../-data/hooks";
import { eventRegisListLoader } from "../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/_singleEvent/$id/registration",
)({
  component: () => <RegistrationPage />,
  loader: eventRegisListLoader,
  beforeLoad: () => {
    return {
      title: t("menu.events.listReg", { ns: "dashboard" }),
    };
  },
});

function RegistrationPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/events/_singleEvent/$id/registration",
  });
  const { eventRegisListFilter, setEventRegisListFilter } =
    useEventRegistrationFilter();
  const { data, isFetching } = useEventRegistration(id);

  const handlePaginateClick = (page: number) => {
    setEventRegisListFilter((prev) => {
      return {
        ...prev,
        page: page,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <EventListRegisHeader />
        <Table
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            disabled: isFetching,
            current: eventRegisListFilter.page,
            onChange: handlePaginateClick,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => record.id,
            },
            {
              title: "User",
              key: "user",
              render: (_, record) => record.user.email,
            },
            {
              title: "Rating",
              key: "rating",
              render: (_, record) => record.rating ?? "--",
            },
            {
              title: "Status",
              key: "status",
              render: (_, record) =>
                record.status
                  ? EventRegistrationStatusMap.get(record.status)?.label
                  : "--",
            },
            {
              title: "Created",
              key: "create",
              render: (_, record) => formatFullDateTime(record.created_at),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
