import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";

import TypesDetail from "../-components/TypesDetailForm";
import { useEventTypesDetails } from "../../-data/hooks";
import { eventTypesDetailsLoader } from "../../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/events/types/$id/")({
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: eventTypesDetailsLoader,
  component: () => <EventTypesDetails />,
});

const EventTypesDetails = () => {
  const { id } = useParams({ from: "/_auth/_dashboard/events/types/$id/" });
  const { data } = useEventTypesDetails(id);
  const form = useForm({
    defaultValues: data,
  });

  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({ to: "/events/types" });
  };
  const handleEdit = () => {
    void navigate({
      to: "/events/types/$id/edit",
      params: { id: data?.id?.toString() ?? "" },
    });
  };

  return (
    <MainDashboard>
      <TypesDetail
        title={t("menu.details")}
        onClose={handleClose}
        form={form}
        onEdit={handleEdit}
      />
    </MainDashboard>
  );
};
