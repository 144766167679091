import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Modal, notification, Switch, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime, mappingContentToBase64 } from "@/lib/utils";

import BenefitsListHeader from "../-components/BenefitsListHeader";
import {
  useBenefitListFilter,
  useListBenefits,
  useUpdateBenefits,
} from "../-data/hooks";
import { benefitsListLoader } from "../-data/loaders";
import { benefitsQueries } from "../-data/store";

export const Route = createFileRoute("/_auth/_dashboard/benefits/_list/")({
  component: () => <BenefitsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: benefitsListLoader,
});

function BenefitsPage() {
  const { data, isFetching } = useListBenefits();
  const { benefitsFilter, setBenefitsFilter } = useBenefitListFilter();
  const { handlePaginateChange } = useCreateHandleFilterFunc(setBenefitsFilter);
  const { t } = useTranslation("dashboard");
  const { mutateAsync } = useUpdateBenefits();
  const queryClient = useQueryClient();
  const onRow = useOnRowTable({ to: "/benefits/$id" });
  const dataSource = useDataSource(data?.results);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <BenefitsListHeader />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: benefitsFilter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          onRow={onRow}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/benefits/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.enTitle"),
              key: "title",
              render: (_, record) => {
                return (
                  <Typography.Text ellipsis>
                    {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                    {record.en_title || record.title || "-"}
                  </Typography.Text>
                );
              },
            },
            {
              title: t("menu.benefits.type.types"),
              key: "type",
              render: (_, record) => {
                return (
                  <span className="text-nowrap">{record.type?.en_name}</span>
                );
              },
            },

            {
              title: t("menu.updatedDate"),
              key: "updated_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.updated_at)}</span>;
              },
            },
            {
              title: t("menu.published"),
              key: "published",
              className: "!text-center",
              render: (_, record) => {
                return (
                  <div className="flex justify-center">
                    <Switch
                      onClick={(checked, e) => {
                        e.stopPropagation();
                        Modal.confirm({
                          title: checked
                            ? t("menu.confirmPublishedTitle", {
                                what: t("menu.benefits.benefits"),
                              })
                            : t("menu.confirmUnpublishedTitle", {
                                what: t("menu.benefits.benefits"),
                              }),
                          content: checked
                            ? t("menu.confirmPublishedDesc", {
                                what: t("menu.benefits.benefits"),
                              })
                            : t("menu.confirmUnpublishedDesc", {
                                what: t("menu.benefits.benefits"),
                              }),
                          autoFocusButton: null,
                          cancelButtonProps: {
                            size: "large",
                            className: "w-[108px]",
                          },
                          okButtonProps: {
                            size: "large",
                            className: "w-[108px] bg-brand-primary",
                          },
                          onOk: async () => {
                            await mutateAsync(
                              mappingContentToBase64(
                                {
                                  ...record,
                                  type: record.type?.id,
                                  created_by: record.created_by?.id,
                                  published: checked,
                                },
                                "summary",
                                "en_summary",
                                "eligibility",
                                "en_eligibility",
                                "content",
                                "en_content",
                                "how_to_redeem",
                                "en_how_to_redeem",
                              ),
                              {
                                onSuccess: () => {
                                  notification.success({
                                    message: t("menu.success"),
                                    description: t("menu.update.success", {
                                      what: t("menu.benefits.benefits"),
                                    }),
                                  });
                                  void queryClient.invalidateQueries({
                                    queryKey:
                                      benefitsQueries.list(benefitsFilter)
                                        .queryKey,
                                  });
                                },
                                onError: () => {
                                  notification.error({
                                    description: t("menu.update.error", {
                                      what: t("menu.benefits.benefits"),
                                    }),
                                    message: t("menu.error"),
                                  });
                                },
                                onSettled: () => {
                                  Modal.destroyAll();
                                },
                              },
                            );
                          },
                          maskClosable: true,
                        });
                      }}
                      checked={record.published}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
