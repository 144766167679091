import { TargetCustomer } from "@/types/Api";

type Roles = NonNullable<TargetCustomer["roles"]>[number];

export const TARGET_CUSTOMER_ROLES: { label: Roles; value: Roles }[] = [
  {
    label: "Staff",
    value: "Staff",
  },
  {
    label: "Student",
    value: "Student",
  },
];
