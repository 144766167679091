import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUserInfo } from "@/jotai-atoms/user";
import "@/lib/constants";
import {
  mappingContentToBase64,
  mappingContentWithHtmlHeaderTag,
} from "@/lib/utils";
import { NewCategory, News, NewsCreate, User } from "@/types/Api";

import NewsForm from "../-components/NewsForm";
import { useAllCategories, useCreateNews } from "../-data/hooks";
import { newsCategoriesListLoader } from "../-data/loaders";
import { newsResolver } from "../-data/validation";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/news/_list/add")({
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  loader: newsCategoriesListLoader,
  component: () => <AddNews />,
});

const defaultValues: News = {
  title: "",
  en_title: "",
  content: "",
  en_content: "",
  thumbnail: undefined,
  external_thumbnail_url: undefined,
};

function AddNews() {
  const userInfo = useUserInfo();
  const { data } = useAllCategories();
  const { mutate, isPending: isLoading } = useCreateNews();

  const form = useForm<
    NewsCreate & { created_by?: User; categories?: number[] }
  >({
    defaultValues: {
      ...defaultValues,
      categories: undefined,
      created_by: userInfo.data?.user,
      thumbnail: "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: newsResolver as unknown as Resolver<
      NewsCreate & { created_by?: User }
    >,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  function onSubmit(data: NewsCreate) {
    const mutateData = mappingContentToBase64(
      mappingContentWithHtmlHeaderTag(data, "content", "en_content"),
      "content",
      "en_content",
    );
    mutate(mutateData, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", { what: t("menu.news.news") }),
        });
        void navigate({ to: "/news" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", { what: t("menu.news.news") }),
        });
      },
    });
  }

  return (
    <MainDashboard>
      <NewsForm
        form={form}
        title={t("menu.add")}
        onSubmit={onSubmit}
        isSubmitLoading={isLoading}
        categories={(data ?? []) as NewCategory[]}
        isEdit
      />
    </MainDashboard>
  );
}
