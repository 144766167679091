import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  Divider,
  Form,
  Input,
  Upload,
  UploadFile,
  Typography,
  message,
  UploadProps,
  Modal,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useImage, useResizeImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { cn, debounce, getImageURL } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { WebGroupEventMedia } from "@/types/Api";

const { Title } = Typography;
const MAX_FILES = 30;

interface IMediaDetail {
  groupId: string;
  type: "view" | "create";
  data?: WebGroupEventMedia | undefined;
  submitAction: {
    onSubmit: (data: WebGroupEventMedia) => void;
    isLoading: boolean;
  };
}

const TypesDetail = ({ data, type, submitAction, groupId }: IMediaDetail) => {
  const hasDefaultValue = type !== "create";
  const [isEditMode, setEditMode] = useState(!hasDefaultValue);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageList, setImageList] = useState<UploadFile[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const { mutate: resizeImage } = useResizeImage();

  const { uploadImage, uploadMultipleImage, getOptimizedImageUrl } = useImage();
  useEffect(() => {
    if (data?.thumbnail) {
      setFileList([
        {
          uid: "-1",
          name: "thumbnail",
          status: "done",
          url: getImageURL(data.thumbnail),
        },
      ]);
    }
    if (data?.images) {
      setImageList(
        data.images.map((image, index) => ({
          uid: index.toString(),
          name: image,
          status: "done",
          url: getImageURL(image),
        })),
      );
    } else {
      console.error("Error: data.images is not an array.");
    }
  }, [data, getOptimizedImageUrl]);

  const handleThumbnailChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList([]);
    // Handle get file from Upload Component
    if (newFileList.length > 0) {
      setFileList([
        {
          uid: "-1",
          percent: 50,
          name:
            newFileList[newFileList.length - 1].originFileObj?.name ??
            "Image title",
          status: "uploading",
        },
      ]);

      const file = newFileList[newFileList.length - 1].originFileObj as File;
      void uploadImage(
        { file },
        {
          onSuccess: (thumbnailUrl) => {
            setFileList([
              {
                uid: "-1",
                name:
                  newFileList[newFileList.length - 1].originFileObj?.name ??
                  "Image title",
                status: "done",
                url: thumbnailUrl,
                thumbUrl: thumbnailUrl,
              },
            ]);
          },
          onError: (err) => {
            void message.error(
              `Error uploading file: ${(err as Error | undefined)?.message ?? ""}`,
            );
            setFileList([]);
          },
        },
      );
    }
  };

  const handleBeforeUpload = debounce((_: File, fileList: File[]) => {
    if (
      imageList.length >= MAX_FILES ||
      fileList.length + imageList.length > MAX_FILES
    ) {
      notification.error({
        message: t("menu.error"),
        description: `You can only upload ${MAX_FILES.toString()} images.`,
      });
      return false;
    }

    setImageList([
      ...imageList,
      {
        uid: "-1",
        percent: 50,
        name: "Image title",
        status: "uploading",
      },
    ]);
    void uploadMultipleImage(fileList, {
      onSuccess(urls) {
        resizeImage({ images: urls.map((item) => ({ path: item })) });
        setImageList([
          ...imageList,
          ...urls.map(
            (url) =>
              ({
                url,
                uid: crypto.randomUUID(),
                name: url,
                status: "done",
                thumbUrl: url,
              }) as UploadFile,
          ),
        ]);
      },
    });
  }, 100);

  //Handle preview thumnail
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isUploading, _] = useState(false);
  const handlePreviewThumbnail = () => {
    if (fileList[0].url) {
      setPreviewImage(fileList[0].url);
      setPreviewOpen(true);
    }
  };
  const handlePreviewImage = (file: UploadFile) => {
    setPreviewImage(file.url ?? "");
    setPreviewOpen(true);
  };
  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const handleRemoveImage = async (file: UploadFile) => {
    if (data?.thumbnail.startsWith("https://")) {
      data.thumbnail = new URL(data.thumbnail).pathname;
    }

    if (data?.id) {
      const { error } =
        await fetchApiClient.groupEventMedia.updateAGroupEventMedia(data.id, {
          title: data.title,
          en_title: data.en_title,
          thumbnail: data.thumbnail,
          images: data.images
            .filter((image) => image !== file.name)
            .map((item: string) =>
              item.startsWith("https://") ? new URL(item).pathname : item,
            ),
          group_event: data.group_event,
        });

      if (!error) {
        setImageList(imageList.filter((image) => image.uid !== file.uid));
      }
    }
  };

  const onFinish = (values: WebGroupEventMedia) => {
    const updatedValues = {
      ...values,
      group_event: data?.group_event ?? groupId,
      thumbnail: fileList[0].url
        ? fileList[0].url.replace(/^https?:\/\/[^\\/]+\/files\//, "")
        : "",
      images:
        imageList.length > 0
          ? imageList.map((image) =>
              image.url?.replace(/^https?:\/\/[^\\/]+\/files\//, ""),
            )
          : [],
    };

    if (hasDefaultValue && data?.id) {
      submitAction.onSubmit({
        ...updatedValues,
        id: data.id,
      } as WebGroupEventMedia);
    } else {
      submitAction.onSubmit(updatedValues as WebGroupEventMedia);
    }

    setEditMode(false);
  };
  return (
    <>
      <div className="bg-white">
        <div className="flex justify-between px-6 pb-1.5 pt-4">
          <Title level={4}>
            {type === "view" ? (isEditMode ? "Edit" : "View") : "Add"}
          </Title>
          {!isEditMode && (
            <Button
              size="large"
              type="link"
              className="text-brand"
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <Divider className="m-0"></Divider>
        <Form
          name="media_detail"
          layout="vertical"
          initialValues={{ remember: true }}
          className="m-6 grid grid-cols-2 gap-x-4"
          onFinish={onFinish}
        >
          <Form.Item
            name="en_title"
            label="EN-Title"
            rules={[{ required: true, message: "EN-Title is required!" }]}
            initialValue={hasDefaultValue ? data?.en_title : undefined}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder="Edit here..."
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="VN-Title"
            rules={[{ required: true, message: "VN-Title is required!" }]}
            initialValue={hasDefaultValue ? data?.title : undefined}
          >
            <Input
              className={hasDefaultValue ? "!text-gray-800" : ""}
              disabled={!isEditMode}
              placeholder="Edit here..."
            />
          </Form.Item>
          <Form.Item label="Thumbnail" className="col-span-2">
            <Upload
              listType="picture-card"
              fileList={fileList}
              disabled={!isEditMode}
              onChange={handleThumbnailChange}
              accept={SUPPORTED_IMAGE_TYPES.join(",")}
              onPreview={handlePreviewThumbnail}
              onRemove={() => {
                setFileList([]);
              }}
              maxCount={2}
            >
              <div>
                <PlusOutlined />
                <div>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item label="Images" className="col-span-2">
            <Upload
              listType="picture-card"
              beforeUpload={handleBeforeUpload}
              multiple
              fileList={imageList.map((item) => ({
                ...item,
                thumbUrl: getImageURL(item.url ?? ""),
              }))}
              disabled={!isEditMode || isUploading}
              accept={SUPPORTED_IMAGE_TYPES.join(",")}
              onPreview={handlePreviewImage}
              onRemove={handleRemoveImage}
            >
              <div>
                <PlusOutlined />
                <div>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          {!isEditMode && hasDefaultValue && (
            <Form.Item
              className="col-span-2"
              name="total"
              label="Total"
              initialValue={data?.total}
            >
              <Input
                className="!text-gray-800"
                disabled={!isEditMode}
                placeholder="Edit here..."
              />
            </Form.Item>
          )}
          <div className="col-span-2">
            <Form.Item className="float-end">
              <Button
                onClick={() => {
                  void navigate({
                    to: "/events/groups/$id/media",
                    params: { id: groupId },
                  });
                }}
                size="large"
                className={cn(!isEditMode ? "bg-brand" : "", "w-[114px]")}
                type={isEditMode ? "default" : "primary"}
                htmlType="submit"
              >
                {t("menu.close")}
              </Button>
              {isEditMode && (
                <Button
                  className="ml-5 border-brand bg-brand w-[114px]"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={submitAction.isLoading}
                >
                  {t("menu.save")}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
        <Modal
          open={previewOpen}
          title="Preview"
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="previewImage"
            style={{ width: "100%" }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
};

export default TypesDetail;
