import { useNavigate } from "@tanstack/react-router";
import { Button, Modal, notification, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { useCheckEventStatus } from "../-data/hooks";

const { Text } = Typography;

interface Props {
  selectedRowKeys: React.Key[];
  onCancel: () => void;
}

const EventGroupSelection = ({ selectedRowKeys, onCancel }: Props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate: checkEventStatus, isPending } = useCheckEventStatus();

  const handleCreateGroup = () => {
    checkEventStatus(selectedRowKeys as number[], {
      onSuccess: ({ status, error }) => {
        if (status) {
          void navigate({ to: "/events/groups/add" });
        } else {
          Modal.error({
            title: t("menu.error"),
            content: error,
          });
        }
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.events.group").toLocaleLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <div
      className="fixed bottom-0 z-50"
      style={{
        width: "calc(100% - 200px)",
      }}
    >
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.36) 100%)",
          backdropFilter: "blur(5px)",
        }}
        className="flex items-baseline justify-between border-b-0 border-l border-r-0 border-t border-solid border-[#D9D9D9] px-6 py-3"
      >
        <Text>
          Chosen <span className="font-bold">{selectedRowKeys.length}</span>{" "}
          item
        </Text>
        <div className="flex gap-5">
          <Button onClick={onCancel}>{t("menu.cancel")}</Button>
          <Button
            onClick={handleCreateGroup}
            className="border-brand bg-brand-primary"
            type="primary"
            loading={isPending}
          >
            {t("menu.events.addGroup")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EventGroupSelection;
