import { Checkbox, Form, Input, Typography } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { WebAdvisorAdd } from "@/types/Api";

interface Props extends LayoutDetailsFormProps<WebAdvisorAdd> {}

const AdvisorDetailForm = (props: Props) => {
  const { form } = props;
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm {...props}>
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                disabled={!props.isEdit}
                placeholder={t("menu.enterHere")}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="email"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              className="col-span-2"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.email")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                placeholder={t("menu.enterHere")}
                disabled={!props.isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="lecturer"
        render={({ field }) => {
          return (
            <Form.Item
              className="col-span-2"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.OASIS.Lecturer")}
                </Typography.Text>
              }
            >
              <Checkbox
                {...field}
                checked={field.value}
                disabled={!props.isEdit}
              >
                {t("menu.OASIS.Lecturer")}
              </Checkbox>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default AdvisorDetailForm;
