import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { WebGroupEventMedia } from "@/types/Api";

import { useCreateEventMedia } from "../../../-data/hooks";
import { groupEventDetailsLoader } from "../../../-data/loaders";
import MediaDetail from "./-components/MediaDetail";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/groups/$id/media/add",
)({
  component: () => <WrapGroupEventCreate />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  loader: groupEventDetailsLoader,
});

const WrapGroupEventCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams({
    from: "/_auth/_dashboard/events/groups/$id/media/add",
  });

  const { mutate: updateGroupEvent, isPending: isUpdateGroupEventLoading } =
    useCreateEventMedia();
  const onSubmit = (data: WebGroupEventMedia) => {
    updateGroupEvent(data, {
      onSuccess: () => {
        notification.success({
          message: "Create Group Event Media Success",
          description: "Group Event Media was successfully created",
        });
        void navigate({ to: "/events/groups/$id/media", params: { id } });
      },
      onError: (err) => {
        notification.error({
          message: "Created group event media failed",
          description: (err as Error | undefined)?.message,
        });
      },
    });
  };

  return (
    <MainDashboard>
      <MediaDetail
        groupId={id}
        type="create"
        submitAction={{ onSubmit, isLoading: isUpdateGroupEventLoading }}
      />
    </MainDashboard>
  );
};
