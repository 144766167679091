import { Card, Form, Radio, Typography } from "antd";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NotificationFormType, NotificationUserCategories } from "@/types";
import { EventReadInfo, ReadTargetCustomer } from "@/types/Api";

import ContentTargetType from "./ContentTargetType";

interface Props {
  form: UseFormReturn<NotificationFormType>;
  isEdit?: boolean;
  children?: React.ReactNode;
  targetCustomers: ReadTargetCustomer[];
  events: EventReadInfo[];
}

const Recipients = ({ form, isEdit, events, targetCustomers }: Props) => {
  const [targetType, setTargetType] = useState<NotificationUserCategories>(
    () => {
      const isHaveRelatedEventValue = form.getValues("related_event");
      if (isHaveRelatedEventValue) {
        return "event";
      }
      return form.getValues("to_all_user") ? "all_user" : "customer";
    },
  );

  const { t } = useTranslation("dashboard");
  return (
    <Form.Item
      label={
        <Typography.Text className="font-medium">
          {t("menu.notification.recipients")}
        </Typography.Text>
      }
      className="col-span-2"
    >
      <Card bodyStyle={{ paddingBlock: "16px" }}>
        <Form.Item
          label={
            <Typography.Text className="font-medium">
              {t("menu.notification.userCategories")}
            </Typography.Text>
          }
          className="mb-0"
          required
        >
          <Radio.Group
            value={targetType}
            onChange={(e) => {
              setTargetType(e.target.value as typeof targetType);
              form.setValue("target_customer", null);
              form.setValue("related_event", null);
              form.setValue(
                "to_all_user",
                (e.target.value as typeof targetType) === "all_user",
              );
            }}
            className="mb-4"
            disabled={!isEdit}
          >
            <Radio value="customer" className="mr-20">
              {t("menu.targetCustomer.targetCustomer")}
            </Radio>
            <Radio value="event" className="mr-20">
              {t("menu.events.eventRegistrants")}
            </Radio>
            <Radio value="all_user">{t("menu.notification.all")}</Radio>
          </Radio.Group>
        </Form.Item>
        <ContentTargetType
          targetCustomersOptions={targetCustomers.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })}
          targetType={targetType}
          eventsOptions={events.map((item) => {
            return {
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              label: item.en_title || item.title || "-",
              value: item.id,
            };
          })}
          form={form}
          isEdit={isEdit}
        />
      </Card>
    </Form.Item>
  );
};

export default Recipients;
