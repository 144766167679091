import { createFileRoute, Link } from "@tanstack/react-router";
import { Badge, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";

import ServiceRequestListHeader from "../-component/ServiceRequestListHeader";
import { ProxyStatus } from "../-data";
import {
  useListServiceRequest,
  useListServiceRequestFilter,
} from "../-data/hooks";
import { serviceRequestLoader } from "../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/service-requests/_list/",
)({
  loader: serviceRequestLoader,
  component: () => <SupportRequestPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function SupportRequestPage() {
  const { value: serviceRequestListState, set: setServiceRequestListState } =
    useListServiceRequestFilter();
  const { handlePaginateChange } = useCreateHandleFilterFunc(
    setServiceRequestListState,
  );
  const { data, isFetching } = useListServiceRequest();
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/service-requests/$id" });
  const dataSource = useDataSource(data?.results);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ServiceRequestListHeader isLoading={isFetching} />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: serviceRequestListState?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/service-requests/$id"
                    params={{ id: record.id?.toString() ?? "" }}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.subject"),
              key: "title",
              render: (_, record) => {
                return <Typography.Text>{record.subject}</Typography.Text>;
              },
            },
            {
              title: "Email",
              key: "email",
              render: (_, record) => {
                return <span>{record.created_by?.email}</span>;
              },
            },
            {
              title: t("menu.serviceRequest.type"),
              key: "request-type",
              render: (_, record) => {
                return <span>{record.case_type}</span>;
              },
            },
            {
              title: t("menu.serviceRequest.date"),
              key: "sent_date",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.created_at)}</span>;
              },
            },
            {
              title: t("menu.status"),
              key: "status",
              render: (_, record) => {
                return (
                  <Badge
                    status={ProxyStatus[record.status ?? "In-Progress"]}
                    text={record.status}
                    className="flex flex-nowrap items-center"
                  />
                );
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
