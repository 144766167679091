import { Badge, Typography } from "antd";

import { NotificationTemplate } from "@/types/Api";

interface Props {
  status: NotificationTemplate["status"];
}

const statusColors = {
  Created: "#1890FF",
  Started: "#2F54EB",
  Processing: "#FA8C16",
  "Partial Failure": "#FFA39E",
  Completed: "#52C41A",
  Failed: "#FF4D4F",
};

const getStatusText = (status: NotificationTemplate["status"]) => {
  switch (status) {
    case "Created":
    case "Started":
      return "Created";
    case "Processing":
      return "Processing";
    case "Partial Failure":
    case "Completed":
    case "Failed":
    default:
      return "Completed";
  }
};

const NotificationStatus = ({ status }: Props) => {
  const statusText = getStatusText(status);
  return (
    <Typography.Text>
      <Badge
        color={status ? statusColors[statusText] : statusColors.Completed}
      />{" "}
      {statusText}
    </Typography.Text>
  );
};

export default NotificationStatus;
