import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { EventType } from "@/types/Api";

import { useCreateEventType } from "../-data/hooks";
import { eventTypeResolver } from "../-data/validation";
import TypesDetailForm from "./-components/TypesDetailForm";

export const Route = createFileRoute("/_auth/_dashboard/events/types/add")({
  component: () => <CreateEventTypesPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});
const CreateEventTypesPage = () => {
  const navigate = useNavigate();
  const { mutate: createTypes, isPending } = useCreateEventType();
  const form = useForm<EventType>({
    defaultValues: {
      name: "",
      en_name: "",
      order: undefined,
    },
    resolver: eventTypeResolver,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const { t } = useTranslation("dashboard");

  function onSubmit(data: EventType) {
    const typeStr = t("menu.events.types", { ns: "dashboard" });
    createTypes(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: typeStr,
          }),
        });
        void navigate({ to: "/events/types" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: typeStr,
          }),
        });
      },
    });
  }
  return (
    <MainDashboard>
      <TypesDetailForm
        isSubmitLoading={isPending}
        onSubmit={onSubmit}
        isEdit
        form={form}
      />
    </MainDashboard>
  );
};
