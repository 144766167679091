import { QueryClient } from "@tanstack/react-query";

const defaultOptions = import.meta.env.DEV
  ? {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    }
  : {};

export const queryClient = new QueryClient({
  defaultOptions,
});
