import { Form, Modal, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";

import { NotificationFormType, NotiRecurrenceForm } from "@/types";

import { mappingFormField, RECURRENCE } from "../../-data";
import { useNotiRecurrenceForm } from "../../-data/hooks";
import RecurrenceModal from "./RecurrenceModal";

interface Props {
  isEdit: boolean;
  form: UseFormReturn<NotificationFormType>;
}

const Recurrence = ({ isEdit, form }: Props) => {
  const recurrenceFormValue = form.getValues("recurrence");
  const recurrenceFormByNoti = {
    start: form.getValues("start_date"),
    end: form.getValues("end_date") ?? undefined,
    weekdays: form.getValues("weekdays") ?? [],
    repeatEvery: form.getValues("repeat_every") ?? 1,
    type: recurrenceFormValue ? recurrenceFormValue : RECURRENCE.NEVER,
  };
  const { recurrenceText, options, recurrenceForm } = useNotiRecurrenceForm({
    notiForm: form,
    defaultValues: recurrenceFormByNoti,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      recurrenceForm.setValue("start", form.getValues("start_date"));
      recurrenceForm.setValue(
        "type",
        recurrenceFormValue && recurrenceFormValue !== RECURRENCE.NEVER
          ? recurrenceFormValue
          : RECURRENCE.WEEKLY,
      );
    }
  }, [form, open, recurrenceForm, recurrenceFormValue]);

  const handleCancel = () => {
    recurrenceForm.reset(recurrenceFormByNoti);
    form.setValue("recurrence", RECURRENCE.NEVER);
    setOpen(false);
  };

  const handleOk = () => {
    Object.entries(recurrenceForm.getValues()).forEach(([key, value]) => {
      const recurrenceKey = key as keyof NotiRecurrenceForm;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      form.setValue(mappingFormField.recurrence[recurrenceKey], value);
    });
    setOpen(false);
  };

  return (
    <Fragment>
      <Controller
        control={form.control}
        name="recurrence"
        render={({ field }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  Recurrence
                </Typography.Text>
              }
              className="col-span-2"
            >
              <Select
                value={field.value ?? RECURRENCE.NEVER}
                showArrow
                maxTagTextLength={24}
                onSelect={(e) => {
                  if (e !== "NEVER") {
                    setOpen(true);
                    field.onChange(e);
                  } else {
                    Modal.confirm({
                      title: "Are you sure?",
                      content:
                        "This action will reset your form recurrence setting data!",
                      onOk: () => {
                        form.setValue(
                          "start_date",
                          form.formState.defaultValues?.start_date,
                        );
                        form.setValue("end_date", null);
                        form.setValue("repeat_every", 1);
                        form.setValue("recurrence", RECURRENCE.NEVER);
                        form.setValue("weekdays", []);

                        recurrenceForm.setValue(
                          "start",
                          form.formState.defaultValues?.start_date,
                        );
                        recurrenceForm.setValue("end", undefined);
                        recurrenceForm.setValue("repeatEvery", 1);
                        recurrenceForm.setValue("type", RECURRENCE.NEVER);
                        recurrenceForm.setValue("weekdays", []);
                      },
                    });
                  }
                }}
                options={options}
                disabled={!isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <RecurrenceModal
        recurrenceForm={recurrenceForm}
        open={open}
        recurrenceText={recurrenceText}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </Fragment>
  );
};

export default Recurrence;
