import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { createHideToolbarPDFUrl } from "@/lib/utils";

interface Props {
  cvUrl?: string;
  onClose: () => void;
}

const CVModal = ({ cvUrl, onClose }: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <Modal
      open={!!cvUrl}
      destroyOnClose
      className="pb-0"
      centered
      onCancel={onClose}
      title={t("menu.OASIS.preGraduationCv")}
      bodyStyle={{ height: (2 / 3) * window.screen.height }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <object
        data={createHideToolbarPDFUrl(cvUrl)}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </Modal>
  );
};

export default CVModal;
