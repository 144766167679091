import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { EventType, Speaker } from "@/types/Api";

const eventUpdateSchema = yup.object().shape({
  id: yup.number(),
  title: yup
    .string()
    .required("Title is required")
    .min(1, "Title must be at least 1 character")
    .max(200, "Title cannot exceed 200 characters"),
  en_title: yup
    .string()
    .nullable()
    .max(200, "En title cannot exceed 200 characters"),
  content: yup
    .string()
    .nullable()
    .max(10000, "Content cannot exceed 10000 characters"),
  en_content: yup
    .string()
    .nullable()
    .max(50000, "En content cannot exceed 50000 characters"),
  thumbnail: yup
    .string()
    .required("Thumbnail is required")
    .test(
      "is-file-or-uri",
      "Thumbnail must be a valid file or a valid URI",
      (value) => {
        // Function to check if the value is a valid file
        const isValidFile = (file: File) => {
          return SUPPORTED_IMAGE_TYPES.includes(file.type);
        };

        // Function to check if the value is a valid URI
        const isValidURI = (uri: string) => {
          try {
            // Use URL constructor for robust URI validation
            new URL(uri);
            return true;
          } catch (_) {
            return false;
          }
        };

        // Check if value is a File object or a URI string
        return (
          ((value as unknown) instanceof File &&
            isValidFile(value as unknown as File)) ||
          (typeof value === "string" && isValidURI(value))
        );
      },
    ),
  url: yup
    .string()
    .nullable()
    .url("URL must be a valid URL")
    .max(2000, "URL cannot exceed 2000 characters"),
  en_url: yup
    .string()
    .nullable()
    .url("En URL must be a valid URL")
    .max(2000, "En URL cannot exceed 2000 characters"),
  published: yup.boolean().nullable(),
  is_highlight: yup.boolean().nullable(),
  status: yup
    .mixed()
    .oneOf(["UPCOMING", "ONGOING", "COMPLETED", "CANCELLED"], "Invalid status")
    .nullable(),
  start_at: yup
    .mixed()
    .required("Start date and time are required")
    .test(
      "is-valid-date",
      "Start at must be a valid date or date string",
      (value) => {
        const date = new Date(value as string);
        return !isNaN(date.getTime());
      },
    ),
  location: yup.string().nullable(),
  lat: yup.number().nullable(),
  long: yup.number().nullable(),
  host_type: yup
    .mixed()
    .oneOf(["OFFLINE", "ONLINE"], "Invalid host type")
    .nullable(),
  contact: yup.string().nullable(),
  agendas: yup
    .mixed()
    .nullable()
    .default(undefined)
    .test("valid-json", "Agendas must be a valid JSON", (value) => {
      try {
        if (!value) {
          return true;
        }
        JSON.stringify(value as string);
        return true;
      } catch (error) {
        return false;
      }
    }),
  external_id: yup
    .number()
    .min(-2147483648, "External ID must be at least -2147483648")
    .max(2147483647, "External ID cannot exceed 2147483647")
    .nullable(),
  created_by: yup.mixed().required("Created by is required"),
  speakers: yup.array().of(yup.number()).min(1, "Speakers are required"),
  event_types: yup.array().of(yup.number().nullable()).nullable(),
});

const speakerSchema = yup.object().shape({
  id: yup.number().optional(),
  name: yup
    .string()
    .required("Name is required!")
    .max(200, "Name must be at most 200 characters long"),
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required!"),
  phone_number: yup
    .string()
    .required("Phone number is required!")
    .matches(/^(0|\+)[0-9]{9,14}$/, "Invalid phone number format!")
    .max(15, "Phone number must be at most 15 characters long"),
  job_title: yup.string().nullable().optional(),
  en_job_title: yup.string().nullable().optional(),
  company: yup.string().nullable().optional(),
  bio: yup.string().nullable().optional(),
  photo: yup.string().required("Image is required!"),
});

const eventTypesSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name (Vietnamese) is required!")
    .max(200, "Name must be at most 200 characters long"),
  en_name: yup
    .string()
    .required("Name (English) is required!")
    .max(200, "Name must be at most 200 characters long"),
  order: yup
    .number()
    .typeError("Order must be a number!")
    .required("Order is required!"),
});

export const eventSpeakerResolver = yupResolver(
  speakerSchema,
) as Resolver<Speaker>;
export const eventTypeResolver = yupResolver(
  eventTypesSchema,
) as Resolver<EventType>;
export const eventUpdateResolver = yupResolver(eventUpdateSchema);
