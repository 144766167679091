import { UseFormReturn, useWatch } from "react-hook-form";

import { NotiRecurrenceForm } from "@/types";

import { WEEKDAYS } from "../../-data";
import DaySelector from "./DaySelector";

interface Props {
  recurrenceForm: UseFormReturn<NotiRecurrenceForm>;
}

const WeekDays = ({ recurrenceForm }: Props) => {
  const { weekdays } = useWatch({ control: recurrenceForm.control });

  return (
    <div className="flex gap-2">
      {Object.entries(WEEKDAYS).map(([_, day]) => {
        const hasCurrentItem = !!weekdays?.includes(day);
        return (
          <DaySelector
            onClick={() => {
              const results = structuredClone(weekdays ?? []);
              if (hasCurrentItem) {
                const index = weekdays?.findIndex((item) => item === day) ?? -1;
                if (index > -1) {
                  results.splice(index, 1);
                }
              } else {
                results.push(day);
              }
              recurrenceForm.setValue("weekdays", results);
            }}
            isSelected={hasCurrentItem}
            key={day}
          >
            {day.charAt(0).toUpperCase()}
          </DaySelector>
        );
      })}
    </div>
  );
};

export default WeekDays;
