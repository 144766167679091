import i18next from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

export const i18nextNsDashboard = "dashboard";
export const i18nextNsPublic = "public";
export const i18nextNsLogin = "login";
export const i18nextNsCommon = "common";

i18next
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [i18nextNsDashboard, i18nextNsPublic, i18nextNsLogin, i18nextNsCommon],
    defaultNS: i18nextNsPublic,
    partialBundledLanguages: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      queryStringParams: { v: import.meta.env.VITE_BUILD_VERSION || "" },
    },
  })
  .catch((e: unknown) => {
    console.error("i18n init error", e);
  });

await i18next.loadNamespaces([i18nextNsDashboard]).catch((e: unknown) => {
  console.error("i18n load namespaces error", e);
});

export default i18next;
