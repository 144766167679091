import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useOnRowTable } from "@/hooks/useOnRowTable";

import { useBenefitTypes, useBenefitsTypesFilter } from "../-data/hooks";
import { benefitsTypesLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/benefits/types/")({
  component: () => <BenefitsTypes />,
  loader: benefitsTypesLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function BenefitsTypes() {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useBenefitTypes();
  const { benefitsFilterType, setBenefitsFilterType } =
    useBenefitsTypesFilter();
  const navigate = useNavigate();
  const onRow = useOnRowTable({ to: "/benefits/types/$id" });

  const handleAddNew = () => {
    void navigate({ to: "/benefits/types/add" });
  };

  const handleChange = (search?: string) => {
    setBenefitsFilterType((prev) => {
      return {
        ...prev,
        page: 1,
        search,
      };
    });
  };
  const handlePaginateClick = (page: number) => {
    setBenefitsFilterType((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          title={t("menu.benefits.type.types")}
          search={benefitsFilterType.search}
          onChange={handleChange}
          onAddNewClick={handleAddNew}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: benefitsFilterType.page,
            onChange: handlePaginateClick,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/benefits/types/$id"
                    params={{ id: record.id?.toString() ?? "" }}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.benefits.type.en_name"),
              key: "title",
              render: (_, record) => {
                return (
                  <Typography.Text ellipsis>{record.en_name}</Typography.Text>
                );
              },
            },
            {
              title: t("menu.benefits.type.vn_name"),
              key: "type",
              render: (_, record) => {
                return <span className="text-nowrap">{record.name}</span>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
