import { createFileRoute } from "@tanstack/react-router";

import { eventTypesLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";
import TypesList from "./-components/TypesList";

export const Route = createFileRoute("/_auth/_dashboard/events/types/")({
  component: () => (
    <MainDashboard>
      <TypesList />
    </MainDashboard>
  ),
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: eventTypesLoader,
});
