import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import {
  mappingContentToBase64,
  mappingContentWithHtmlHeaderTag,
} from "@/lib/utils";
import { NewCategory, NewsCreate } from "@/types/Api";

import NewsForm from "../../../-components/NewsForm";
import { useNewsForm, useUpdateNews } from "../../../-data/hooks";
import { newsDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/news/_list/(details)/$id/edit",
)({
  component: () => <NewsEditPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: newsDetailLoader,
});

const NewsEditPage = () => {
  const { id } = useParams({
    from: "/_auth/_dashboard/news/_list/$id/edit",
  });
  const navigate = useNavigate();
  const { form, categories } = useNewsForm(id.toString());
  const { mutate, isPending: isLoading } = useUpdateNews();
  const { t } = useTranslation("dashboard");

  function onSubmit(data: NewsCreate) {
    const mutateData = mappingContentToBase64(
      mappingContentWithHtmlHeaderTag(data, "content", "en_content"),
      "content",
      "en_content",
    );
    mutate(mutateData, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.news.news"),
          }),
        });
        void navigate({
          to: "/news/$id",
          params: { id },
        });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: t("menu.news.news"),
          }),
        });
      },
    });
  }

  return (
    <MainDashboard>
      <NewsForm
        isEdit
        form={form}
        categories={(categories ?? []) as NewCategory[]}
        title={t("menu.edit")}
        onSubmit={onSubmit}
        isSubmitLoading={isLoading}
      />
    </MainDashboard>
  );
};
