import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Checkbox,
  Form,
  Input,
  notification,
  Select,
  Typography,
  UploadFile,
} from "antd";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joinURL } from "ufo";

import {
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  UploadImage,
  Editor,
} from "@/components";
import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { cn, filterSelectOptions } from "@/lib/utils";
import { NewCategory, NewsCreate, User } from "@/types/Api";

interface NewsFormProps
  extends LayoutDetailsFormProps<
    NewsCreate & { created_by?: User; en_external_id?: number }
  > {
  categories?: NewCategory[];
}

const NewsForm = ({
  form,
  categories,
  isSubmitLoading,
  onSubmit,
  title,
  isEdit,
  onEdit,
}: NewsFormProps) => {
  const isExternalNews =
    !!form.getValues("external_id") || !!form.getValues("en_external_id");
  const formThumbnail = form.getValues("thumbnail");

  const { uploadImage, getOptimizedImageUrl } = useImage();
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const [fileList, setFiledList] = useState<UploadFile[]>(
    formThumbnail
      ? [
          {
            uid: crypto.randomUUID(),
            name: formThumbnail,
            status: "done",
            url: formThumbnail,
            thumbUrl: formThumbnail,
          },
        ]
      : [],
  );

  const handleClose = () => {
    void navigate({ to: "/news" });
  };

  const { title: errorTitle, en_title } = form.formState.errors;

  useEffect(() => {
    if (errorTitle?.type === "required" && en_title?.type === "required") {
      notification.error({
        message: "Error",
        description: errorTitle.message,
      });
    }
  }, [
    en_title?.message,
    en_title?.type,
    errorTitle?.message,
    errorTitle?.type,
  ]);

  return (
    <LayoutDetailsForm
      onSubmit={onSubmit}
      isSubmitLoading={isSubmitLoading}
      form={form}
      onEdit={onEdit}
      isEdit={isEdit}
      title={title}
      onClose={handleClose}
    >
      <Controller
        control={form.control}
        name="en_title"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
            >
              <Input
                {...field}
                placeholder="Enter here"
                disabled={!isEdit || isExternalNews}
                value={field.value ?? ""}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="title"
        render={({ field, fieldState }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.vnTitle")}
                </Typography.Text>
              }
            >
              <Input
                {...field}
                placeholder="Enter here"
                status={fieldState.error && "error"}
                disabled={!isEdit || isExternalNews}
                value={field.value ?? ""}
              />
            </Form.Item>
          );
        }}
      />
      {isExternalNews && (
        <>
          <Controller
            control={form.control}
            name="url"
            render={({ field }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.news.enUrl")}
                    </Typography.Text>
                  }
                >
                  <Input
                    {...field}
                    placeholder="Enter here"
                    disabled={!isEdit || isExternalNews}
                    value={field.value ?? ""}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            control={form.control}
            name="en_url"
            render={({ field }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.news.url")}
                    </Typography.Text>
                  }
                >
                  <Input
                    {...field}
                    placeholder="Enter here"
                    disabled={!isEdit || isExternalNews}
                    value={field.value ?? ""}
                  />
                </Form.Item>
              );
            }}
          />
        </>
      )}
      <Controller
        control={form.control}
        name="categories"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.news.category")}
                </Typography.Text>
              }
              className={cn(
                "flex flex-col gap-1.5",
                isExternalNews && "col-span-2",
              )}
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Select
                mode="multiple"
                showArrow
                options={categories?.map((item) => {
                  return {
                    value: item.id,
                    label:
                      (item as unknown as { en_name?: string }).en_name ??
                      item.name,
                  };
                })}
                placeholder="Select Category"
                filterOption={filterSelectOptions}
                {...field}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      {!isExternalNews && (
        <Form.Item
          label={
            <Typography.Text className="font-medium">
              {t("menu.news.author")}
            </Typography.Text>
          }
        >
          <Input
            disabled
            value={
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              (form.getValues("created_by.first_name") || "") +
              " " +
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              (form.getValues("created_by.last_name") || "")
            }
          />
        </Form.Item>
      )}

      {!isExternalNews && (
        <>
          <Controller
            control={form.control}
            name="en_content"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.enContent")}
                    </Typography.Text>
                  }
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                  className="col-span-2"
                >
                  <Editor
                    placeholder={t("menu.enterHere")}
                    disabled={!isEdit}
                    data={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            control={form.control}
            name="content"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  label={
                    <Typography.Text className="font-medium">
                      {t("menu.content")}
                    </Typography.Text>
                  }
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                  className="col-span-2"
                >
                  <Editor
                    placeholder={t("menu.enterHere")}
                    disabled={!isEdit}
                    data={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              );
            }}
          />
        </>
      )}
      <Controller
        control={form.control}
        name="published"
        render={({ field }) => {
          return (
            <Form.Item className="col-span-2">
              <Checkbox {...field} disabled={!isEdit} checked={field.value}>
                {t("menu.published")}
              </Checkbox>
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="thumbnail"
        render={({ fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.thumbnail")}
                </Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <UploadImage
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: isEdit,
                }}
                disabled={!isEdit}
                accept={SUPPORTED_IMAGE_TYPES.join(",")}
                onRemove={(file) => {
                  setFiledList((prev) => {
                    const result = prev.filter((item) => item.uid !== file.uid);
                    return [...result];
                  });
                  form.setValue("thumbnail", undefined);
                }}
                customRequest={(options) => {
                  const file = options.file as File;
                  const filename = joinURL("news", file.name);

                  void uploadImage(
                    { file, filename },
                    {
                      onSuccess: () => {
                        const thumbnailUrl = getOptimizedImageUrl(filename);
                        form.setValue("thumbnail", filename);
                        setFiledList([
                          {
                            name: thumbnailUrl,
                            url: thumbnailUrl,
                            thumbUrl: thumbnailUrl,
                            status: "done",
                            uid: crypto.randomUUID(),
                          },
                        ]);
                      },
                    },
                  );
                }}
                fileList={fileList}
                emptyComponent={isEdit ? null : undefined}
                className="w-full"
              >
                {isEdit && fileList.length === 0 && (
                  <div className="flex w-full flex-col items-center gap-1">
                    <PlusOutlined className="" />
                    <span>{t("menu.upload")}</span>
                  </div>
                )}
              </UploadImage>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default NewsForm;
