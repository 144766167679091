import { LoaderType } from "@/main";

import { newsAtoms, newsQueries } from "./store";

export const newsCategoriesListLoader = ({
  context: { queryClient },
}: LoaderType) => {
  return queryClient.ensureQueryData(newsQueries.allCategories());
};

export const newsListLoader = (loaderFnContextProps: LoaderType) => {
  const {
    context: { queryClient, globalStore },
  } = loaderFnContextProps;
  void queryClient.ensureQueryData(
    newsQueries.list(globalStore.get(newsAtoms.filter.currentValueAtom)),
  );
  void newsCategoriesListLoader(loaderFnContextProps);
};

export const newsDetailLoader = (props: LoaderType<{ id: number }>) => {
  const {
    context: { queryClient },
    params: { id },
  } = props;
  void newsCategoriesListLoader(props);
  void queryClient.ensureQueryData(newsQueries.details(+id));
};

export const newsCategoryDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: number }>) => {
  void queryClient.ensureQueryData(newsQueries.categoryDetails(+id));
};
