import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { validateThumbnail } from "@/lib/utils";
import { Benefit, BenefitType } from "@/types/Api";

// const benefitsSchema = yup.object().shape(
//   {
//     title: yup.string().when("en_title", {
//       is: (en_title?: string) => !en_title || en_title.length === 0,
//       then: (schema) =>
//         schema.required("Either VN title or EN title is required."),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     en_title: yup.string().when("title", {
//       is: (title: string | undefined) => !title || title.length === 0,
//       then: (schema) =>
//         schema.required("Either VN title or EN title is required."),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     content: yup.string().when("title", {
//       is: (title?: string) => !!title || (title?.length ?? 0) > 0,
//       then: (schema) =>
//         schema
//           .required("Content is required!")
//           .max(500000, "Content too large!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     en_content: yup.string().when("en_title", {
//       is: (value?: string) => !!value || (value?.length ?? 0) > 0,
//       then: (schema) =>
//         schema
//           .required("Content is required!")
//           .max(500000, "Content too large!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     en_eligibility: yup.string().when("en_title", {
//       is: (value?: string) => !!value || (value?.length ?? 0) > 0,
//       then: (schema) => schema.required("EN - Eligibility is required!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     eligibility: yup.string().when("title", {
//       is: (value?: string) => !!value || (value?.length ?? 0) > 0,
//       then: (schema) => schema.required("Eligibility is required!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     en_how_to_redeem: yup.string().when("en_title", {
//       is: (value?: string) => !!value || (value?.length ?? 0) > 0,
//       then: (schema) => schema.required("EN - How to redeem is required!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     how_to_redeem: yup.string().when("title", {
//       is: (value?: string) => !!value || (value?.length ?? 0) > 0,
//       then: (schema) => schema.required("How to redeem is required!"),
//       otherwise: (schema) => schema.nullable(),
//     }),
//     thumbnail: yup
//       .string()
//       .required("Thumbnail is required!")
//       .test(
//         "is-file-or-uri",
//         "Thumbnail must be a valid file or a valid URI",
//         validateThumbnail,
//       ),
//     type: yup.number().required("Benefits type is required!"),
//     end_date: yup.string().required("Date is required!"),
//     start_date: yup.string().required("Date is required!"),
//   },
//   [
//     ["title", "en_title"],
//     ["title", "content"],
//     ["title", "eligibility"],
//     ["title", "how_to_redeem"],
//     ["en_title", "en_how_to_redeem"],
//     ["en_title", "en_content"],
//     ["en_title", "en_eligibility"],
//   ],
// );

const benefitsSchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  content: yup.string().nullable().max(500000, "Content too large!"),
  en_content: yup.string().nullable().max(500000, "Content too large!"),
  en_eligibility: yup.string().nullable(),
  eligibility: yup.string().nullable(),
  en_how_to_redeem: yup.string().nullable(),
  how_to_redeem: yup.string().nullable(),
  thumbnail: yup
    .string()
    .required("Thumbnail is required!")
    .test(
      "is-file-or-uri",
      "Thumbnail must be a valid file or a valid URI",
      validateThumbnail,
    ),
  type: yup.number().required("Benefits type is required!"),
  end_date: yup.string().required("Date is required!"),
  start_date: yup.string().required("Date is required!"),
});

const benefitTypeSchema = yup.object().shape(
  {
    name: yup.string().when("en_name", {
      is: (value: string) => value,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("Name is required!"),
    }),
    en_name: yup.string().when("name", {
      is: (value: string) => value,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("EN - Name is required!"),
    }),
  },
  [["name", "en_name"]],
);

export interface BenefitUpdate extends Omit<Benefit, "type"> {
  type: number;
}

export const benefitsResolver = yupResolver(
  benefitsSchema,
) as Resolver<BenefitUpdate>;

export const benefitTypeResolver = yupResolver(
  benefitTypeSchema,
) as Resolver<BenefitType>;
