import { WebStorageStateStore, UserManagerSettings } from "oidc-client-ts";
import { joinURL } from "ufo";

import {
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_REDIRECT_URI,
  OIDC_SILENT_REDIRECT_URI,
  OIDC_POST_LOGOUT_REDIRECT_URI,
  OIDC_TENANT_ID,
} from "@/lib/constants";

const userStore = new WebStorageStateStore({ store: window.localStorage });

export const OIDC_CONFIGS: Record<string, UserManagerSettings> = {
  azure: {
    authority: joinURL(OIDC_AUTHORITY, OIDC_TENANT_ID, "v2.0"),
    metadataUrl: joinURL(
      OIDC_AUTHORITY,
      OIDC_TENANT_ID,
      "v2.0",
      ".well-known/openid-configuration",
    ),
    client_id: OIDC_CLIENT_ID,
    redirect_uri: joinURL(OIDC_SILENT_REDIRECT_URI, "auth/loginCallback"),
    response_type: "code",
    scope: "openid profile email offline_access",
    post_logout_redirect_uri: OIDC_POST_LOGOUT_REDIRECT_URI,
    silent_redirect_uri: OIDC_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    // loadUserInfo: true,
    userStore,
  },

  // Example only, if you are using a different OIDC provider, just setup the config here
  default: {
    authority: "",
    metadataUrl: joinURL("", ".well-known/openid-configuration"),
    client_id: "",
    redirect_uri: OIDC_REDIRECT_URI,
    response_type: "code",
    scope: "openid profile email",
    post_logout_redirect_uri: OIDC_POST_LOGOUT_REDIRECT_URI,
    silent_redirect_uri: OIDC_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore,
  },
};

export type OidcProvider = keyof typeof OIDC_CONFIGS;
