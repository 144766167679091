import { NotiRecurrenceForm } from "@/types";
import { NotificationTemplate } from "@/types/Api";

type NotiAction = Exclude<
  NotificationTemplate["action_type"],
  null | undefined
>;

export const NOTIFICATION_ACTIONS: {
  label: NotiAction;
  value: NotiAction;
}[] = [
  {
    label: "External Link",
    value: "External Link",
  },
  {
    label: "Internal Link",
    value: "Internal Link",
  },
  {
    label: "Feature",
    value: "Feature",
  },
];

export const NOTIFICATION_STATUS: {
  label: NotificationTemplate["status"];
  value: NotificationTemplate["status"];
}[] = [
  {
    label: "Created",
    value: "Created",
  },
  {
    label: "Processing",
    value: "Processing",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const WEEKDAYS = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
} as const;

export const RECURRENCE = {
  NEVER: "NEVER",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
} as const;

export const HOUR_ONLY = "HH:mm:ss";

export const mappingFormField: {
  recurrence: Record<keyof NotiRecurrenceForm, keyof NotificationTemplate>;
  noti: Partial<Record<keyof NotificationTemplate, keyof NotiRecurrenceForm>>;
} = {
  recurrence: {
    end: "end_date",
    start: "start_date",
    repeatEvery: "repeat_every",
    weekdays: "weekdays",
    type: "recurrence",
  },
  noti: {
    end_date: "end",
    start_date: "start",
    recurrence: "type",
    weekdays: "weekdays",
    repeat_every: "repeatEvery",
  },
} as const;
