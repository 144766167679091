import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NotificationType } from "@/types/Api";

import NotiTypesForm from "../-components/NotiTypesForm";
import { useCreateNotiTypes } from "../-data/hooks";
import { notiTypesResolver } from "../-data/validation";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/types/add",
)({
  component: () => <AddNotificationTypesPAge />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function AddNotificationTypesPAge() {
  const form = useForm<NotificationType>({
    defaultValues: { en_name: "", name: "", icon: "" },
    resolver: notiTypesResolver,
  });
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateNotiTypes();
  const { t } = useTranslation("dashboard");

  const handleSubmit = (values: NotificationType) => {
    mutate(
      {
        ...values,
        icon: values.icon ?? "",
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.create.success", {
              what: t("menu.notification.notificationType"),
            }),
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.create.error", {
              what: t("menu.notification.notificationType"),
            }),
          });
        },
      },
    );
  };

  const handleClose = () => {
    void navigate({ to: "/notifications/types" });
  };

  return (
    <MainDashboard>
      <NotiTypesForm
        title={t("menu.add", { ns: "dashboard" })}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        onClose={handleClose}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
