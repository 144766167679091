import { createFileRoute } from "@tanstack/react-router";

import { MainDashboard } from "@/components/MainDashboard";

import SpeakerList from "./-components/SpeakerList";

export const Route = createFileRoute("/_auth/_dashboard/events/speakers/")({
  component: () => (
    <MainDashboard>
      <SpeakerList />
    </MainDashboard>
  ),
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});
