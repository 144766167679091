import { Alert } from "antd";

export const ErrorResetAlert = ({
  error,
}: {
  error: Error;
  reset: () => void;
}) => {
  let message = "Error";
  if (error.message) {
    message = error.message;
  } else if (error.stack) {
    message = error.stack;
  }
  return (
    <div className="mx-auto flex w-full max-w-sm items-center gap-2 pt-12">
      <Alert banner message={message} type="error" />
    </div>
  );
};
