import { Form, Modal, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { filterSelectOptions } from "@/lib/utils";

import { COHORT_OPTIONS } from "../../-data";
import { useOASISOrientationFilter } from "../../-data/hooks";

interface Props {
  open: boolean;
  onClose: () => void;
  handleCancel: () => void;
}

const CohortModal = ({ onClose, open, handleCancel }: Props) => {
  const { filter, setFilter } = useOASISOrientationFilter();
  const [cohort, setCohort] = useState(
    filter?.cohort ? String(filter.cohort) : undefined,
  );
  const { t } = useTranslation("dashboard");

  const handleOk = () => {
    if (cohort) {
      setFilter((prev) => ({ ...prev, page: 1, cohort: +cohort }));
    }
    onClose();
  };

  return (
    <Modal
      width={378}
      okButtonProps={{ disabled: !cohort }}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      title="Select cohort"
      open={open}
    >
      <Form layout="vertical">
        <Form.Item
          required
          label={
            <Typography.Text className="font-medium">
              {t("menu.cohort")}
            </Typography.Text>
          }
        >
          <Select
            options={COHORT_OPTIONS}
            filterOption={filterSelectOptions}
            onSelect={(_, option) => {
              setCohort(option.value);
            }}
            value={cohort}
            placeholder="Select Cohort"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CohortModal;
