import { useAtomValue } from "jotai";

import { InternalAppConfigParams, internalAtoms } from "@/jotai-atoms/internal";
import { mappingContentToBase64 } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { useAppMutation } from "./useAppMutation";

export interface IAppConfigUpdate {
  emergency_contact?: {
    name?: string;
    en_name?: string;
    phone?: string;
  }[];
  library_service_url?: string;
  campus_guideline_handbook_url?: string;
  international_student_handbook_url?: string;
  wow_handbook_url?: string;
  student_policy_url?: string;
  faculty_policy_url?: string;
  campus_map_url?: string;
  code_of_conduct_url?: string;
  student_success_url?: string;
}

export const useAppConfig = (params: InternalAppConfigParams) => {
  return useAtomValue(internalAtoms.appConfig(params));
};
export const useUpdateAppConfig = () =>
  useAppMutation({
    mutationFn: async (data: IAppConfigUpdate & { code: string }) => {
      try {
        const requestData = mappingContentToBase64(
          data,
          "campus_guideline_handbook_url",
          "campus_map_url",
          "code_of_conduct_url",
          "faculty_policy_url",
          "international_student_handbook_url",
          "library_service_url",
          "student_policy_url",
          "student_success_url",
          "wow_handbook_url",
        );
        await fetchApiClient.config.updateAnAppConfigWithCode(
          data.code,
          requestData,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
