import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute("/_auth/_dashboard/customers/_list")({
  beforeLoad: () => {
    return {
      title: t("menu.list", { ns: "dashboard" }),
    };
  },
});
