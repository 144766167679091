import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { NotificationType, ReadTargetCustomer } from "@/types/Api";

import NotificationForm from "../../../-components/NotificationForm";
import { useNotiFormDetail, usePushNotification } from "../../../-data/hooks";
import { notificationDetailsLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/_list/(details)/$id/",
)({
  loader: notificationDetailsLoader,
  component: () => <NotificationsDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function NotificationsDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/notifications/_list/$id/",
  });
  const {
    notiTypes,
    notificationDetails,
    events,
    statistic,
    targetCustomerList,
    form,
  } = useNotiFormDetail(id.toString());

  const { t } = useTranslation("dashboard");
  const { mutateAsync: pushNoti, isPending: isPushingNoti } =
    usePushNotification();
  const navigate = useNavigate();

  const handlePushNoti = () => {
    Modal.confirm({
      title: t("menu.notification.confirmPushNotiTitle"),
      content: t("menu.notification.confirmPushNotiDesc"),
      autoFocusButton: null,
      cancelButtonProps: {
        size: "large",
        className: "w-[108px]",
      },
      okButtonProps: {
        size: "large",
        className: "w-[108px] bg-brand-primary",
      },
      onOk: async () => {
        await pushNoti(id.toString());
      },
      maskClosable: true,
    });
  };

  return (
    <MainDashboard>
      <NotificationForm
        title={t("menu.details")}
        statistic={statistic ?? []}
        form={form}
        onEdit={() => {
          void navigate({
            to: "/notifications/$id/edit",
            params: { id },
          });
        }}
        notificationType={(notiTypes as NotificationType[] | undefined) ?? []}
        targetCustomers={
          (targetCustomerList as ReadTargetCustomer[] | undefined) ?? []
        }
        events={events ?? []}
        createBy={notificationDetails?.created_by?.email ?? ""}
        rightContent={
          <Button
            loading={isPushingNoti}
            className="min-h-[38px] min-w-[114px]"
            type="primary"
            onClick={handlePushNoti}
          >
            {t("menu.send")}
          </Button>
        }
      />
    </MainDashboard>
  );
}
