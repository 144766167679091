import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import {
  useAllCohorts,
  useAllColleges,
  useAllUsers,
  useUserInfo,
} from "@/jotai-atoms/user";
import { CreateTargetCustomerRequest } from "@/types/Api";

import TargetCustomerForm from "../-components/TargetCustomerForm";
import { useCreateTargetCustomer } from "../-data/hooks";
import { targetCustomerAddLoader } from "../-data/loaders";
import { targetCustomerResolver } from "../-data/validation";

export const Route = createFileRoute("/_auth/_dashboard/customers/_list/add")({
  loader: targetCustomerAddLoader,
  component: () => <TargetCustomerCreatePage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function TargetCustomerCreatePage() {
  const { data: userInfo } = useUserInfo();
  const { data: users } = useAllUsers();
  const { data: college } = useAllColleges();
  const { data: cohorts } = useAllCohorts();
  const { mutate: createTargetCustomer, isPending } = useCreateTargetCustomer();
  const form = useForm<CreateTargetCustomerRequest>({
    defaultValues: { name: "" },
    resolver: targetCustomerResolver,
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const handleClose = () => {
    void navigate({ to: "/customers" });
  };
  const handleSubmit = (data: CreateTargetCustomerRequest) => {
    createTargetCustomer(
      {
        ...data,
        colleges: data.colleges ?? [],
        roles: data.roles ?? [],
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.create.success", {
              what: "Target Customer",
            }),
          });
          void navigate({ to: "/customers" });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.create.error", {
              what: "Target Customer",
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <TargetCustomerForm
        created_by={userInfo?.user.email ?? ""}
        title={t("menu.add", { ns: "dashboard" })}
        form={form}
        users={users ?? []}
        colleges={college ?? []}
        cohorts={cohorts ?? []}
        isSubmitLoading={isPending}
        onClose={handleClose}
        onSubmit={handleSubmit}
        isEdit
      />
    </MainDashboard>
  );
}
