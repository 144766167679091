import { useFile } from "./file.hook";
import { useAppMutation } from "./useAppMutation";

const AZURE_BLOB_STORE_KEYS = {
  containerName: "private",
  fileName: "import-files",
};

export const useUploadXlsx = () => {
  const { uploadFile } = useFile();
  const { mutate, isPending } = useAppMutation({
    mutationFn: async ({ file }: { file: File }) => {
      const data = await uploadFile({
        file,
        filename: `${AZURE_BLOB_STORE_KEYS.fileName}/${file.name}`,
        containerName: AZURE_BLOB_STORE_KEYS.containerName,
      });
      return data;
    },
  });

  const getFileName = (url: string) => {
    return url.split(`${AZURE_BLOB_STORE_KEYS.containerName}/`)[1];
  };

  return { mutate, getFileName, isPending };
};
