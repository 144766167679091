import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Autoformat,
  Bold,
  Italic,
  Underline,
  BlockQuote,
  CloudServices,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  IndentBlock,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableColumnResize,
  TableToolbar,
  TextTransformation,
  Undo,
  ClassicEditor,
  UploadAdapter,
  FileLoader,
  FileRepository,
  FontSize,
  FontColor,
  FontBackgroundColor,
  GeneralHtmlSupport,
  FontFamily,
  EventInfo,
  TableProperties,
  TableCellProperties,
} from "ckeditor5";
import { joinURL } from "ufo";

import { useImage } from "@/hooks";

interface EditorProps {
  initialData?: string | Record<string, string>;
  data?: string | null;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
}

class IUploadAdapter implements UploadAdapter {
  private loader: FileLoader;
  private mutateFile: (opts: {
    file: File;
    filename?: string;
  }) => Promise<string>;
  constructor(
    loader: FileLoader,
    mutateFile: (opts: { file: File; filename?: string }) => Promise<string>,
  ) {
    this.loader = loader;
    this.mutateFile = mutateFile;
  }

  public async upload(): Promise<{ default: string }> {
    const file = await this.loader.file;
    if (!file) {
      throw new Error("Something went wrong!");
    }
    const url = await this.mutateFile({
      file,
      filename: joinURL("editors", file.name),
    });
    if (url) {
      return Promise.resolve({ default: url });
    }
    return Promise.reject(new Error("Something went wrong"));
  }
  abort?(): void {
    throw new Error("Method not implemented.");
  }
}

const Style = () => {
  return (
    <style>
      {`
        .ck.ck-editor__main > .ck-column-resize_disabled{
          &, * {
            background-color: #f5f5f5 !important;
          }
          cursor: not-allowed;
        }
        a {
          color: revert;
        }
      `}
    </style>
  );
};

export const Editor: React.FC<EditorProps> = ({
  initialData,
  disabled,
  data,
  onChange,
  placeholder,
}) => {
  const { uploadImage } = useImage();

  const handleEditorReady = (editor: ClassicEditor) => {
    try {
      // Set font-family come first
      const fontFamily = editor.commands.get("fontFamily");
      fontFamily?.execute({ value: "Montserrat, sans-serif" });

      const editorData = editor.getData();
      if (editorData.trim().length > 0) {
        // Remove the duplicate ck-editor's container
        const doc = new DOMParser().parseFromString(editorData, "text/html");
        const ckEditorContainer = doc.querySelector(".ck-editor__editable");
        const children = ckEditorContainer?.innerHTML ?? "";
        if (ckEditorContainer) {
          ckEditorContainer.remove();
        }
        doc.body.insertAdjacentHTML("afterbegin", children);
        editor.setData(new XMLSerializer().serializeToString(doc));
      } else {
        // Set the empty string that will make editor display placeholder
        editor.setData("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditorChange = (_: EventInfo, editor: ClassicEditor) => {
    const editorData = editor.getData();
    onChange?.(editorData);
  };

  return (
    <>
      <Style />
      <CKEditor
        editor={ClassicEditor}
        disabled={disabled}
        data={data}
        onReady={handleEditorReady}
        onChange={handleEditorChange}
        config={{
          htmlSupport: {
            allow: [
              {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          placeholder,
          fontFamily: {
            options: ["Montserrat, sans-serif"],
          },
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "|",
              "fontsize",
              "fontColor",
              "fontBackgroundColor",
              "|",
              "link",
              "uploadImage",
              "insertTable",
              "blockQuote",
              "mediaEmbed",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "outdent",
              "indent",
            ],
          },
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableProperties",
              "tableCellProperties",
            ],
            tableCellProperties: {
              defaultProperties: {
                borderColor: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              },
            },
          },
          image: {
            toolbar: [
              "imageStyle:inline",
              "imageStyle:alignCenter",
              {
                name: "imageStyle:customDropdown",
                title: "Centered image",
                items: ["imageStyle:alignLeft", "imageStyle:alignRight"],
                defaultItem: "imageStyle:alignLeft",
              },
              "|",
              "imageTextAlternative",
            ],
          },
          fontSize: {
            options: ["tiny", "small", "default", "big", "huge"],
          },
          extraPlugins: [
            // Must use normal function instead of arrow function
            function (editor) {
              const fileRepository = editor.plugins.get("FileRepository");
              fileRepository.createUploadAdapter = (loader) => {
                return new IUploadAdapter(loader, uploadImage);
              };
            },
          ],
          plugins: [
            GeneralHtmlSupport,
            Autoformat,
            Bold,
            FontFamily,
            FileRepository,
            Italic,
            Underline,
            BlockQuote,
            CloudServices,
            FontSize,
            FontColor,
            FontBackgroundColor,
            Essentials,
            Heading,
            Image,
            ImageCaption,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            PictureEditing,
            Indent,
            IndentBlock,
            Link,
            List,
            MediaEmbed,
            Mention,
            Paragraph,
            PasteFromOffice,
            Table,
            TableColumnResize,
            TableToolbar,
            TableProperties,
            TableCellProperties,
            TextTransformation,
            Undo,
          ],
          initialData,
        }}
      />
    </>
  );
};
