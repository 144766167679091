import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Table } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { calculateTableRecordByIndex } from "@/lib/utils";
import { OrientationTest } from "@/types/Api";

import OrientationsHeader from "./-components/ListHeaders/OrientationsHeader";
import CohortModal from "./-components/Modals/CohortModal";
import ITable from "./-components/Table";
import { useOASISOrientation, useOASISOrientationFilter } from "./-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/oasis/orientation")({
  component: () => <OASISOrientationPage />,
  beforeLoad: () => {
    return {
      title: t("menu.OASIS.orientation", { ns: "dashboard" }),
    };
  },
});

function OASISOrientationPage() {
  const [openModal, setOpenModal] = useState(true);
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useOASISOrientation();
  const { filter, setFilter } = useOASISOrientationFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const navigate = useNavigate();

  const handleCancel = () => {
    if (!filter?.cohort) {
      void navigate({ to: "/oasis" });
    } else {
      setOpenModal(false);
    }
  };

  const renderTableGroupChildren = (index: number) => {
    return [
      {
        className: "!p-0 text-center",
        title: <ITable.Col>{t("menu.OASIS.gradePoint")}</ITable.Col>,
        render: (
          _: unknown,
          record: OrientationTest & { key: string | number },
        ) => record.orientation_tests?.[index].point,
      },
      {
        className: "!p-0 text-center",
        title: <ITable.Col>{t("menu.OASIS.gradePercentage")}</ITable.Col>,
        render: (
          _: unknown,
          record: OrientationTest & { key: string | number },
        ) => record.orientation_tests?.[index].grade,
      },
      {
        className: "!p-0 text-center",
        title: <ITable.Col>{t("menu.OASIS.gradeLetter")}</ITable.Col>,
        render: (
          _: unknown,
          record: OrientationTest & { key: string | number },
        ) => (record.orientation_tests?.[index].passed ? "Passed" : "Failed"),
      },
    ];
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4 h-full">
        <OrientationsHeader
          onReset={() => {
            setOpenModal(true);
          }}
          isSearchLoading={isFetching}
          onSearchChange={handleSearchChange}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, __, index) =>
                calculateTableRecordByIndex(index, filter?.page),
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => record.personal_profile.name,
            },
            {
              title: t("menu.OASIS.studentId"),
              key: "studentId",
              render: (_, record) => record.personal_profile.student_number,
            },
            {
              title: (
                <ITable.Group className="py-5">
                  [{filter?.cohort}] {t("menu.OASIS.academicIntegrityQuiz")}
                </ITable.Group>
              ),
              className: "!p-0 w-[351px]",
              key: "orientation",
              children: renderTableGroupChildren(0),
            },
            {
              title: (
                <ITable.Group type="secondary" className="py-5">
                  {t("menu.OASIS.studentCodeOfConduct")}
                </ITable.Group>
              ),
              className: "!p-0 w-[351px]",
              key: "orientation",
              children: renderTableGroupChildren(1),
            },
          ]}
        />
      </div>
      <CohortModal
        handleCancel={handleCancel}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </MainDashboard>
  );
}
