import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useFile } from "@/hooks";
import { NotificationType } from "@/types/Api";

import NotiTypesForm from "../../-components/NotiTypesForm";
import { useNotiTypesForm, useUpdateNotiTypes } from "../../-data/hooks";
import { notiTypeDetailsLoader } from "../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/types/$id/edit",
)({
  loader: notiTypeDetailsLoader,
  component: () => <NotificationTypesDetailsPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function NotificationTypesDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/notifications/types/$id/edit",
  });
  const { form } = useNotiTypesForm(id);
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateNotiTypes();
  const { getFilePath } = useFile();
  const { t } = useTranslation("dashboard");

  const handleSubmit = (values: NotificationType) => {
    if (values.icon) {
      values.icon = getFilePath(values.icon);
    }
    mutate(
      {
        ...values,
        id: +id,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: "Notification Type",
            }),
          });
          void navigate({
            to: "/notifications/types/$id",
            params: { id: id.toString() },
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: "Notification Type",
            }),
          });
        },
      },
    );
  };

  const handleClose = () => {
    void navigate({ to: "/notifications/types" });
  };

  return (
    <MainDashboard>
      <NotiTypesForm
        title={t("menu.edit", { ns: "dashboard" })}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        onClose={handleClose}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
