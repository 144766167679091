import { FunnelPlotOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@/components";
import { cn, filterSelectOptions } from "@/lib/utils";
import { Feedback } from "@/types/Api";

import { FEEDBACK_RATING, FEEDBACK_SUBJECT } from "../-data";

const Style = () => {
  return (
    <style>
      {`
        .ant-dropdown-menu-item-group-list {
            margin: 0;
            .ant-dropdown-menu-item,.ant-dropdown-menu-item-only-child{
                padding: 5px 28px;
            }
        }
      `}
    </style>
  );
};

interface FilterType {
  search?: string;
  feedbackType?: Feedback["subject"][];
  rating?: Feedback["rating"][];
}

interface Props {
  search?: string;
  onResetFilter: () => void;
  onApplyFilter: (filter: FilterType) => void;
  onChange: (value: string) => void;
  onSearch: (value: string) => void;
  isLoading?: boolean;
  shouldDisableResetBtn?: boolean;
}

const FeedbackListHeader = ({
  search,
  onApplyFilter,
  onResetFilter,
  onChange,
  onSearch,
  isLoading = false,
  shouldDisableResetBtn = false,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<FilterType>({
    search: "",
    feedbackType: undefined,
    rating: undefined,
  });

  const handleResetFilter = () => {
    onResetFilter();
    setFilter({
      feedbackType: undefined,
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    onApplyFilter(filter);
    setOpen(false);
  };

  return (
    <Stack className="mb-4 justify-between">
      <Typography.Title className="!mb-0" level={4}>
        {t("menu.list")}
      </Typography.Title>
      <div className="flex flex-1 items-center justify-end gap-4">
        {!isOpenSearch ? (
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setIsOpenSearch(true);
            }}
          >
            <span>{t("menu.search.search")}</span>
            <SearchOutlined className="text-[#8C8C8C]" />
          </Stack>
        ) : (
          <Input.Search
            autoFocus
            value={search}
            placeholder="Search Subject, Description, Rating"
            className="w-1/3"
            onSearch={onSearch}
            loading={isLoading}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={() => {
              setIsOpenSearch(false);
            }}
          />
        )}
        <Style />
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[232px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={isLoading}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.subject")}
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      placeholder={t("menu.select", {
                        what: t("menu.subject"),
                      })}
                      className="w-full"
                      options={FEEDBACK_SUBJECT}
                      onChange={(value) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            feedbackType: value,
                          };
                        });
                      }}
                      value={filter.feedbackType}
                      showArrow
                      maxTagTextLength={18}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "feedback-type",
              },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.feedback.rating")}
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      placeholder={t("menu.select", {
                        what: t("menu.feedback.rating"),
                      })}
                      filterOption={filterSelectOptions}
                      className="w-full"
                      options={FEEDBACK_RATING}
                      onChange={(value) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            rating: value,
                          };
                        });
                      }}
                      value={filter.rating}
                      showArrow
                      maxTagTextLength={24}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "rating",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      </div>
    </Stack>
  );
};

export default FeedbackListHeader;
