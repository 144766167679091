import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Radio } from "antd";
import React from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";

import { checkQuestionTypeIsChoice, cn } from "@/lib/utils";
import { ISurveyForm, ISurveyQuestionType } from "@/types";

const DropdownWrapper = ({
  count,
  children,
  className,
}: {
  count: number;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex gap-1 items-center", className)}>
      {count}.{children}
    </div>
  );
};

const ChoiceWrapper = ({
  type,
  children,
  onClick,
}: {
  type: "checkbox" | "radio";
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return React.cloneElement(type === "checkbox" ? <Checkbox /> : <Radio />, {
    className: "opacity-65",
    children,
    checked: false,
    onClick,
  });
};

interface Props {
  form: UseFormReturn<ISurveyForm>;
  questionIndex: number;
  questionType: ISurveyQuestionType;
  disable: boolean;
}

const QuestionType = ({
  form,
  questionIndex,
  questionType,
  disable,
}: Props) => {
  const { control } = form;
  const {
    fields: optionField,
    append,
    remove,
  } = useFieldArray({
    control,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    name: `questions.${questionIndex}.options`,
  });

  const handleAppend = () => {
    const option = "Option";
    append({
      label: option,
      value: option,
      order: optionField.length + 1,
      en_label: option,
    });
  };

  const inputAddComp = (
    <Input
      onClick={handleAppend}
      readOnly
      bordered={false}
      value="Add Option"
      className="!cursor-pointer pl-1"
    />
  );
  return (
    <div className="border border-solid border-[#d9d9d9] p-4 py-3">
      {optionField.length > 0 &&
        optionField.map((option, optionIndex) => {
          return (
            <div key={option.id} className="flex items-center justify-between">
              <Controller
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                name={`questions.${questionIndex}.options.${optionIndex}`}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  const fieldValue = field.value?.value ?? "";
                  const inputComp = (
                    <Input
                      {...field}
                      disabled={disable}
                      className="pl-1"
                      status={error ? "error" : undefined}
                      bordered={!!error}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange({
                          ...option,
                          label: value,
                          en_label: value,
                          value,
                        });
                      }}
                      value={fieldValue}
                      placeholder="Option"
                    />
                  );

                  if (questionType === "DROPDOWN") {
                    return (
                      <DropdownWrapper count={optionIndex + 1}>
                        {inputComp}
                      </DropdownWrapper>
                    );
                  }

                  if (questionType === "CHECKBOXES") {
                    return (
                      <Checkbox disabled={disable} checked={false}>
                        {inputComp}
                      </Checkbox>
                    );
                  }

                  if (
                    checkQuestionTypeIsChoice(questionType) ||
                    questionType === "RATE"
                  ) {
                    return (
                      <Radio disabled={disable} checked={false}>
                        {inputComp}
                      </Radio>
                    );
                  }
                  return (
                    <Input
                      {...field}
                      disabled
                      value={fieldValue}
                      placeholder="Option"
                    />
                  );
                }}
              />
              <Button
                icon={<DeleteOutlined />}
                disabled={disable}
                danger
                type="text"
                onClick={() => {
                  const options = optionField.filter(
                    (item) => item.order !== optionIndex + 1,
                  );
                  remove();
                  options.forEach((item, index) => {
                    append({ ...item, order: index + 1 });
                  });
                }}
              />
            </div>
          );
        })}
      {questionType === "CHECKBOXES" && (
        <ChoiceWrapper onClick={handleAppend} type="checkbox">
          {inputAddComp}
        </ChoiceWrapper>
      )}
      {(questionType === "MULTIPLE_CHOICE" || questionType === "RATE") && (
        <ChoiceWrapper onClick={handleAppend} type="radio">
          {inputAddComp}
        </ChoiceWrapper>
      )}
      {questionType === "DROPDOWN" && (
        <DropdownWrapper className="opacity-65" count={optionField.length + 1}>
          {inputAddComp}
        </DropdownWrapper>
      )}
    </div>
  );
};

export default QuestionType;
