import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { BenefitType } from "@/types/Api";

import BenefitsTypeForm from "../../-components/BenefitsTypeForm";
import { useBenefitTypeDetails, useUpdateBenefitType } from "../../-data/hooks";
import { benefitTypesDetailsLoader } from "../../-data/loaders";
import { benefitTypeResolver } from "../../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/benefits/types/$id/edit",
)({
  loader: benefitTypesDetailsLoader,
  component: () => <BenefitTypeEdit />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function BenefitTypeEdit() {
  const { id } = useParams({
    from: "/_auth/_dashboard/benefits/types/$id/edit",
  });
  const { data } = useBenefitTypeDetails(+id);
  const form = useForm<BenefitType>({
    defaultValues: data,
    resolver: benefitTypeResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateBenefitType();

  const onSubmit = (values: BenefitType) => {
    mutate(values, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.benefits.type.types"),
          }),
        });
        void navigate({ to: "/benefits/types/$id", params: { id } });
      },
      onError: (error) => {
        // If error is an custom validate error not Error from mutate function
        if (!(error instanceof Error)) {
          const errors: Record<string, string[]> =
            (error as Record<string, string[]> | undefined) ?? {};

          Object.keys(errors).forEach((item) => {
            form.setError(item as keyof BenefitType, {
              message: errors[item][0],
            });
          });
        } else {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.benefits.type.types"),
            }),
          });
        }
      },
    });
  };
  const handleClose = () => {
    void navigate({ to: "/benefits/types" });
  };

  return (
    <MainDashboard>
      <BenefitsTypeForm
        title={t("menu.edit", { ns: "dashboard" })}
        form={form}
        isEdit
        onClose={handleClose}
        onSubmit={onSubmit}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
