import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeedbackBody } from "@/types/Api";

import FeedbackForm from "../-components/FeedbackForm";
import { useFeedbackDetails } from "../-data/hooks";
import { feedbackDetailLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/feedbacks/_list/$id")({
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
  loader: feedbackDetailLoader,
  component: () => <FeedbackDetailPage />,
});

function FeedbackDetailPage() {
  const { id } = useParams({ from: "/_auth/_dashboard/feedbacks/_list/$id" });
  const { data: feedbackDetail } = useFeedbackDetails(id);

  const form = useForm<FeedbackBody>({
    defaultValues: {
      ...feedbackDetail,
      rating: feedbackDetail?.rating ?? undefined,
    },
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const handleClose = () => {
    void navigate({ to: "/feedbacks" });
  };

  return (
    <MainDashboard>
      <FeedbackForm
        onClose={handleClose}
        form={form}
        title={t("menu.details")}
      />
    </MainDashboard>
  );
}
