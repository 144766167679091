import { LoaderType } from "@/main";

import { benefitsAtoms, benefitsQueries } from "./store";

export const benefitsListLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    benefitsQueries.list(
      globalStore.get(benefitsAtoms.filter.currentValueAtom),
    ),
  );
  void queryClient.ensureQueryData(benefitsQueries.allTypes());
};

export const benefitsDetailLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: number }>) => {
  void queryClient.ensureQueryData(benefitsQueries.details(id));
  void queryClient.ensureQueryData(benefitsQueries.allTypes());
};

export const benefitsTypesLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    benefitsQueries.types(
      globalStore.get(benefitsAtoms.filterTypes.currentValueAtom),
    ),
  );
};

export const benefitTypesDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(benefitsQueries.detailsType(+id));
};
