import { Typography, Input, Form } from "antd";
import { useTranslation } from "react-i18next";

import {
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  UploadImage,
} from "@/components";
import { useImage } from "@/hooks";
import { formatFullDateTime } from "@/lib/utils";
import { Feedback } from "@/types/Api";

import { FEEDBACK_RATING, FEEDBACK_SUBJECT } from "../-data";

interface Props extends LayoutDetailsFormProps<Feedback> {}

const { TextArea } = Input;

const FeedbackForm = ({ form, title, onClose }: Props) => {
  const { t } = useTranslation("dashboard");
  const getFormAttachmentsValues = () => form.getValues("attachments") ?? [];
  const { getImageUrl } = useImage();

  return (
    <LayoutDetailsForm form={form} title={title} onClose={onClose}>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.feedback.id")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("id")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.subject")}
          </Typography.Text>
        }
      >
        <Input
          value={
            FEEDBACK_SUBJECT.find(
              (item) => item.value === form.getValues("subject"),
            )?.label
          }
          disabled
        />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.feedback.description")}
          </Typography.Text>
        }
        className="col-span-2"
      >
        <TextArea rows={4} value={form.getValues("description")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.feedback.sentDate")}
          </Typography.Text>
        }
      >
        <Input
          value={formatFullDateTime(form.getValues("created_at"))}
          disabled
        />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.feedback.rating")}
          </Typography.Text>
        }
      >
        <Input
          value={
            typeof form.getValues("rating") === "number"
              ? FEEDBACK_RATING[
                  (form.getValues("rating") as unknown as number) - 1
                ].label
              : ""
          }
          disabled
        />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.feedback.photoAttachment")}
          </Typography.Text>
        }
      >
        <div className="flex flex-col">
          <UploadImage
            fileList={getFormAttachmentsValues().map((item, index) => {
              const uniqueName = item + index.toString();
              const url = getImageUrl(item);
              return {
                url: url,
                thumbUrl: url,
                name: uniqueName,
                uid: uniqueName,
                status: "done",
              };
            })}
            className="border-none bg-none"
          />
        </div>
      </Form.Item>
    </LayoutDetailsForm>
  );
};

export default FeedbackForm;
