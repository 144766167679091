import { FunnelPlotOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn } from "@/lib/utils";

import { EventRegStatus } from "../-data";
import { useEventRegistrationFilter } from "../-data/hooks";
import { EventRegistrationQuery } from "../-data/store";

const EventListRegisHeader = () => {
  const { t } = useTranslation("dashboard");
  const { setEventRegisListFilter, eventRegisListFilter } =
    useEventRegistrationFilter();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<Omit<EventRegistrationQuery, "id">>({
    status: undefined,
    search: "",
  });

  const handleChange = (search: string) => {
    setEventRegisListFilter((prev) => {
      return {
        ...prev,
        search,
        page: 1,
      };
    });
  };

  const handleResetFilter = () => {
    setEventRegisListFilter({
      status: undefined,
      search: "",
      page: 1,
    });
    setFilter({
      status: undefined,
      search: "",
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    setEventRegisListFilter((prev) => {
      return {
        ...prev,
        ...filter,
      };
    });
    setOpen(false);
  };

  const shouldDisableResetBtn = !eventRegisListFilter.status?.length;

  return (
    <ListHeader
      title={t("menu.events.listReg")}
      onChange={handleChange}
      search={eventRegisListFilter.search}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            selectable: true,
            selectedKeys:
              (filter.status?.length ?? 0) > 0
                ? (filter.status as string[])
                : [],
            className: "w-[232px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.status")}
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      placeholder={t("menu.select", {
                        what: t("menu.status"),
                      })}
                      showArrow
                      maxTagTextLength={24}
                      className="w-full"
                      options={EventRegStatus}
                      onChange={(status) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            status,
                          };
                        });
                      }}
                      value={filter.status}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "event-status",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default EventListRegisHeader;
