import { atom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/vanilla/utils";
import { UserManager } from "oidc-client-ts";

import { OIDC_CONFIGS, type OidcProvider } from "@/lib/config/oidc-client-ts";
import { LOCAL_STORAGE_KEYS } from "@/lib/constants";

const currentOidcProviderAtom = atomWithStorage<OidcProvider>(
  LOCAL_STORAGE_KEYS.authProvider,
  "azure",
);

// This atom should provides a stable reference to the oidc-client-js UserManager instance
export const authAtom = atom((get) => {
  const currentProvider = get(currentOidcProviderAtom);
  const config = OIDC_CONFIGS[currentProvider];
  const auth = new UserManager(config);

  auth.events.addAccessTokenExpiring(() => {
    auth.signinSilent().catch((err: unknown) => {
      console.error("Failed to silently renew token", err);
    });
  });
  auth.events.addAccessTokenExpired(() => {
    auth.signinRedirect().catch((err: unknown) => {
      console.error("Failed to silently renew token", err);
    });
  });
  auth.events.addSilentRenewError((err: unknown) => {
    console.error("Silent renew error", err);
    auth.signinRedirect().catch((err: unknown) => {
      console.error("Failed to sign out", err);
    });
  });

  return auth;
});

export const useAuth = () => {
  return useAtomValue(authAtom);
};
