import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  createFileRoute,
  redirect,
  Outlet,
  useNavigate,
} from "@tanstack/react-router";
import { notification } from "antd";
import { Avatar, Button, Dropdown, Layout, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useState } from "react";

import { PendingComponent, Stack } from "@/components";
import { authAtom } from "@/jotai-atoms/oidc";
import { userQueries } from "@/jotai-atoms/user";
import { useUserInfo } from "@/jotai-atoms/user";
import { LOGIN_ROUTE } from "@/lib/constants";
import { APP_MODE, AppMode } from "@/lib/constants";
import { isStaff, cn } from "@/lib/utils";

import { Sidebar } from "./-components/Sidebar";

const { Content, Header } = Layout;

const redirectToLogin = () =>
  redirect({
    to: LOGIN_ROUTE,
    from: location.pathname,
  });

export const Route = createFileRoute("/_auth")({
  loader: async ({ context: { queryClient, globalStore } }) => {
    // Auth guard
    const auth = globalStore.get(authAtom);
    const user = await auth.getUser();
    if (!user) {
      redirectToLogin();
    }

    const userInfo = await queryClient.ensureQueryData(userQueries.userInfo());
    if (!userInfo || !isStaff(userInfo)) {
      notification.error({
        message: "You are not authorized to access this page",
        description: "Please contact admin for help!",
      });
      redirectToLogin();
    }
  },
  pendingComponent: () => <PendingComponent />,
  component: () => <Auth />,
});

function Auth() {
  const [collapsed, setCollapsed] = useState(false);
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const dropdownItems: unknown[] = [
    {
      label: "Logout",
      key: 4,
      onClick: () => {
        void navigate({ to: "/auth/logout" });
      },
    },
  ];

  if (APP_MODE === AppMode.DEV) {
    dropdownItems.push(
      ...[
        { label: "My Account", key: 1 },
        { type: "divider" },
        { label: "Settings", key: 2 },
        { label: "Support", key: 3 },
        { type: "divider" },
      ],
    );
  }

  return (
    <Layout hasSider className="min-h-screen">
      <Sidebar collapsed={collapsed} setCollapse={setCollapsed} />
      <Layout
        className={cn(
          "site-layout relative transition-all",
          collapsed ? "ml-[80px]" : "ml-[200px]",
        )}
      >
        <Header
          className={cn(
            "border-b-solid sticky top-0 z-10 flex w-full items-center justify-between border-x-0 border-b border-t-0 border-solid border-b-[#F0F0F0] bg-white p-4",
          )}
        >
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined className="text-xl" />
              ) : (
                <MenuFoldOutlined className="text-xl" />
              )
            }
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
          <Dropdown
            menu={{
              items: dropdownItems as unknown as ItemType[],
            }}
          >
            <Stack className="cursor-pointer gap-2">
              <Avatar>{userInfo.data?.user.email?.[0]}</Avatar>
              <Typography>{userInfo.data?.user.email}</Typography>
            </Stack>
          </Dropdown>
        </Header>
        <Content className="z-0 flex flex-1 flex-col">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
