import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { t } from "i18next";

import { MainDashboard } from "@/components/MainDashboard";

import TargetCustomerForm from "../../../-components/TargetCustomerForm";
import { useTargetCustomerForm } from "../../../-data/hooks";
import { targetCustomerDetailsLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/customers/_list/(details)/$id/",
)({
  component: () => <TargetCustomerDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: targetCustomerDetailsLoader,
});

const TargetCustomerDetailsPage = () => {
  const { id } = useParams({ from: "/_auth/_dashboard/customers/_list/$id/" });
  const { targetCustomerDetails, form, college, cohorts, users } =
    useTargetCustomerForm(id);
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({ to: "/customers" });
  };

  return (
    <MainDashboard>
      <TargetCustomerForm
        created_by={targetCustomerDetails?.created_by?.email ?? ""}
        title={t("menu.details", { ns: "dashboard" })}
        form={form}
        colleges={college ?? []}
        cohorts={cohorts ?? []}
        users={users ?? []}
        onClose={handleClose}
        isEdit={false}
        onEdit={() => {
          void navigate({ to: "/customers/$id/edit", params: { id } });
        }}
      />
    </MainDashboard>
  );
};
