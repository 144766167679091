import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Dropdown, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn, filterSelectOptions } from "@/lib/utils";
import { NewCategory } from "@/types/Api";

interface FilterType {
  search?: string;
  categories?: number[];
}

interface Props {
  search?: string;
  onResetFilter: () => void;
  onApplyFilter: (filter: FilterType) => void;
  onChange: (value: string) => void;
  isLoading?: boolean;
  shouldDisableResetBtn?: boolean;
  categories?: NewCategory[];
}

const NewListHeader = ({
  search,
  onApplyFilter,
  onResetFilter,
  onChange,
  isLoading = false,
  shouldDisableResetBtn = false,
  categories,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<FilterType>({
    search: "",
  });
  const navigate = useNavigate();

  const handleResetFilter = () => {
    onResetFilter();
    setFilter({
      categories: undefined,
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    onApplyFilter(filter);
    setOpen(false);
  };
  const handleAddNew = () => {
    void navigate({ to: "/news/add" });
  };

  return (
    <ListHeader
      title={t("menu.list")}
      search={search}
      onChange={onChange}
      onAddNewClick={handleAddNew}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={isLoading}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      Select Category
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      showArrow
                      maxTagTextLength={24}
                      placeholder={t("menu.select", {
                        what: "category",
                      })}
                      className="w-full"
                      options={categories?.map((item) => {
                        return {
                          value: item.id,
                          label: item.en_name ?? item.name,
                        };
                      })}
                      filterOption={filterSelectOptions}
                      onChange={(value) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            categories: value,
                          };
                        });
                      }}
                      value={filter.categories}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "categories",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default NewListHeader;
