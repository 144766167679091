import { useAtomValue } from "jotai";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { SurveyFormUpdate } from "@/types/Api";

import { surveyAtoms, surveyQueries } from "./store";
import { COUNSELLING_SURVEY_ID } from "@/lib/constants";

export const useSurvey = () => {
  return useAtomValue(surveyAtoms.details("1"));
};

export const useUpdateSurvey = () => {
  return useAppMutation({
    mutationFn: async (data: SurveyFormUpdate) => {
      try {
        await fetchApiClient.surveys.updateSurveyForm(COUNSELLING_SURVEY_ID, data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: surveyQueries.details("1").queryKey,
      });
      surveyAtoms.details.remove("1");
    },
  });
};
