import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { EventType } from "@/types/Api";

import TypesDetailsForm from "../-components/TypesDetailForm";
import { useEventTypesDetails, useUpdateEventTypes } from "../../-data/hooks";
import { eventTypesDetailsLoader } from "../../-data/loaders";
import { eventTypeResolver } from "../../-data/validation";

export const Route = createFileRoute("/_auth/_dashboard/events/types/$id/edit")(
  {
    component: () => <EditEventType />,
    beforeLoad: () => {
      return {
        title: t("menu.edit", { ns: "dashboard" }),
      };
    },
    loader: eventTypesDetailsLoader,
  },
);

const EditEventType = () => {
  const { mutate: updateTypes, isPending: isUpdateSpeakerLoading } =
    useUpdateEventTypes();

  const { id } = useParams({ from: "/_auth/_dashboard/events/types/$id/edit" });
  const { data } = useEventTypesDetails(id);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  function onSubmit(data: EventType) {
    const typesStr = t("menu.events.types").toLowerCase();
    updateTypes(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: typesStr,
          }),
        });
        void navigate({
          to: "/events/types/$id",
          params: { id: data.id?.toString() ?? "" },
        });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: typesStr,
          }),
        });
      },
    });
  }
  const form = useForm({
    defaultValues: data,
    resolver: eventTypeResolver,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleClose = () => {
    void navigate({ to: "/events/types" });
  };

  return (
    <MainDashboard>
      <TypesDetailsForm
        onSubmit={onSubmit}
        isSubmitLoading={isUpdateSpeakerLoading}
        onClose={handleClose}
        isEdit
        form={form}
        title={t("menu.edit")}
      />
    </MainDashboard>
  );
};
