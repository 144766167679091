import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute("/_auth/_dashboard/news")({
  beforeLoad: () => {
    return {
      title: t("menu.news.news", { ns: "dashboard" }),
    };
  },
});
