import { FunnelPlotOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button, Dropdown, notification, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker, ListHeader, Stack } from "@/components";
import { useUploadXlsx } from "@/hooks/useUploadXlsx";
import { DATE_ONLY } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { useOASISAdvisingGroupsFilter } from "../../-data/hooks";
import { AdvisingGroupsFilterState } from "../../-data/store";

interface Props {
  onSearchChange: (value: string) => void;
}

const AdvisingGroupsHeader = ({ onSearchChange }: Props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { filter, setFilter } = useOASISAdvisingGroupsFilter();
  const [advisingGroupFilter, setAdvisingGroupFilter] =
    useState<AdvisingGroupsFilterState>({
      date: filter?.date,
    });

  const [open, setOpen] = useState(false);
  const { mutate, getFileName, isPending } = useUploadXlsx();
  const shouldDisableResetBtn = !filter?.date;

  const handleUpload = (file: File) => {
    mutate(
      { file },
      {
        onSuccess: (data) => {
          void fetchApiClient.oasisPrograms
            .importAdvisingGroupsViaExcelFile({
              filename: getFileName(data),
            })
            .then(() => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.OASIS.uploadSuccess"),
              });
            });
        },
      },
    );
    return false;
  };

  const handleAddNew = () => {
    void navigate({
      to: "/oasis/advising-group/add",
    });
  };

  const handleResetFilter = () => {
    setFilter((prev) => ({ ...prev, page: 1, date: undefined }));
    setAdvisingGroupFilter({ date: undefined });
    setOpen(false);
  };
  const handleApplyFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: 1,
      date: advisingGroupFilter?.date,
    }));
    setOpen(false);
  };

  return (
    <ListHeader
      search={filter?.search}
      onChange={onSearchChange}
      onAddNewClick={handleAddNew}
      title={t("menu.OASIS.advisingGroup")}
      upload={{
        onUpload: handleUpload,
        isLoading: isPending,
      }}
      filter={
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          trigger={["click"]}
          menu={{
            className: "w-[272px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.date")}
                    </Typography.Text>
                    <DatePicker
                      className="w-full"
                      value={
                        advisingGroupFilter?.date
                          ? dayjs(advisingGroupFilter.date)
                          : undefined
                      }
                      onSelect={(e) => {
                        setAdvisingGroupFilter({
                          date: dayjs(e).format(DATE_ONLY),
                        });
                      }}
                      placeholder={t("menu.select", { what: "here" })}
                      format="YYYY-MM-DD"
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "type",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default AdvisingGroupsHeader;
