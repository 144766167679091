import { createFileRoute, Link } from "@tanstack/react-router";
import { notification, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader, MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { useUploadXlsx } from "@/hooks/useUploadXlsx";
import { calculateTableRecordByIndex } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import {
  useOASISPrograms,
  useOASISProgramsFilter,
  useSetOASISCohort,
} from "../-data/hooks";
import { OASISProgramsLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/oasis/_dashboard/")({
  component: () => <OASISDashboardPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: OASISProgramsLoader,
});

function OASISDashboardPage() {
  const { t } = useTranslation("dashboard");
  const { isFetching, data } = useOASISPrograms();
  const { filter, setFilter } = useOASISProgramsFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const setOASISCohort = useSetOASISCohort();
  const dataSource = useDataSource(data?.results);
  const { mutate, getFileName, isPending } = useUploadXlsx();

  const onRow = useOnRowTable({
    to: "/oasis/$cohort",
    onNavigate: (record: (typeof dataSource)[number]) => {
      setOASISCohort(record);
    },
    navigateParams: {
      key: "cohort",
      valueKey: "start_year",
    },
  });

  const handleBeforeUpload = (file: File) => {
    mutate(
      { file },
      {
        onSuccess: (data) => {
          void fetchApiClient.oasisPrograms
            .importOasisProgramProfileViaExcelFile({
              filename: getFileName(data),
            })
            .then(() => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.OASIS.uploadSuccess"),
              });
            });
        },
      },
    );
    return false;
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          title={t("menu.OASIS.OASISDashboard")}
          search={filter?.search}
          onChange={handleSearchChange}
          upload={{
            isLoading: isPending,
            onUpload: handleBeforeUpload,
          }}
          isLoading={isFetching}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "no",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/$cohort"
                    params={{ cohort: record.start_year ?? "" }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => {
                return <Typography.Text>{record.name ?? "-"}</Typography.Text>;
              },
            },
            {
              title: t("menu.cohort"),
              key: "cohort",
              render: (_, record) => {
                return <Typography.Text>{record.cohort}</Typography.Text>;
              },
            },
            {
              title: t("menu.OASIS.numberOfStudents"),
              key: "number_of_students",
              render: (_, record) => {
                return (
                  <Typography.Text>{record.number_of_students}</Typography.Text>
                );
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
