import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { NewCategory } from "@/types/Api";

import NewsCategoryForm from "../../../-components/NewsCategoryForm";
import {
  useNewsCategoryDetails,
  useUpdateNewsCategory,
} from "../../../-data/hooks";
import { newsCategoryDetailsLoader } from "../../../-data/loaders";
import { newsCategoryResolver } from "../../../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/news/category/_details/$id/edit",
)({
  component: () => <NewsCategoryDetailPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: newsCategoryDetailsLoader,
});

function NewsCategoryDetailPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const { id } = useParams({
    from: "/_auth/_dashboard/news/category/_details/$id/edit",
  });
  const { data: newsCategoryDetails } = useNewsCategoryDetails(id);
  const form = useForm<NewCategory>({
    defaultValues: newsCategoryDetails,
    resolver: newsCategoryResolver,
  });
  const { mutate, isPending } = useUpdateNewsCategory();

  const onSubmit = (data: NewCategory) => {
    mutate(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.news.category"),
          }),
        });
        void navigate({ to: "/news/category/$id", params: { id } });
      },
      onError: (error) => {
        // If error is an custom validate error not Error from mutate function
        if (!(error instanceof Error)) {
          const errors: Record<string, string[]> =
            (error as Record<string, string[]> | undefined) ?? {};
          Object.keys(errors).forEach((item) => {
            form.setError(item as keyof NewCategory, {
              message: errors[item][0],
            });
          });
        } else {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.news.category"),
            }),
          });
        }
      },
    });
  };

  const handleClose = () => {
    void navigate({ to: "/news/category" });
  };

  return (
    <MainDashboard>
      <NewsCategoryForm
        isEdit
        title={t("menu.edit")}
        onClose={handleClose}
        isSubmitLoading={isPending}
        form={form}
        onSubmit={onSubmit}
      />
    </MainDashboard>
  );
}
