import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

import { removeContentImgURLDomain } from "@/lib/utils";

export function useAppMutation<TData, TVariables = void>(
  options: UseMutationOptions<TData, unknown, TVariables> & {
    mutationFn: MutationFunction<TData, TVariables>;
  },
): UseMutationResult<TData, unknown, TVariables> {
  const mutationOptions: UseMutationOptions<TData, unknown, TVariables> = {
    ...options,
    mutationFn: (data) => {
      const mutationData =
        typeof data === "object"
          ? (removeContentImgURLDomain(
              data as Record<string, unknown>,
            ) as TVariables)
          : data;
      return options.mutationFn(mutationData);
    },
  };
  return useMutation(mutationOptions);
}
