import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { transformHtmlContent } from "@/lib/utils";
import { Speaker } from "@/types/Api";

import SpeakerDetailForm from "../-components/SpeakerDetailForm";
import { useSpeakerDetails, useUpdateSpeaker } from "../../-data/hooks";
import { eventSpeakerDetailLoader } from "../../-data/loaders";
import { eventSpeakerResolver } from "../../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/speakers/$id/edit",
)({
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: eventSpeakerDetailLoader,
  component: () => <WrapSpeakerDetail />,
});

const WrapSpeakerDetail = () => {
  const { mutate: updateSpeaker, isPending: isUpdateSpeakerLoading } =
    useUpdateSpeaker();
  const { t } = useTranslation("dashboard");

  function onSubmit(data: Speaker) {
    updateSpeaker(
      {
        ...data,
        en_job_title: transformHtmlContent(data.en_job_title),
        job_title: transformHtmlContent(data.job_title),
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.speaker.speaker").toLowerCase(),
            }),
          });
          void navigate({
            to: "/events/speakers/$id",
            params: { id: data.id?.toString() ?? "" },
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.speaker.speaker").toLowerCase(),
            }),
          });
        },
      },
    );
  }
  const { id } = useParams({
    from: "/_auth/_dashboard/events/speakers/$id/edit",
  });
  const { data } = useSpeakerDetails(id);
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: data,
    resolver: eventSpeakerResolver,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const handleClose = () => {
    void navigate({ to: "/events/speakers" });
  };

  return (
    <MainDashboard>
      <SpeakerDetailForm
        isSubmitLoading={isUpdateSpeakerLoading}
        onClose={handleClose}
        onSubmit={onSubmit}
        isEdit
        form={form}
        title={t("menu.edit")}
      />
    </MainDashboard>
  );
};
