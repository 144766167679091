import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Typography, UploadFile } from "antd";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Editor,
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  UploadImage,
} from "@/components";
import { useImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { Speaker } from "@/types/Api";

interface Props extends LayoutDetailsFormProps<Speaker> {}

const SpeakerDetailForm = ({
  onSubmit,
  isSubmitLoading,
  form,
  onEdit,
  isEdit,
  title,
  onClose,
}: Props) => {
  const formPhoto = form.getValues("photo");

  const [fileList, setFileList] = useState<UploadFile[]>(
    formPhoto
      ? [
          {
            uid: "-1",
            name: formPhoto,
            status: "done",
            url: formPhoto,
            thumbUrl: formPhoto,
          },
        ]
      : [],
  );

  const { uploadImage } = useImage();
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm
      onSubmit={onSubmit}
      isSubmitLoading={isSubmitLoading}
      form={form}
      onEdit={onEdit}
      isEdit={isEdit}
      title={title}
      onClose={onClose}
    >
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              className="col-span-2"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enTitle")}
                </Typography.Text>
              }
              help={error?.message}
              required
              validateStatus={error ? "error" : ""}
            >
              <Input
                {...field}
                placeholder={t("menu.enterHere")}
                disabled={!isEdit}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="email"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.email")}
                </Typography.Text>
              }
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                value={field.value ?? ""}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="phone_number"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.phoneNumber")}
                </Typography.Text>
              }
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                value={field.value ?? ""}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="job_title"
        render={({ field }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.speaker.jobTitle")}
                </Typography.Text>
              }
            >
              <Editor
                {...field}
                placeholder={t("menu.editHere")}
                disabled={!isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_job_title"
        render={({ field }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.speaker.enJobTitle")}
                </Typography.Text>
              }
            >
              <Editor
                {...field}
                placeholder={t("menu.editHere")}
                disabled={!isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="company"
        render={({ field }) => {
          return (
            <Form.Item
              className="col-span-2"
              label={
                <Typography.Text className="font-medium">
                  {t("menu.speaker.company")}
                </Typography.Text>
              }
            >
              <Input
                {...field}
                value={field.value ?? ""}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="photo"
        render={({ fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.speaker.image")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <UploadImage
                disabled={!isEdit}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: isEdit,
                }}
                accept={SUPPORTED_IMAGE_TYPES.join(",")}
                onRemove={(file) => {
                  setFileList((prev) => {
                    const result = prev.filter((item) => item.uid !== file.uid);
                    return [...result];
                  });
                  form.setValue("photo", undefined);
                }}
                customRequest={(options) => {
                  void uploadImage(
                    { file: options.file as File },
                    {
                      onSuccess: (url) => {
                        form.setValue("photo", url);
                        form.clearErrors("photo");
                        setFileList([
                          {
                            name: url,
                            url: url,
                            thumbUrl: url,
                            status: "done",
                            uid: crypto.randomUUID(),
                          },
                        ]);
                      },
                    },
                  );
                }}
                fileList={fileList}
                emptyComponent={isEdit ? null : undefined}
              >
                {isEdit && fileList.length === 0 && (
                  <div className="flex flex-col items-center gap-1">
                    <PlusOutlined className="" />
                    <span>{t("menu.upload")}</span>
                  </div>
                )}
              </UploadImage>
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default SpeakerDetailForm;
