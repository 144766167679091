import { useAtomValue, useSetAtom } from "jotai";

import { serviceRequestAtoms } from "./store";

export const useListServiceRequest = () => {
  return useAtomValue(serviceRequestAtoms.list);
};

export const useListServiceRequestFilter = () => {
  const set = useSetAtom(serviceRequestAtoms.filter.debouncedValueAtom);
  const value = useAtomValue(serviceRequestAtoms.filter.currentValueAtom);
  return { set, value };
};
