import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import {
  CreateTargetCustomerRequest,
  UpdateTargetCustomerRequest,
} from "@/types/Api";

const targetCustomerSchema = yup.object().shape(
  {
    name: yup.string().required("Name is required!"),
    colleges: yup.array().when(["cohort", "users", "roles"], {
      is: (
        cohort?: string | null,
        users?: number[] | null,
        roles?: string[] | null,
      ) => !!cohort || (users?.length ?? 0) > 0 || (roles?.length ?? 0) > 0,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.min(1, "One of the criteria is required!"),
    }),
    cohort: yup.string().when(["colleges", "users", "roles"], {
      is: (
        colleges?: (string | number)[],
        users?: number[] | null,
        roles?: string[] | null,
      ) =>
        (colleges?.length ?? 0) > 0 ||
        (users?.length ?? 0) > 0 ||
        (roles?.length ?? 0) > 0,
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.required("One of the criteria is required!"),
    }),
    users: yup
      .array()
      .of(yup.string())
      .when(["cohort", "colleges", "roles"], {
        is: (
          cohort?: string | null,
          colleges?: (string | number)[] | null,
          roles?: string[] | null,
        ) =>
          !!cohort || (colleges?.length ?? 0) > 0 || (roles?.length ?? 0) > 0,
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required("One of the criteria is required!"),
      }),
    roles: yup
      .array()
      .of(yup.string())
      .when(["cohort", "users", "colleges"], {
        is: (
          cohort?: string | null,
          users?: number[] | null,
          colleges?: (number | string)[] | null,
        ) =>
          !!cohort || (users?.length ?? 0) > 0 || (colleges?.length ?? 0) > 0,
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required("One of the criteria is required!"),
      }),
  },
  [
    ["cohort", "colleges"],
    ["cohort", "users"],
    ["cohort", "roles"],
    ["users", "roles"],
    ["users", "colleges"],
    ["roles", "colleges"],
  ],
);

export const targetCustomerResolver = yupResolver(
  targetCustomerSchema,
) as Resolver<UpdateTargetCustomerRequest & CreateTargetCustomerRequest>;
