import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute("/_auth/_dashboard/counselling-service")({
  beforeLoad: () => {
    return {
      title: t("menu.counsellingService.counsellingService", {
        ns: "dashboard",
      }),
    };
  },
});
