import { Link, useNavigate } from "@tanstack/react-router";
import { Table } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { formatFullDateTime } from "@/lib/utils";

import { useGroupEventMedia } from "../../../../-data/hooks";

interface IMediaList {
  groupId: string;
}

const MediaList = ({ groupId }: IMediaList) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { data, isFetching } = useGroupEventMedia(groupId);
  const { t } = useTranslation("dashboard");

  return (
    <div className="bg-white p-6 py-4">
      <ListHeader
        search={search}
        onChange={(value) => {
          setSearch(value);
        }}
        onAddNewClick={() => {
          void navigate({ to: `/events/groups/${groupId}/media/add` });
        }}
        title={t("menu.events.media.mediaLibrary")}
      />
      <Table
        dataSource={data?.results}
        loading={isFetching}
        columns={[
          {
            title: "ID",
            key: "id",
            width: "1ch",
            render: (_, record) => {
              return (
                <Link
                  to={`/events/groups/${groupId}/media/${record.id?.toString() ?? ""}`}
                >
                  {record.id}
                </Link>
              );
            },
          },
          {
            title: "Title",
            key: "title",
            render: (_, record) => {
              return <span>{record.title}</span>;
            },
          },
          {
            title: "Total",
            key: "total",
            render: (_, record) => {
              return <span>{record.total}</span>;
            },
          },
          {
            title: "Created",
            key: "created_at",
            render: (_, record) => {
              return <span> {formatFullDateTime(record.created_at)}</span>;
            },
          },
          {
            title: "Updated",
            key: "updated_at",
            render: (_, record) => {
              return <span> {formatFullDateTime(record.updated_at)}</span>;
            },
          },
        ]}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          current: 1,
        }}
      />
    </div>
  );
};

export default MediaList;
