import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { BenefitType } from "@/types/Api";

import BenefitsTypeForm from "../../-components/BenefitsTypeForm";
import { useBenefitTypeDetails } from "../../-data/hooks";
import { benefitTypesDetailsLoader } from "../../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/benefits/types/$id/")({
  loader: benefitTypesDetailsLoader,
  component: () => <BenefitTypeDetails />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function BenefitTypeDetails() {
  const { id } = useParams({
    from: "/_auth/_dashboard/benefits/types/$id",
  });
  const { data } = useBenefitTypeDetails(+id);
  const form = useForm<BenefitType>({
    defaultValues: data,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({ to: "/benefits/types" });
  };

  const handleEdit = () => {
    void navigate({ to: "/benefits/types/$id/edit", params: { id } });
  };

  return (
    <MainDashboard>
      <BenefitsTypeForm
        title={t("menu.details", { ns: "dashboard" })}
        form={form}
        onClose={handleClose}
        onEdit={handleEdit}
      />
    </MainDashboard>
  );
}
