import { Form, Input, Typography } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { EventType } from "@/types/Api";

interface Props extends LayoutDetailsFormProps<EventType> {}

const TypesDetailForm = ({
  title,
  form,
  onClose,
  onEdit,
  onSubmit,
  isEdit,
  isSubmitLoading,
}: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm
      form={form}
      isEdit={isEdit}
      isSubmitLoading={isSubmitLoading}
      title={title}
      onClose={onClose}
      onEdit={onEdit}
      onSubmit={onSubmit}
    >
      <Controller
        control={form.control}
        name="en_name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.eventTypes.enName")}
                </Typography.Text>
              }
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                value={field.value ?? ""}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.eventTypes.name")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="order"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.eventTypes.order")}
                </Typography.Text>
              }
              className="col-span-2"
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                value={field.value ?? ""}
                disabled={!isEdit}
                type="number"
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default TypesDetailForm;
