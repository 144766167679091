import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Button, Modal, notification, Table } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex } from "@/lib/utils";

import AdvisingGroupsHeader from "../-components/ListHeaders/AdvisingGroupsHeader";
import {
  useDeleteAdvisingGroup,
  useOASISAdvisingGroups,
  useOASISAdvisingGroupsFilter,
} from "../-data/hooks";
import { OASISAdvisingGroupsLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/oasis/advising-group/")(
  {
    component: () => <AdvisingGroups />,
    beforeLoad: () => {
      return {
        title: t("menu.list", { ns: "dashboard" }),
      };
    },
    loader: OASISAdvisingGroupsLoader,
  },
);

function AdvisingGroups() {
  const { data, isFetching } = useOASISAdvisingGroups();
  const { setFilter, filter } = useOASISAdvisingGroupsFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/oasis/advising-group/$id" });
  const dataSource = useDataSource(data?.results);
  const { mutate } = useDeleteAdvisingGroup();

  const formatTimeExceptSeconds = (time?: string | null) => {
    return dayjs(time, "HH:MM:ss").format("HH:MM");
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <AdvisingGroupsHeader onSearchChange={handleSearchChange} />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "index",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/advisor/$id"
                    params={{ id: String(record.id) }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.OASIS.genericAdvisor"),
              key: "advisor",
              render: (_, record) => record.generic_advisor,
            },
            {
              title: t("menu.OASIS.sessionName"),
              key: "session",
              render: (_, record) =>
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                record.en_session_name || record.session_name,
            },
            {
              title: t("menu.date"),
              key: "date",
              render: (_, record) =>
                `${record.date ?? ""} ${formatTimeExceptSeconds(record.start_time)} - ${formatTimeExceptSeconds(record.end_time)}`,
            },
            {
              title: t("menu.action"),
              key: "action",
              className: "flex justify-center",
              render: (_, { id }) => (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: t("menu.OASIS.deleteAdvisingGroupTitle"),
                      icon: (
                        <ExclamationCircleOutlined className="!text-red-500" />
                      ),
                      content: t("menu.OASIS.deleteAdvisingGroupDesc"),
                      onOk() {
                        mutate(String(id), {
                          onSuccess: () => {
                            notification.success({
                              message: t("menu.success"),
                              description: t("menu.delete.success", {
                                what: t(
                                  "menu.OASIS.advisingGroup",
                                  "advising group",
                                ).toLowerCase(),
                              }),
                            });
                          },
                          onError: () => {
                            notification.error({
                              message: t("menu.error"),
                              description: t("menu.delete.error", {
                                what: t(
                                  "menu.OASIS.advisingGroup",
                                  "advising group",
                                ).toLowerCase(),
                              }),
                            });
                          },
                        });
                      },
                    });
                  }}
                  icon={<DeleteOutlined className="text-xl" />}
                  danger
                />
              ),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
