import { Form, Input, Select, Typography } from "antd";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DatePicker,
  LayoutDetailsForm,
  LayoutDetailsFormProps,
} from "@/components";
import { filterSelectOptions } from "@/lib/utils";
import { WebAdvisingGroupAdd } from "@/types/Api";

import { useOASISAllAdvisors, useOASISAllMembers } from "../../-data/hooks";

interface Props extends LayoutDetailsFormProps<WebAdvisingGroupAdd> {}

const { RangePicker } = DatePicker;

const AdvisingGroupForm = (props: Props) => {
  const { form } = props;
  const { t } = useTranslation("dashboard");
  const { data: advisors } = useOASISAllAdvisors();
  const { data: members } = useOASISAllMembers();
  const advisorsOptions = advisors?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const membersOptions = members?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [startTime, endTime] = form.watch(["start_time", "end_time"]);
  const isTimeError =
    form.formState.errors.start_time ?? form.formState.errors.end_time;

  return (
    <LayoutDetailsForm {...props}>
      <Controller
        control={form.control}
        name="generic_advisor"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.OASIS.genericAdvisor")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Select
                {...field}
                showArrow
                showSearch
                maxTagTextLength={24}
                options={advisorsOptions}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", { what: "here" })}
                disabled={!props.isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="peer_advisor"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.OASIS.peerAdvisor")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Select
                {...field}
                onClear={() => {
                  form.reset((values) => {
                    return {
                      ...values,
                      peer_advisor: null,
                    };
                  });
                }}
                showArrow
                showSearch
                allowClear
                maxTagTextLength={24}
                options={advisorsOptions}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", { what: "here" })}
                disabled={!props.isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="academic_advisor"
        render={({ field }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.OASIS.academicAdvisor")}
                </Typography.Text>
              }
            >
              <Select
                {...field}
                onClear={() => {
                  form.reset((values) => {
                    return {
                      ...values,
                      academic_advisor: null,
                    };
                  });
                }}
                showArrow
                showSearch
                allowClear
                maxTagTextLength={24}
                options={advisorsOptions}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", { what: "here" })}
                disabled={!props.isEdit}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_session_name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.OASIS.sessionName")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                disabled={!props.isEdit}
                placeholder={t("menu.enterHere")}
                value={field.value ?? ""}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="date"
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.date")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            required
          >
            <DatePicker
              {...field}
              className="w-full"
              value={field.value ? dayjs(field.value) : undefined}
              disabled={!props.isEdit}
              placeholder={t("menu.select", { what: "here" })}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        )}
      />

      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.time")}
          </Typography.Text>
        }
        validateStatus={isTimeError ? "error" : ""}
        help={isTimeError?.message}
        required
      >
        <RangePicker
          value={
            startTime && endTime
              ? [dayjs(startTime, "HH:MM:ss"), dayjs(endTime, "HH:MM:ss")]
              : undefined
          }
          picker="time"
          onChange={(_, values) => {
            const [start_time, end_time] = values;
            form.setValue("start_time", start_time);
            form.setValue("end_time", end_time);
          }}
          placeholder={["Start Time", "End Time"]}
          className="w-full"
          disabled={!props.isEdit}
        />
      </Form.Item>
      <Controller
        control={form.control}
        name="location"
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.OASIS.location")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            required
          >
            <Input
              {...field}
              disabled={!props.isEdit}
              placeholder={t("menu.enterHere")}
              value={field.value ?? ""}
            />
          </Form.Item>
        )}
      />

      {/* Requirement */}
      <Controller
        control={form.control}
        name="requirements"
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.OASIS.requirements")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            required
          >
            <Input
              {...field}
              disabled={!props.isEdit}
              placeholder={t("menu.enterHere")}
              value={field.value ?? ""}
            />
          </Form.Item>
        )}
      />

      <Controller
        control={form.control}
        name="advising_members"
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.OASIS.members")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            className="col-span-2"
            required
          >
            <Select
              {...field}
              value={field.value}
              mode="multiple"
              allowClear
              showArrow
              showSearch
              filterOption={filterSelectOptions}
              placeholder={t("menu.select", { what: "here" })}
              disabled={!props.isEdit}
              options={membersOptions}
            />
          </Form.Item>
        )}
      />
    </LayoutDetailsForm>
  );
};

export default AdvisingGroupForm;
