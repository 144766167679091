import { createFileRoute, useParams } from "@tanstack/react-router";
import { Modal, ModalFuncProps, notification, Table, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import CheckItem from "@/components/CheckItem";
import { MainDashboard } from "@/components/MainDashboard";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { calculateTableRecordByIndex } from "@/lib/utils";
import { Workshop } from "@/types/Api";

import CVModal from "../-components/Modals/CVModal";
import ITable from "../-components/Table";
import {
  useOASISCohort,
  useOASISProgramDetails,
  useOASISProgramDetailsFilter,
  useUpdateOasisProgram,
} from "../-data/hooks";
import { OASISProgramDetailLoader } from "../-data/loaders";
import { oasisAtoms } from "../-data/store";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/_dashboard/$cohort",
)({
  component: () => <OASISProgramPage />,
  loader: OASISProgramDetailLoader,
  beforeLoad: ({ context: { globalStore }, params: { cohort } }) => {
    return {
      //FIXME: be should provide cohort detail for this
      title: globalStore.get(oasisAtoms.cohort)?.name ?? cohort,
    };
  },
});

function OASISProgramPage() {
  const { cohort } = useParams({
    from: "/_auth/_dashboard/oasis/_dashboard/$cohort",
  });
  const { data, isFetching } = useOASISProgramDetails(+cohort);
  const [cvUrl, setCvUrl] = useState("");
  const { t } = useTranslation("dashboard");
  const OASISCohort = useOASISCohort();
  const { filter, setFilter } = useOASISProgramDetailsFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const { mutate } = useUpdateOasisProgram();
  const dataSource = useDataSource(data?.results);

  const getModalConfirmArgs = (
    checked: boolean,
    type: Workshop["type"],
    id: number,
  ): ModalFuncProps => {
    return {
      title: t("menu.OASIS.whichWarning", {
        which: checked ? t("menu.OASIS.tick") : t("menu.OASIS.untick"),
      }),
      content: t("menu.OASIS.tickModalDescription", {
        tick: checked
          ? t("menu.OASIS.tick", "tick").toLowerCase()
          : t("menu.OASIS.untick", "untick").toLowerCase(),
      }),
      onOk: () => {
        mutate(
          { id, type, cohort: cohort as number },
          {
            onSuccess: () => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.update.success", {
                  what: t("menu.OASIS.OASISProgram"),
                }),
              });
            },
            onError: () => {
              notification.error({
                message: t("menu.error"),
                description: t("menu.update.error", {
                  what: t("menu.OASIS.OASISProgram"),
                }),
              });
            },
          },
        );
      },
      autoFocusButton: null,
      cancelButtonProps: {
        size: "large",
        className: "w-[108px]",
      },
      okButtonProps: {
        size: "large",
        className: "w-[108px] bg-brand-primary",
      },
      cancelText: t("menu.no"),
      okText: t("menu.yes"),
      maskClosable: true,
    };
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          search={filter?.search}
          onChange={handleSearchChange}
          title={OASISCohort?.name ?? String(cohort)}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: "No.",
              key: "id",
              fixed: "left",
              width: 70,
              render: (_, __, index) =>
                calculateTableRecordByIndex(index, filter?.page),
            },
            {
              title: t("menu.name"),
              key: "name",
              fixed: "left",
              width: 112,
              render: (_, record) => {
                const shouldEllipse = (record.name?.length ?? 0) > 20;
                const text = shouldEllipse
                  ? record.name?.slice(0, 20)
                  : record.name;
                return text?.concat(shouldEllipse ? "..." : "");
              },
            },
            {
              title: t("menu.OASIS.studentId"),
              key: "cohort",
              fixed: "left",
              render: (_, record) => record.student_number,
              width: 112,
            },
            {
              title: <ITable.Group>{t("menu.OASIS.orientation")}</ITable.Group>,
              className: "!p-0 w-[302px]",
              children: [
                {
                  title: (
                    <ITable.Col>{t("menu.OASIS.integrityTest")}</ITable.Col>
                  ),
                  className: "!p-0",
                  render: (_, record) => {
                    return (
                      <CheckItem
                        checked={
                          !!record.oasis_program
                            ?.academic_integrity_test_completed
                        }
                      />
                    );
                  },
                },
                {
                  title: <ITable.Col>{t("menu.OASIS.conductTest")}</ITable.Col>,
                  className: "!p-0",
                  render: (_, record) => {
                    return (
                      <CheckItem
                        checked={
                          !!record.oasis_program?.code_of_conduct_test_completed
                        }
                      />
                    );
                  },
                },
              ],
              key: "orientation",
            },
            {
              title: (
                <ITable.Group type="secondary">
                  {t("menu.OASIS.advising")}
                </ITable.Group>
              ),
              className: "!p-0 !border-b-0 w-[121px]",
              key: "orientation",
              children: [
                {
                  className: "!p-0",
                  title: <ITable.Col>{t("menu.OASIS.admissionCv")}</ITable.Col>,
                  render: (_, record) => (
                    <CheckItem
                      checked={!!record.oasis_program?.resume_submitted}
                    />
                  ),
                },
                {
                  className: "!p-0",
                  title: (
                    <ITable.Col>
                      <div className="px-1">IDP</div>
                    </ITable.Col>
                  ),
                  render: (_, record) => (
                    <CheckItem
                      checked={!!record.oasis_program?.idp_completed}
                    />
                  ),
                },
              ],
            },
            {
              title: <ITable.Group>{t("menu.OASIS.skill")}</ITable.Group>,
              className: "!p-0 w-[327px]",
              key: "orientation",
              children: [
                {
                  title: (
                    <ITable.Col>{t("menu.OASIS.collegeReadiness")}</ITable.Col>
                  ),
                  className: "!p-0 text-center",
                  render: (_, record) => (
                    <ITable.Checkbox
                      onChange={(e) => {
                        Modal.confirm(
                          getModalConfirmArgs(
                            e.target.checked,
                            "COLLEGE_READINESS",
                            record.oasis_program?.id ?? 0,
                          ),
                        );
                      }}
                      checked={record.oasis_program?.college_skill_completed}
                    />
                  ),
                },
                {
                  title: (
                    <ITable.Col>{t("menu.OASIS.careerReadiness")}</ITable.Col>
                  ),
                  className: "!p-0 text-center",
                  render: (_, record) => (
                    <ITable.Checkbox
                      onChange={(e) => {
                        Modal.confirm(
                          getModalConfirmArgs(
                            e.target.checked,
                            "CAREER_READINESS",
                            record.oasis_program?.id ?? 0,
                          ),
                        );
                      }}
                      checked={record.oasis_program?.career_skill_completed}
                    />
                  ),
                },
                {
                  title: (
                    <ITable.Col>{t("menu.OASIS.preGraduationCv")}</ITable.Col>
                  ),
                  className: "!p-0 text-center",
                  render: (_, record) => {
                    const link = record.oasis_program?.pre_graduation_cv;
                    return link ? (
                      <Typography.Link
                        onClick={() => {
                          setCvUrl(link);
                        }}
                      >
                        {t("menu.OASIS.viewCv")}
                      </Typography.Link>
                    ) : (
                      "--/--"
                    );
                  },
                },
              ],
            },
            {
              title: (
                <ITable.Group type="secondary">
                  {t("menu.OASIS.identityDiversity")}
                </ITable.Group>
              ),
              className: "!p-0 w-[235px]",
              key: "orientation",
              children: [
                {
                  className: "!p-0",
                  title: (
                    <ITable.Col>{t("menu.OASIS.studentClubs")}</ITable.Col>
                  ),
                  render: (_, record) => (
                    <CheckItem checked={!!record.oasis_program?.student_club} />
                  ),
                },
                {
                  className: "!p-0",
                  title: (
                    <ITable.Col>{t("menu.OASIS.universityAwards")}</ITable.Col>
                  ),
                  render: (_, record) => (
                    <CheckItem
                      checked={!!record.oasis_program?.university_award}
                    />
                  ),
                },
              ],
            },
            {
              title: <ITable.Group>{t("menu.OASIS.spirit")}</ITable.Group>,
              className: "!p-0 !border-b-0 w-[205px]",
              key: "orientation",
              children: [
                {
                  className: "!p-0",
                  title: (
                    <ITable.Col>{t("menu.OASIS.communityService")}</ITable.Col>
                  ),
                  render: (_, record) => (
                    <CheckItem
                      checked={!!record.oasis_program?.community_service}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </div>
      <CVModal
        cvUrl={cvUrl}
        onClose={() => {
          setCvUrl("");
        }}
      />
    </MainDashboard>
  );
}
