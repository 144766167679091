import { Layout, Spin } from "antd";
import { useEffect, useState } from "react";

export const PendingComponent = ({ delay = 150 }: { delay?: number }) => {
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDelayed(true);
    }, delay);
  });

  return (
    <Layout className="flex min-h-screen flex-row items-center justify-center">
      {delayed ? <Spin /> : null}
    </Layout>
  );
};
