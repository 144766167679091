import { useAtomValue, useSetAtom } from "jotai";

import { feedbackAtoms } from "./store";

export const useFeedbacks = () => {
  return useAtomValue(feedbackAtoms.list);
};

export const useFeedbacksFilter = () => {
  const feedbackListState = useAtomValue(feedbackAtoms.filter.currentValueAtom);
  const setFeedbackListState = useSetAtom(
    feedbackAtoms.filter.debouncedValueAtom,
  );
  return {
    feedbackListState,
    setFeedbackListState,
  };
};

export const useFeedbackDetails = (id: string) => {
  return useAtomValue(feedbackAtoms.details(id));
};
