import { userQueries } from "@/jotai-atoms/user";
import { LoaderType } from "@/main";

import { targetCustomerAtoms, targetCustomerQueries } from "./store";

export const targetCustomerLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    targetCustomerQueries.list(
      globalStore.get(targetCustomerAtoms.filter.currentValueAtom),
    ),
  );
};

export const targetCustomerDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(targetCustomerQueries.details(id));
  void queryClient.ensureQueryData(userQueries.colleges());
  void queryClient.ensureQueryData(userQueries.cohorts());
  void queryClient.ensureQueryData(userQueries.all());
};

export const targetCustomerAddLoader = ({
  context: { queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(userQueries.colleges());
};
