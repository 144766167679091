import { Event, EventRegistrationResponse } from "@/types/Api";

export const EventStatus: {
  label: string;
  value: Exclude<Event["status"], undefined>;
}[] = [
  {
    label: "Upcoming",
    value: "UPCOMING",
  },
  {
    label: "On-Going",
    value: "ONGOING",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
];

export const EventRegStatus: {
  label: string;
  value: Exclude<EventRegistrationResponse["status"], undefined>;
}[] = [
  {
    label: "Registered",
    value: "REGISTERED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
  {
    label: "Checked In",
    value: "CHECKED_IN",
  },
];

export const EventRegistrationStatusMap = new Map(
  EventRegStatus.map((item) => [item.value, item]),
);
