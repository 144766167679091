import { createFileRoute, Link } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";
import { NotificationTemplate } from "@/types/Api";

import NotificationListHeader from "../-components/NotificationListHeader";
import NotificationStatus from "../-components/NotificationStatus";
import {
  useListNotificationFilter,
  useListNotifications,
} from "../-data/hooks";
import { notificationLoaders } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/notifications/_list/")({
  loader: notificationLoaders,
  component: () => <NotificationTable />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const NotificationTable = () => {
  const { data, isFetching } = useListNotifications();
  const { filter: notificationFilter, setFilter: setNotificationFilter } =
    useListNotificationFilter();
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable<NotificationTemplate>({
    to: "/notifications/$id",
  });

  const handlePaginateClick = (page: number) => {
    setNotificationFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <NotificationListHeader />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: notificationFilter?.page,
            onChange: handlePaginateClick,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/notifications/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.title"),
              key: "title",
              render: (_, record) => {
                return (
                  <Typography.Text>
                    {record.en_title || record.title || "-"}
                  </Typography.Text>
                );
              },
            },
            {
              title: t("menu.notification.type"),
              key: "type",
              render: (_, record) => {
                return (
                  <Typography.Text>
                    {record.type.en_name || record.type.name}
                  </Typography.Text>
                );
              },
            },
            {
              title: t("menu.notification.createdDate"),
              key: "created_date",
              render: (_, record) => {
                return (
                  <Typography.Text>
                    {formatFullDateTime(record.created_at)}
                  </Typography.Text>
                );
              },
            },
            {
              title: t("menu.notification.createdBy"),
              key: "date",
              render: (_, record) => {
                return (
                  <Typography.Text>{record.created_by?.email}</Typography.Text>
                );
              },
            },
            {
              title: t("menu.status"),
              key: "status",
              render: (_, record) => {
                return <NotificationStatus status={record.status} />;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
};
