import { notification, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useUploadXlsx } from "@/hooks/useUploadXlsx";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { useOASISOrientationFilter } from "../../-data/hooks";

interface Props {
  onReset: () => void;
  onSearchChange: (value: string) => void;
  isSearchLoading?: boolean;
}

const OrientationsHeader = ({
  onReset,
  onSearchChange,
  isSearchLoading,
}: Props) => {
  const { mutate, getFileName, isPending } = useUploadXlsx();
  const { filter } = useOASISOrientationFilter();
  const { t } = useTranslation("dashboard");

  const handleUpload = (file: File) => {
    mutate(
      { file },
      {
        onSuccess: (data) => {
          void fetchApiClient.oasisPrograms
            .importOrientationTestsViaExcelFile({
              filename: getFileName(data),
              cohort: filter?.cohort as unknown as string,
            })
            .then(() => {
              notification.success({
                message: t("menu.success"),
                description: t("menu.OASIS.uploadSuccess"),
              });
            });
        },
      },
    );
    return false;
  };

  return (
    <ListHeader
      isLoading={isSearchLoading}
      onChange={onSearchChange}
      title={
        <div className="flex items-baseline gap-2">
          <Typography.Title className="!mb-0" level={4}>
            {t("menu.OASIS.orientation")}
          </Typography.Title>
          {filter?.cohort && (
            <span
              className="text-xs text-brand-primary cursor-pointer"
              onClick={onReset}
            >
              {t("menu.button.reset")}
            </span>
          )}
        </div>
      }
      upload={{
        isLoading: isPending,
        onUpload: handleUpload,
      }}
    />
  );
};

export default OrientationsHeader;
