import { Modal, Upload, UploadFile } from "antd";
import { RcFile, UploadProps } from "antd/lib/upload";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(new Error());
    };
  });

interface Props extends UploadProps {
  emptyComponent?: ReactNode;
}

export const UploadImage = ({
  fileList,
  emptyComponent = <img className="w-[84px]" src="/NoThumbnail.svg" alt="" />,
  ...props
}: Props) => {
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const { t } = useTranslation("dashboard");

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url ?? file.preview ?? "");
    setPreviewOpen(true);
  };

  return (
    <>
      {(!fileList || fileList.length === 0) && emptyComponent}
      <Upload
        listType="picture-card"
        fileList={fileList}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon: true,
          showRemoveIcon: false,
        }}
        onPreview={(file) => {
          void handlePreview(file);
        }}
        {...props}
      />
      <Modal
        open={previewOpen}
        closable
        maskClosable
        title={t("menu.image.preview")}
        onOk={() => {
          setPreviewImage("");
          setPreviewOpen(false);
        }}
        onCancel={() => {
          setPreviewImage("");
          setPreviewOpen(false);
        }}
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <img alt="preview" className="w-full" src={previewImage} />
      </Modal>
    </>
  );
};
