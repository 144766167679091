import { useMutation } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { useAppMutation } from "@/hooks/useAppMutation";
import { globalStore } from "@/jotai-atoms";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import {
  SkillAdd,
  WebAdvisingGroupAdd,
  WebAdvisorAdd,
  Workshop,
  WorkshopAdd,
} from "@/types/Api";

import { oasisAtoms, oasisQueries } from "./store";

export const useOASISPrograms = () => useAtomValue(oasisAtoms.list);

export const useOASISProgramsFilter = () => {
  const filter = useAtomValue(oasisAtoms.listFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.listFilter.debouncedValueAtom);
  return { filter, setFilter };
};
export const useOASISProgramDetailsFilter = () => {
  const filter = useAtomValue(oasisAtoms.detailsFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.detailsFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useOASISProgramDetails = (cohort: number) => {
  return useAtomValue(oasisAtoms.details(cohort));
};

export const useOASISCohort = () => useAtomValue(oasisAtoms.cohort);
export const useSetOASISCohort = () => useSetAtom(oasisAtoms.cohort);

export const useUpdateOasisProgram = () => {
  return useAppMutation({
    mutationFn: async ({
      id,
      type,
    }: {
      id: number;
      type: Workshop["type"];
      cohort: number;
    }) => {
      try {
        const data = await fetchApiClient.oasisPrograms.updateOasisProgram(id, {
          workshop_type: type,
        });
        return data;
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { cohort }) => {
      void queryClient.invalidateQueries({
        queryKey: oasisQueries.details({ cohort }).queryKey,
      });
    },
  });
};

export const useOASISOrientation = () => useAtomValue(oasisAtoms.orientation);
export const useOASISOrientationFilter = () => {
  const filter = useAtomValue(oasisAtoms.orientationFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.orientationFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useOASISSkills = () => useAtomValue(oasisAtoms.skills);
export const useOASISSkillsFilter = () => {
  const filter = useAtomValue(oasisAtoms.skillsFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.skillsFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useCreateOASISSkill = () => {
  return useAppMutation({
    mutationFn: async (data: SkillAdd) => {
      try {
        await fetchApiClient.oasisPrograms.createASkill(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};
export const useCreateOASISWorkshop = () => {
  return useAppMutation({
    mutationFn: async (data: WorkshopAdd) => {
      try {
        await fetchApiClient.oasisPrograms.createAWorkshop(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useOASISWorkshops = (skill: number) =>
  useAtomValue(oasisAtoms.workshops(skill));
export const useOASISWorkshopsFilter = () => {
  const filter = useAtomValue(oasisAtoms.workshopsFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.workshopsFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useOASISAdvisors = () => useAtomValue(oasisAtoms.advisors);
export const useOASISAdvisorsFilter = () => {
  const filter = useAtomValue(oasisAtoms.advisorsFilter.currentValueAtom);
  const setFilter = useSetAtom(oasisAtoms.advisorsFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useOASISAdvisor = (id: string) =>
  useAtomValue(oasisAtoms.advisorDetails(id));
export const useOASISAllAdvisors = () => useAtomValue(oasisAtoms.allAdvisors);
export const useOASISAllMembers = () => useAtomValue(oasisAtoms.allMembers);

export const useUpdateOASISAdvisor = () =>
  useMutation({
    mutationFn: async (params: { id: string; data: WebAdvisorAdd }) => {
      try {
        await fetchApiClient.oasisPrograms.updateAnAdvisor(
          +params.id,
          params.data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: oasisQueries.advisorDetail(+id).queryKey,
        });
        oasisAtoms.advisorDetails.remove(id);
      }
    },
  });

export const useCreateOASISAdvisor = () =>
  useMutation({
    mutationFn: async (data: WebAdvisorAdd) => {
      try {
        await fetchApiClient.oasisPrograms.createAnAdvisor(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useDeleteAdvisor = () =>
  useMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.oasisPrograms.deleteAnAdvisor(id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: oasisQueries.advisors({
          page: globalStore.get(oasisAtoms.advisorsFilter.currentValueAtom)
            ?.page,
        }).queryKey,
        exact: false,
      });
    },
  });

export const useOASISAdvisingGroups = () =>
  useAtomValue(oasisAtoms.advisingGroups);
export const useOASISAdvisingGroupsFilter = () => {
  const filter = useAtomValue(oasisAtoms.advisingGroupsFilter.currentValueAtom);
  const setFilter = useSetAtom(
    oasisAtoms.advisingGroupsFilter.debouncedValueAtom,
  );
  return { filter, setFilter };
};

export const useOASISAdvisingGroup = (id: string) =>
  useAtomValue(oasisAtoms.advisingGroup(id));

export const useDeleteAdvisingGroup = () =>
  useMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.oasisPrograms.deleteAnAdvisionGroup(id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: oasisQueries.advisingGroups({
          page: globalStore.get(
            oasisAtoms.advisingGroupsFilter.currentValueAtom,
          )?.page,
        }).queryKey,
        exact: false,
      });
    },
  });

export const useUpdateOASISAdvisingGroup = () =>
  useMutation({
    mutationFn: async (params: { id: string; data: WebAdvisingGroupAdd }) => {
      try {
        await fetchApiClient.oasisPrograms.updateAnAdvisingGroup(
          +params.id,
          params.data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: oasisQueries.advisingGroup(+id).queryKey,
        });
        oasisAtoms.advisingGroup.remove(id);
      }
    },
  });

export const useCreateOASISAdvisingGroup = () =>
  useMutation({
    mutationFn: async (data: WebAdvisingGroupAdd) => {
      try {
        await fetchApiClient.oasisPrograms.createAnAdvisingGroup(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
