import { queryOptions } from "@tanstack/react-query";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import { fetchApiClient } from "@/lib/utils/fetchUtils";

export type InternalAppConfigParams = Parameters<
  typeof fetchApiClient.config.getAnAppConfigWithCode
>[0];

export const internalQueries = {
  appConfig: (params: InternalAppConfigParams) => {
    return queryOptions({
      queryKey: ["internal", "app-config", params],
      queryFn: async () => {
        try {
          const data =
            await fetchApiClient.config.getAnAppConfigWithCode(params);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
};

export const internalAtoms = {
  appConfig: atomFamily((params: InternalAppConfigParams) => {
    return atomWithQuery(() => internalQueries.appConfig(params));
  }),
};
