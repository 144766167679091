import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithQuery } from "jotai-tanstack-query";
import { SearchParamsOption } from "ky";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApi } from "@/lib/utils/fetchUtils";
import { LoaderType } from "@/main";

export type CacheFilterParams = SearchParamsOption & {
  pattern?: string;
  type?: string;
};

export interface CacheItem {
  key: string;
  ttl: number;
  size: number;
  type: string;
}

export const systemQueries = {
  cache: (params: CacheFilterParams) =>
    queryOptions({
      queryKey: ["system", "cache", params],
      queryFn: async () => {
        try {
          const filteredParams: SearchParamsOption = {};
          if (params.pattern) {
            filteredParams.pattern = params.pattern;
          }

          if (params.type) {
            filteredParams.type = params.type;
          }
          const response = await fetchApi.get<{
            data: { results: CacheItem[] };
          }>("storages/cache/", {
            searchParams: filteredParams,
          });
          const data = await response.json();
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    }),
};

const cacheFilterAtom = atomWithDebounce<CacheFilterParams>({
  pattern: "*",
});

export const systemAtoms = {
  cacheFilter: cacheFilterAtom,
  cache: atomWithQuery((get) =>
    systemQueries.cache(get(cacheFilterAtom.debouncedValueAtom)),
  ),
};

export const systemCacheLoader = ({
  context: { globalStore, queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    systemQueries.cache(
      globalStore.get(systemAtoms.cacheFilter.currentValueAtom),
    ),
  );
};
