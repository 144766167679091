import { useAtomValue, useSetAtom } from "jotai";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApi, fetchApiClient } from "@/lib/utils/fetchUtils";
import {
  EventType,
  GroupEventAdd,
  GroupEventMediaAdd,
  Speaker,
  WebGroupEventMedia,
} from "@/types/Api";

import { IEventUpdate } from "../-components/Form/EventForm";
import { eventAtoms, eventQueries } from "./store";

export const useAllEvents = () => {
  return useAtomValue(eventAtoms.all);
};

export const useListEvents = () => {
  return useAtomValue(eventAtoms.list);
};

export const useListEventsFilter = () => {
  const filter = useAtomValue(eventAtoms.filter.currentValueAtom);
  const setFilter = useSetAtom(eventAtoms.filter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useEventDetails = (id: string) => {
  return useAtomValue(eventAtoms.details(Number(id)));
};

export const useUpdateEvent = () =>
  useAppMutation({
    mutationFn: async (data: IEventUpdate) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.events.updateAnOutlookEvent(data.id, data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useCreateEvent = () => {
  return useAppMutation({
    mutationFn: async (data: IEventUpdate) => {
      try {
        await fetchApiClient.events.createAEvent(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useDeleteEvent = () => {
  return useAppMutation({
    mutationFn: async (id: number) => {
      try {
        // await fetchApiClient.events.eventsDelete(id);
        await fetchApiClient.events.getDetailEvent(id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useGroupEvents = () => {
  return useAtomValue(eventAtoms.group);
};

export const useGroupEventMedia = (id: number | string) => {
  return useAtomValue(eventAtoms.groupMedia(id));
};

export const useGroupValidEvents = () => {
  return useAtomValue(eventAtoms.groupValid);
};

export const useGroupEventDetails = (id: number) => {
  return useAtomValue(eventAtoms.groupDetails(id));
};

export const useEventTypes = () => {
  return useAtomValue(eventAtoms.listTypes);
};

export const useEventTypesDetails = (id: string) => {
  return useAtomValue(eventAtoms.typesDetails(id));
};

export const useEventTypesFilter = () => {
  const setEventTypesFilter = useSetAtom(
    eventAtoms.typesFilter.debouncedValueAtom,
  );
  const eventTypesFilter = useAtomValue(
    eventAtoms.typesFilter.currentValueAtom,
  );
  return { setEventTypesFilter, eventTypesFilter };
};

export const useUpdateEventTypes = () =>
  useAppMutation({
    mutationFn: async (data: EventType) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.events.updateAnEventType(data.id, data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      const eventTypeId = id?.toString().trim();
      if (eventTypeId) {
        queryClient.removeQueries({
          queryKey: eventQueries.typesDetails(eventTypeId).queryKey,
        });
        eventAtoms.typesDetails.remove(eventTypeId);
      }
    },
  });

export const useCreateEventType = () => {
  return useAppMutation({
    mutationFn: async (data: EventType) => {
      try {
        await fetchApiClient.events.createAnEventType(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useDeleteTypes = () => {
  return useAppMutation({
    mutationFn: async (id: number) => {
      try {
        await fetchApi.delete(`events/types/${id.toString()}`);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useCreateGroupEvent = () => {
  return useAppMutation({
    mutationFn: async (data: GroupEventAdd) => {
      try {
        await fetchApiClient.groupEvents.createAGroupEvent(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useUpdateGroupEvent = () => {
  return useAppMutation({
    mutationFn: async (data: GroupEventAdd & { id: number }) => {
      try {
        await fetchApiClient.groupEvents.updateAGroupEvent(data.id, data);
      } catch (error) {
        const errorThrow = (
          error as { error: { error: string } }
        ).error.error.toString();
        throw new Error(errorThrow);
      }
    },
  });
};

export const useGroupIds = () => {
  return useAtomValue(eventAtoms.groupEventIds);
};
export const useListEventGroupFilter = () => {
  const filter = useAtomValue(eventAtoms.groupFilter.currentValueAtom);
  const setFilter = useSetAtom(eventAtoms.groupFilter.debouncedValueAtom);
  return { filter, setFilter };
};
export const useSetGroupIds = () => {
  return useSetAtom(eventAtoms.groupEventIds);
};
export const useListSpeaker = () => {
  return useAtomValue(eventAtoms.listSpeakers);
};
export const useListSpeakerFilter = () => {
  const setEventSpeakerFilter = useSetAtom(
    eventAtoms.speakerFilter.debouncedValueAtom,
  );
  const eventSpeakerFilter = useAtomValue(
    eventAtoms.speakerFilter.currentValueAtom,
  );
  return { setEventSpeakerFilter, eventSpeakerFilter };
};
export const useCheckEventStatus = () => {
  return useAppMutation({
    mutationFn: async (data: number[]) => {
      try {
        const response = await fetchApiClient.groupEvents.checkEventConditions({
          event_ids: data,
        });
        const { status, error } = response.data as unknown as {
          status: boolean;
          error: string;
        };
        return {
          status,
          error,
        };
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};
export const usePublishGroupEvent = () => {
  return useAppMutation({
    mutationFn: async (id: number) => {
      try {
        await fetchApiClient.groupEvents.publishAGroupEvent(id);
      } catch (error) {
        const errorThrow = (
          error as { error: { error: string } }
        ).error.error.toString();
        throw new Error(errorThrow);
      }
    },
  });
};

export const useCreateEventMedia = () => {
  return useAppMutation({
    mutationFn: async (data: GroupEventMediaAdd) => {
      try {
        await fetchApiClient.groupEventMedia.createAGroupEventMedia(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useUpdateEventMedia = () => {
  return useAppMutation({
    mutationFn: async (data: WebGroupEventMedia) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.groupEventMedia.updateAGroupEventMedia(
          data.id,
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useGroupEventMediaDetail = (id: string) => {
  return useAtomValue(eventAtoms.groupMediaDetail(id));
};

export const useEventRegistrationFilter = () => {
  const eventRegisListFilter = useAtomValue(
    eventAtoms.registrationFilter.currentValueAtom,
  );
  const setEventRegisListFilter = useSetAtom(
    eventAtoms.registrationFilter.debouncedValueAtom,
  );
  return { eventRegisListFilter, setEventRegisListFilter };
};

export const useEventRegistration = (id: string) => {
  return useAtomValue(eventAtoms.registration(id));
};

export const useSpeakerDetails = (id: string) => {
  return useAtomValue(eventAtoms.speakerDetails(id));
};

export const useUpdateSpeaker = () =>
  useAppMutation({
    mutationFn: async (data: Speaker) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.speakers.updateASpeaker(data.id, data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        const speakerId = id.toString();
        queryClient.removeQueries({
          queryKey: eventQueries.speakerDetails(speakerId).queryKey,
        });
        eventAtoms.speakerDetails.remove(speakerId);
      }
    },
  });

export const useCreateSpeaker = () => {
  return useAppMutation({
    mutationFn: async (data: Speaker) => {
      try {
        await fetchApiClient.speakers.createASpeaker(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useDeleteSpeaker = () => {
  return useAppMutation({
    mutationFn: async (id: number) => {
      try {
        await fetchApi.delete(`speakers/${id.toString()}`);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};
