import { createFileRoute, useParams } from "@tanstack/react-router";

import { MainDashboard } from "@/components/MainDashboard";

import MediaList from "./-components/MediaList";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/groups/$id/media/",
)({
  component: () => <WrapGroupEventDetail />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});
const WrapGroupEventDetail = () => {
  const { id } = useParams({
    from: "/_auth/_dashboard/events/groups/$id/media/",
  });
  return (
    <MainDashboard>
      <MediaList groupId={id} />
    </MainDashboard>
  );
};
