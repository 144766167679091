import { FeedbackBody } from "@/types/Api";

export const FEEDBACK_SUBJECT: {
  label: string;
  value: FeedbackBody["subject"];
}[] = [
  { value: "ADMINISTRATIVE_MANAGEMENT", label: "Administrative management" },
  {
    value: "CONSTRUCTION_AND_RENOVATION",
    label: "Construction and renovation",
  },
  { value: "CURRICULUM", label: "Curriculum" },
  { value: "FACILITIES", label: "Facilities" },
  { value: "FACULTY", label: "Faculty" },
  { value: "HEALTHCARE", label: "Healthcare" },
  { value: "OTHER", label: "Other" },
  { value: "STUDENT_SERVICES", label: "Student services" },
  { value: "EXTRACURRICULAR_ACTIVITIES", label: "Extracurricular activities" },
];

export const FEEDBACK_SUBJECT_SET = new Map(
  FEEDBACK_SUBJECT.map((item) => {
    return [item.value, item];
  }),
);

export const FEEDBACK_RATING = [
  { label: "Terrible", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Okay", value: 3 },
  { label: "Good", value: 4 },
  { label: "Amazing", value: 5 },
];
