import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApi, fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";
import { BenefitType } from "@/types/Api";

export type BenefitsListFilterType = Parameters<
  typeof fetchApiClient.benefits.getListBenefits
>[0];

export const benefitsQueries = {
  list: (params: BenefitsListFilterType) => {
    return queryOptions({
      queryKey: ["benefits-list", params],
      queryFn: async () => {
        try {
          const { data } =
            await fetchApiClient.benefits.getListBenefits(params);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
  details: (id: number) => {
    return queryOptions({
      queryKey: ["benefits-details", Number(id)],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.benefits.getDetailBenefit(
            id.toString(),
          );
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  detailsType: (id: number) => {
    return queryOptions({
      queryKey: ["benefits-type-details", Number(id)],
      queryFn: async () => {
        try {
          const response = await fetchApi.get(
            `benefits/types/${id.toString()}/`,
          );
          const result: { data: BenefitType } = await response.json();
          return result.data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  allTypes: () => {
    return queryOptions({
      queryKey: ["benefits-types", "all"],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.benefits.getAllBenefitTypes();
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  types: (params: PaginateFilterState) => {
    return queryOptions({
      queryKey: ["benefits types", "list", params],
      queryFn: async () => {
        try {
          const { data } =
            await fetchApiClient.benefits.getAllBenefitTypesWithPagination(
              params,
            );
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
};

const benefitsListFilterAtom = atomWithDebounce<BenefitsListFilterType>({
  search: "",
  page: 1,
});

const benefitsTypesFilterAtom = atomWithDebounce<PaginateFilterState>({
  search: "",
  page: 1,
});

export const benefitsAtoms = {
  filter: benefitsListFilterAtom,
  filterTypes: benefitsTypesFilterAtom,
  list: atomWithQuery((get) =>
    benefitsQueries.list(get(benefitsListFilterAtom.debouncedValueAtom)),
  ),
  details: atomFamily((id: number) =>
    atomWithQuery(() => benefitsQueries.details(id)),
  ),
  allTypes: atomWithQuery(() => benefitsQueries.allTypes()),
  types: atomWithQuery((get) =>
    benefitsQueries.types(get(benefitsTypesFilterAtom.debouncedValueAtom)),
  ),
  detailsType: atomFamily((id: number) =>
    atomWithQuery(() => benefitsQueries.detailsType(id)),
  ),
};
