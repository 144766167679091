import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute("/_auth/_dashboard/benefits")({
  beforeLoad: () => {
    return {
      title: t("menu.benefits.benefits", { ns: "dashboard" }),
    };
  },
});
