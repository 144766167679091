import { useAtomValue, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { ErrorResponse } from "@/types";
import { NewCategory, News, NewsCreate, User } from "@/types/Api";

import { newsQueries, newsAtoms } from "./store";
import { newsResolver } from "./validation";

export const useCreateNews = () => {
  return useAppMutation({
    mutationFn: async (data: NewsCreate) => {
      try {
        await fetchApiClient.news.createANew(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useUpdateNews = () => {
  return useAppMutation({
    mutationFn: async (data: NewsCreate) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.news.updateANew(data.id, data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: newsQueries.details(id).queryKey,
        });
        newsAtoms.details.remove(id);
      }
    },
  });
};

export const useListNews = () => useAtomValue(newsAtoms.list);
export const useAllCategories = () => useAtomValue(newsAtoms.allCategories);
export const useCategories = () => useAtomValue(newsAtoms.categories);
export const useNewsCategoryDetails = (id: number) => {
  return useAtomValue(newsAtoms.categoryDetail(id));
};
export const useNewsDetails = (id: number) => {
  return useAtomValue(newsAtoms.details(id));
};
export const useUpdateNewsCategory = () => {
  return useAppMutation({
    mutationFn: async (data: NewCategory) => {
      try {
        if (!data.id) {
          throw new Error();
        }
        await fetchApiClient.news.updateACategoryForNew(data.id, data);
      } catch (error) {
        throw (
          ((error as ErrorResponse).error as Error | undefined) ??
          new Error("Something went wrong!")
        );
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: newsQueries.categoryDetails(id).queryKey,
        });
        newsAtoms.categoryDetail.remove(id);
      }
    },
  });
};

export const useCreateNewsCategory = () => {
  return useAppMutation({
    mutationFn: async (data: NewCategory) => {
      try {
        await fetchApiClient.news.createACategoryForNew(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

const defaultValues: News = {
  title: "",
  en_title: "",
  content: "",
  en_content: "",
  thumbnail: undefined,
  external_thumbnail_url: undefined,
};

export const useNewsForm = (id: string) => {
  const { data: categories } = useAllCategories();
  const { data: newsDetails } = useNewsDetails(Number(id));
  const form = useForm<NewsCreate & { created_by?: User }>({
    defaultValues: {
      ...defaultValues,
      ...newsDetails,
      categories: newsDetails?.categories?.map((item) => item.id) ?? [],
      thumbnail:
        newsDetails?.external_id ?? newsDetails?.en_external_id
          ? newsDetails.external_thumbnail_url ?? ""
          : newsDetails?.thumbnail ?? "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: newsResolver,
  });

  return { form, categories, newsDetails };
};

export const useCategoriesFilter = () => {
  const filter = useAtomValue(newsAtoms.categoriesFilter.currentValueAtom);
  const setFilter = useSetAtom(newsAtoms.categoriesFilter.debouncedValueAtom);
  return { filter, setFilter };
};
