import { LoaderType } from "@/main";

import { oasisAtoms, oasisQueries } from "./store";

export const OASISProgramsLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    oasisQueries.list(globalStore.get(oasisAtoms.listFilter.currentValueAtom)),
  );
};

export const OASISProgramDetailLoader = ({
  context: { queryClient },
  params: { cohort },
}: LoaderType<{ cohort: string | number }>) => {
  if (typeof +cohort === "number") {
    void queryClient.ensureQueryData(oasisQueries.details({ cohort: +cohort }));
  }
};

export const OASISOrientationLoader = (props: LoaderType) => {
  OASISProgramsLoader(props);
};

export const OASISSkillsLoader = ({ context: { queryClient } }: LoaderType) => {
  void queryClient.ensureQueryData(oasisQueries.skills({ page: 1 }));
};

export const OASISAdvisorsLoader = ({
  context: { queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(oasisQueries.advisors({ page: 1 }));
  void queryClient.ensureQueryData(oasisQueries.allAdvisors(undefined));
};

export const OASISAdvisorDetailLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(oasisQueries.advisorDetail(+id));
};

export const OASISAdvisingGroupsLoader = ({
  context: { queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(oasisQueries.advisingGroups({ page: 1 }));
};

export const OASISAddAdvisingGroupLoader = ({
  context: { queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(oasisQueries.allAdvisors(undefined));
  void queryClient.ensureQueryData(oasisQueries.allMembers(undefined));
};

export const OASISAdvisingGroupLoader = (props: LoaderType<{ id: string }>) => {
  const {
    context: { queryClient },
    params: { id },
  } = props;
  void queryClient.ensureQueryData(oasisQueries.advisingGroup(Number(id)));
  OASISAddAdvisingGroupLoader(props);
};
