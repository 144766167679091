import { createFileRoute, Link } from "@tanstack/react-router";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";

import NewCategoryHeader from "../-components/NewCategoryHeader";
import { useCategories, useCategoriesFilter } from "../-data/hooks";
import { newsCategoriesListLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/news/category/")({
  component: () => <NewsCategoryPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: newsCategoriesListLoader,
});

function NewsCategoryPage() {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useCategories();
  const { filter: categoriesFilter, setFilter: setCategoriesFilter } =
    useCategoriesFilter();
  const onRow = useOnRowTable({ to: "/news/category/$id" });

  const handlePaginateClick = (page: number) => {
    setCategoriesFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <NewCategoryHeader isLoading={isFetching} />
        <Table
          loading={isFetching}
          onRow={onRow}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: categoriesFilter.page,
            onChange: handlePaginateClick,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/news/category/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.news.enCategory"),
              key: "name",
              render: (_, record) => {
                return <span>{record.en_name}</span>;
              },
            },
            {
              title: t("menu.news.createdAt"),
              key: "created_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.created_at)}</span>;
              },
            },
            {
              title: t("menu.updatedDate"),
              key: "updated_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.updated_at)}</span>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
