import { LoaderType } from "@/main";

import { eventAtoms, eventQueries } from "./store";

export const eventListLoader = ({
  context: { globalStore, queryClient },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    eventQueries.list(globalStore.get(eventAtoms.filter.currentValueAtom)),
  );
};

export const eventSpeakersListLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  const filter = globalStore.get(eventAtoms.speakerFilter.currentValueAtom);
  void queryClient.ensureQueryData(eventQueries.listSpeakers(filter));
};

export const eventDetailLoader = (props: LoaderType<{ id: string }>) => {
  const {
    context: { queryClient },
    params: { id },
  } = props;
  eventSpeakersListLoader(props);
  void queryClient.ensureQueryData(eventQueries.details(Number(id)));
};

export const eventSpeakerDetailLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(eventQueries.speakerDetails(id));
};

export const groupEventLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    eventQueries.group(
      globalStore.get(eventAtoms.groupFilter.currentValueAtom),
    ),
  );
};

export const groupEventDetailsLoader = ({
  context: { queryClient, globalStore },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(
    eventQueries.group(
      globalStore.get(eventAtoms.groupFilter.currentValueAtom),
    ),
  );
  void queryClient.ensureQueryData(eventQueries.groupDetails(+id));
};

export const groupEventMediaDetailLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string; mediaId: string }>) => {
  void queryClient.ensureQueryData(eventQueries.groupEventMediaDetail(id));
};

export const eventRegisListLoader = ({
  context: { queryClient, globalStore },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(
    eventQueries.registrations({
      id,
      ...globalStore.get(eventAtoms.registrationFilter.currentValueAtom),
    }),
  );
};

export const eventTypesLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    eventQueries.types(
      globalStore.get(eventAtoms.typesFilter.currentValueAtom),
    ),
  );
};

export const eventTypesDetailsLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(eventQueries.typesDetails(id));
};

export const addSingleEventLoader = (props: LoaderType) => {
  eventTypesLoader(props);
  eventSpeakersListLoader(props);
};
