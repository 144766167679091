import { useAtomValue, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";

import { useAppMutation } from "@/hooks/useAppMutation";
import { useAllCohorts, useAllColleges, useAllUsers } from "@/jotai-atoms/user";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import {
  CreateTargetCustomerRequest,
  UpdateTargetCustomerRequest,
} from "@/types/Api";

import { targetCustomerAtoms, targetCustomerQueries } from "./store";
import { targetCustomerResolver } from "./validation";

export const useListTargetCustomer = () => {
  return useAtomValue(targetCustomerAtoms.list);
};

export const useListTargetCustomerFilter = () => {
  const filter = useAtomValue(targetCustomerAtoms.filter.currentValueAtom);
  const setFilter = useSetAtom(targetCustomerAtoms.filter.debouncedValueAtom);
  return {
    filter,
    setFilter,
  };
};

export const useTargetCustomerDetails = (id: string) => {
  return useAtomValue(targetCustomerAtoms.details(id));
};

export const useAllTargetCustomer = () => {
  return useAtomValue(targetCustomerAtoms.all);
};

export const useUpdateTargetCustomer = () => {
  return useAppMutation({
    mutationFn: async (data: UpdateTargetCustomerRequest & { id: string }) => {
      try {
        await fetchApiClient.targetCustomers.updateATargetCustomer(
          data.id,
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      queryClient.removeQueries({
        queryKey: targetCustomerQueries.details(id).queryKey,
      });
      targetCustomerAtoms.details.remove(id);
    },
  });
};

export const useCreateTargetCustomer = () => {
  return useAppMutation({
    mutationFn: async (data: CreateTargetCustomerRequest) => {
      try {
        await fetchApiClient.targetCustomers.createATargetCustomer(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });
};

export const useAllRecipientOfTargetCustomer = (id: string) => {
  return useAtomValue(targetCustomerAtoms.allRecipient(id));
};

export const useTargetCustomerForm = (id: string) => {
  const { data: targetCustomerDetails } = useTargetCustomerDetails(id);
  const form = useForm<
    UpdateTargetCustomerRequest & CreateTargetCustomerRequest
  >({
    defaultValues: {
      ...targetCustomerDetails,
      users: targetCustomerDetails?.users?.map((item) => {
        return typeof item === "number" ? item : item.id;
      }),
    },
    reValidateMode: "onSubmit",
    mode: "onSubmit",
    resolver: targetCustomerResolver,
  });
  const { data: college } = useAllColleges();
  const { data: users } = useAllUsers();
  const { data: cohorts } = useAllCohorts();

  return {
    targetCustomerDetails,
    form,
    cohorts,
    college,
    users,
  };
};
