import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

interface Props {
  checked: boolean;
}
const CheckItem = ({ checked }: Props) => {
  return (
    <div className="flex justify-center">
      {checked ? (
        <CheckCircleFilled className="text-2xl text-brand-success" />
      ) : (
        <CloseCircleFilled className="text-2xl text-[#999999]" />
      )}
    </div>
  );
};

export default CheckItem;
