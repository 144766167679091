import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { mappingContentToBase64 } from "@/lib/utils";
import { Speaker } from "@/types/Api";

import { useCreateSpeaker } from "../-data/hooks";
import { eventSpeakerResolver } from "../-data/validation";
import SpeakerDetailForm from "./-components/SpeakerDetailForm";

export const Route = createFileRoute("/_auth/_dashboard/events/speakers/add")({
  component: () => <CreateSpeakerPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

const CreateSpeakerPage = () => {
  const navigate = useNavigate();
  const { mutate: createSpeaker, isPending: isCreateSpeakerLoading } =
    useCreateSpeaker();
  const form = useForm<Speaker>({
    defaultValues: {
      name: "",
      phone_number: "",
      company: "",
      photo: null,
      job_title: "",
      en_job_title: "",
    },
    resolver: eventSpeakerResolver,
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });
  const { t } = useTranslation("dashboard");
  const navigateToSpeakers = () => {
    void navigate({ to: "/events/speakers" });
  };

  function handleSubmit(data: Speaker) {
    createSpeaker(mappingContentToBase64(data, "en_job_title", "job_title"), {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.speaker.speaker").toLowerCase(),
          }),
        });
        navigateToSpeakers();
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.speaker.speaker").toLowerCase(),
          }),
        });
      },
    });
  }

  return (
    <MainDashboard>
      <SpeakerDetailForm
        title={t("menu.add")}
        isSubmitLoading={isCreateSpeakerLoading}
        onClose={navigateToSpeakers}
        form={form}
        onSubmit={handleSubmit}
        isEdit
      />
    </MainDashboard>
  );
};
