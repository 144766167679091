import { Form, Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { formatFullDateTime } from "@/lib/utils";
import { SupportRequest } from "@/types/Api";

interface Props extends LayoutDetailsFormProps<SupportRequest> {}

const SupportRequestForm = ({ form, onClose, title }: Props) => {
  const { t } = useTranslation("dashboard");

  return (
    <LayoutDetailsForm form={form} title={title} onClose={onClose}>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.serviceID")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("id")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.subject")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("subject")} disabled />
      </Form.Item>
      <Form.Item
        className="col-span-2"
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.description")}
          </Typography.Text>
        }
      >
        <TextArea rows={4} value={form.getValues("description")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.type")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("case_type")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.email")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("created_by.email")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.assignEmail")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("created_by.email")} disabled />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.serviceRequest.date")}
          </Typography.Text>
        }
      >
        <Input
          value={formatFullDateTime(form.getValues("created_at"))}
          disabled
        />
      </Form.Item>
      <Form.Item
        className="col-span-2"
        label={
          <Typography.Text className="font-medium">
            {t("menu.status")}
          </Typography.Text>
        }
      >
        <Input value={form.getValues("status")} disabled />
      </Form.Item>
    </LayoutDetailsForm>
  );
};

export default SupportRequestForm;
