import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { WebAdvisingGroupAdd } from "@/types/Api";

import AdvisingGroupForm from "../../../-components/DetailsForms/AdvisingGroupForm";
import { useOASISAdvisingGroup } from "../../../-data/hooks";
import { OASISAdvisingGroupLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/advising-group/_details/$id/",
)({
  component: () => <AdvisingGroupDetailPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
  loader: OASISAdvisingGroupLoader,
});

function AdvisingGroupDetailPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/oasis/advising-group/_details/$id/",
  });
  const { data } = useOASISAdvisingGroup(id);
  const form = useForm<WebAdvisingGroupAdd>({
    defaultValues: {
      ...data,
      advising_members: data?.members?.map((item) => item.id),
      generic_advisor: data?.generic_advisor.id,
      academic_advisor: data?.academic_advisor?.id,
      peer_advisor: data?.peer_advisor?.id,
    },
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  return (
    <MainDashboard>
      <AdvisingGroupForm
        onClose={() => void navigate({ to: "/oasis/advising-group" })}
        onEdit={() =>
          void navigate({
            to: "/oasis/advising-group/$id/edit",
            params: { id },
          })
        }
        title={t("menu.edit")}
        form={form}
      />
    </MainDashboard>
  );
}
