import { Dispatch, SetStateAction, useEffect } from "react";

interface FeatureFilterType {
  page: number;
  search: string;
}
type SetStateActionType<T> = Dispatch<SetStateAction<Partial<T> | undefined>>;

const createHandleFeatureFunc = <T extends FeatureFilterType>(
  feature: keyof T,
) => {
  return (setState: SetStateActionType<T>) => {
    return (value: T[typeof feature]) => {
      setState((prev = {} as T) => {
        const result = {
          ...prev,
          [feature]: value,
        };
        if (feature === "search") {
          result.page = 1;
        }
        return result;
      });
    };
  };
};

export const useCreateHandleFilterFunc = <T extends FeatureFilterType>(
  setState: SetStateActionType<T>,
) => {
  const handleSearchChange = createHandleFeatureFunc<T>("search")(setState);
  const handlePaginateChange = createHandleFeatureFunc<T>("page")(setState);

  useEffect(() => {
    return () => {
      setState({ page: 1, search: "" } as T);
    };
  }, [setState]);

  return { handleSearchChange, handlePaginateChange };
};
