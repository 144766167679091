import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BenefitType } from "@/types/Api";

import BenefitsTypeForm from "../-components/BenefitsTypeForm";
import { useCreateBenefitType } from "../-data/hooks";
import { benefitTypeResolver } from "../-data/validation";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/benefits/types/add")({
  component: () => <BenefitTypesCreatePage />,
  beforeLoad: () => {
    return {
      title: "Add",
    };
  },
});

function BenefitTypesCreatePage() {
  const form = useForm<BenefitType>({
    defaultValues: { name: "", en_name: "" },
    resolver: benefitTypeResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateBenefitType();

  const onSubmit = (values: BenefitType) => {
    mutate(values, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.benefits.type.types"),
          }),
        });
        void navigate({ to: "/benefits/types" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.benefits.type.types"),
          }),
        });
      },
    });
  };
  const handleClose = () => {
    void navigate({ to: "/benefits" });
  };

  return (
    <MainDashboard>
      <BenefitsTypeForm
        title={t("menu.add", { ns: "dashboard" })}
        form={form}
        isEdit
        onClose={handleClose}
        onSubmit={onSubmit}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
