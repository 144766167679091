import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { NewsCreate } from "@/types/Api";

const newsSchema = yup.object().shape(
  {
    title: yup.string().when("en_title", {
      is: (en_title?: string) => !en_title || en_title.length === 0,
      then: (schema) =>
        schema.required("Either VN title or EN title is required."),
      otherwise: (schema) => schema.nullable(),
    }),
    en_title: yup.string().when("title", {
      is: (title: string | undefined) => !title || title.length === 0,
      then: (schema) =>
        schema.required("Either VN title or EN title is required."),
      otherwise: (schema) => schema.nullable(),
    }),
    categories: yup.array().of(yup.number()).min(1, "Categories is required"),
    content: yup.string().when(["title", "external_id"], {
      is: (title?: string, external_id?: number) =>
        (!!title || (title?.length ?? 0) > 0) && !external_id,
      then: (schema) =>
        schema
          .required("Content is required!")
          .max(500000, "Content too large!"),
      otherwise: (schema) => schema.nullable(),
    }),
    en_content: yup.string().when(["en_title", "en_external_id"], {
      is: (value?: string, en_external_id?: number) =>
        (!!value || (value?.length ?? 0) > 0) && !en_external_id,
      then: (schema) =>
        schema
          .required("Content is required!")
          .max(500000, "Content too large!"),
      otherwise: (schema) => schema.nullable(),
    }),
    thumbnail: yup.string().required("Thumbnail is required!"),
    url: yup.string().nullable().url("URL must be a valid URL"),
    en_url: yup.string().nullable().url("En URL must be a valid URL"),
    published: yup.boolean().nullable(), // Optional and can be null
    external_id: yup.number().nullable(),
  },
  [
    ["title", "en_title"],
    ["title", "content"],
    ["en_title", "en_content"],
    ["content", "external_id"],
    ["en_content", "en_external_id"],
  ],
);

const newsCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(1, "Name must be at least 1 character")
    .max(200, "Name cannot exceed 200 characters"),
  en_name: yup
    .string()
    .nullable()
    .max(200, "En name cannot exceed 200 characters"),
});

export const newsCategoryResolver = yupResolver(newsCategorySchema);
export const newsResolver = yupResolver(
  newsSchema,
) as unknown as Resolver<NewsCreate>;
