import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

import { APP_MODE, AppMode } from "@/lib/constants";
import { RouterContext } from "@/main";

import { RouteError404 } from "./-components/404";

const Root = () => {
  const showDevtools = APP_MODE === AppMode.DEV;

  return (
    <div className="relative h-full">
      <Outlet />
      {showDevtools && <TanStackRouterDevtools />}
    </div>
  );
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => <Root />,
  notFoundComponent: () => <RouteError404 />,
});
