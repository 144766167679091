import { Typography, Checkbox as ACheckbox, CheckboxProps } from "antd";
import React from "react";

import { cn } from "@/lib/utils";

interface ChildProps {
  children: React.ReactNode;
}

const Text = ({ children }: ChildProps) => {
  return (
    <Typography className="text-center font-medium text-sm text-white">
      {children}
    </Typography>
  );
};

interface GroupType extends ChildProps {
  type?: "primary" | "secondary";
  className?: string;
}
const Group = ({ type = "primary", className, children }: GroupType) => {
  return (
    <div
      className={cn(
        "h-[34px] flex items-center justify-center",
        type === "primary" ? "bg-brand-primary" : "bg-brand-secondary",
        className,
      )}
    >
      <Text>{children}</Text>
    </div>
  );
};

const Col = ({ children }: ChildProps) => {
  return (
    <div className="bg-[#4890BD] flex-1 flex items-center justify-center px-2 py-1 h-[55px]">
      <Text>{children}</Text>
    </div>
  );
};

const Checkbox = ({ checked, ...props }: CheckboxProps) => {
  return (
    <ACheckbox
      className="w-[24px] h-[24px]"
      checked={checked}
      children={
        <style>
          {`
              .ant-checkbox-inner{
                width: 24px;
                height: 24px;
              }
              .ant-checkbox-checked .ant-checkbox-inner{
                background: var(--color-brand-success);
                border-color: var(--color-brand-success);
              }
              .ant-checkbox-checked .ant-checkbox-inner:after{
                transform: translate(-50%, -50%) rotate(45deg);
                left: 50%;
              }
          `}
        </style>
      }
      {...props}
    />
  );
};

const ITable = {
  Group,
  Col,
  Checkbox,
};

export default ITable;
