import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NewCategory } from "@/types/Api";

import NewsCategoryForm from "../-components/NewsCategoryForm";
import { useCreateNewsCategory } from "../-data/hooks";
import { newsCategoryResolver } from "../-data/validation";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute("/_auth/_dashboard/news/category/add")({
  component: () => <CategoryAddPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function CategoryAddPage() {
  const form = useForm<NewCategory>({
    defaultValues: {},
    resolver: newsCategoryResolver,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const { mutate, isPending } = useCreateNewsCategory();

  const handleClose = () => {
    void navigate({ to: "/news/category" });
  };

  const handleSubmit = (data: NewCategory) => {
    mutate(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.news.category"),
          }),
        });
        void navigate({ to: "/news/category" });
      },
      onError: (error) => {
        // If error is an custom validate error not Error from mutate function
        if (!(error instanceof Error)) {
          const errors: Record<string, string[]> =
            (error as Record<string, string[]> | undefined) ?? {};
          Object.keys(errors).forEach((item) => {
            form.setError(item as keyof NewCategory, {
              message: errors[item][0],
            });
          });
        } else {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.news.category"),
            }),
          });
        }
      },
    });
  };

  return (
    <MainDashboard>
      <NewsCategoryForm
        title={t("menu.details")}
        onClose={handleClose}
        form={form}
        onSubmit={handleSubmit}
        isSubmitLoading={isPending}
        isEdit
      />
    </MainDashboard>
  );
}
