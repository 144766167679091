import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { NewCategory } from "@/types/Api";

import NewsCategoryForm from "../../../-components/NewsCategoryForm";
import { useNewsCategoryDetails } from "../../../-data/hooks";
import { newsCategoryDetailsLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/news/category/_details/$id/",
)({
  component: () => <NewsCategoryDetailPage />,
  loader: newsCategoryDetailsLoader,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

const NewsCategoryDetailPage = () => {
  const { id } = useParams({
    from: "/_auth/_dashboard/news/category/_details/$id/",
  });
  const { data: newsCategoryDetails } = useNewsCategoryDetails(id);
  const form = useForm<NewCategory>({
    defaultValues: newsCategoryDetails,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const handleClose = () => {
    void navigate({ to: "/news/category" });
  };
  const handleEdit = () => {
    void navigate({ to: "/news/category/$id/edit", params: { id } });
  };

  return (
    <MainDashboard>
      <NewsCategoryForm
        title={t("menu.details")}
        onClose={handleClose}
        form={form}
        onEdit={handleEdit}
      />
    </MainDashboard>
  );
};
