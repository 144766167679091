import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { NewCategory } from "@/types/Api";

import NewsForm from "../../../-components/NewsForm";
import { useNewsForm } from "../../../-data/hooks";
import { newsDetailLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/news/_list/(details)/$id/",
)({
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: newsDetailLoader,
  component: () => <UpdateNewsPage />,
});

function UpdateNewsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/news/_list/$id/",
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { form, categories } = useNewsForm(id.toString());

  return (
    <MainDashboard>
      <NewsForm
        onEdit={() => {
          void navigate({
            to: "/news/$id/edit",
            params: { id },
          });
        }}
        form={form}
        categories={(categories ?? []) as NewCategory[]}
        title={t("menu.details")}
      />
    </MainDashboard>
  );
}
