import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";
import { joinURL } from "ufo";

import { ISurveyQuestionType } from "@/types";
import { UserInfoResponse } from "@/types/Api";

import {
  BLOB_STORAGE_URL,
  PREFIX_IMG_FILE,
  SUPPORTED_IMAGE_TYPES,
} from "../constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function sliceURLDomain(uri?: string | null): string {
  try {
    const url = new URL(uri ?? "");
    return url.toString().replace(`${url.origin}/files/`, "");
  } catch (error) {
    return uri ?? "";
  }
}

export function removeContentImgURLDomain(content: Record<string, unknown>) {
  const results = structuredClone(content);
  Object.keys(results).forEach((key) => {
    const url = results[key];
    if (
      typeof url === "string" &&
      SUPPORTED_IMAGE_TYPES.some((item) =>
        url.endsWith(item.replace(PREFIX_IMG_FILE, "")),
      )
    ) {
      results[key] = sliceURLDomain(url);
    }
  });
  return results;
}

function convertStringToBase64(value?: string | null) {
  const strValue = value ?? "";
  try {
    return btoa(unescape(encodeURIComponent(strValue)));
  } catch (error) {
    return strValue;
  }
}

function constructMappingContent(converter: (value: string | null) => string) {
  return function <T extends object>(content: T, ...keys: (keyof T)[]) {
    const result = structuredClone(content);
    keys.forEach((key) => {
      if (key in result) {
        if (typeof result[key] === "string") {
          Object.defineProperty(result, key, {
            value: converter(result[key]),
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
      }
    });
    return result;
  };
}

function addHTMLHeaderTagForPublishContent(content?: string | null) {
  const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <link rel="stylesheet" href="https://cdn.ckeditor.com/ckeditor5/43.1.0/ckeditor5-content.css">
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"
            rel="stylesheet"
          />
      </head>
      <body>
          <div class="ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-read-only ck-column-resize_disabled" lang="en" dir="ltr" role="textbox" aria-label="Editor editing area: main. Press Alt+0 for help." contenteditable="false">
            ${content ?? ""}
          </div>
      </body>
    </html>
  `;
  return content?.includes("<meta") ? content : htmlContent;
}

export const mappingContentToBase64 = constructMappingContent(
  convertStringToBase64,
);

export const mappingContentWithHtmlHeaderTag = constructMappingContent(
  addHTMLHeaderTagForPublishContent,
);

export function transformHtmlContent(
  content?: string | null,
  options?: {
    addHtmlHeaderTags?: boolean;
  },
) {
  const result = options?.addHtmlHeaderTags
    ? addHTMLHeaderTagForPublishContent(content ?? "")
    : content ?? "";
  return convertStringToBase64(result);
}

export function validateThumbnail(value: string) {
  // Function to check if the value is a valid file
  const isValidFile = (file: File) => {
    return SUPPORTED_IMAGE_TYPES.includes(file.type);
  };

  // Function to check if the value is a valid URI
  const isValidURI = (uri: string) => {
    try {
      // Use URL constructor for robust URI validation
      new URL(uri);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Check if value is a File object or a URI string
  return (
    ((value as unknown) instanceof File &&
      isValidFile(value as unknown as File)) ||
    (typeof value === "string" && isValidURI(value))
  );
}

export const filterSelectOptions = (
  input: string,
  option?: {
    label?: string;
    value?: number | string;
  },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const formatFullDateTime = (
  date?: Date | string | null,
  formatStr = "YYYY-MM-DD HH:mm:ss",
) => {
  return dayjs(date).format(formatStr);
};

export const getImageURL = (path: string) => {
  if (path.startsWith("https://")) {
    return path;
  }

  const url = joinURL(BLOB_STORAGE_URL, path);
  return url;
};

export const debounce = <T extends unknown[]>(
  func: (...args: T) => void,
  wait: number,
) => {
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
    return false;
  };
};

export function isStaff(userInfo: UserInfoResponse): boolean {
  return userInfo.user.is_staff ?? false;
}

export const convertFilePath = (path: string) => {
  const newPath = path.replace(/^https?:\/\/[^\\/]+\/files\//, "");
  return newPath;
};

export function formatBytes(bytes: number): string {
  if (bytes === 0) return "0B";

  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = sizes[i];
  const num = (bytes / Math.pow(1024, i)).toFixed(i > 1 ? 2 : 0);

  return `${num} ${size}`;
}

export function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const hoursDisplay = hours > 0 ? `${hours.toFixed(0)}h` : "";
  const minutesDisplay = minutes > 0 ? `${minutes.toFixed(0)}m` : "";
  const secondsDisplay = secs > 0 ? `${secs.toFixed(0)}s` : "";

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
}

export const upperFirstLetter = (item: string) => {
  const lowerCaseItem = item.toLowerCase();
  const uppercaseFirstLetter = lowerCaseItem.charAt(0).toUpperCase();
  return `${uppercaseFirstLetter}${lowerCaseItem.slice(1, lowerCaseItem.length)}`;
};

export const checkQuestionTypeIsChoice = (
  questionType?: ISurveyQuestionType,
) => {
  return (
    questionType === "CHECKBOXES" ||
    questionType === "DROPDOWN" ||
    questionType === "MULTIPLE_CHOICE"
  );
};

export const createHideToolbarPDFUrl = (pdfLink?: string) => {
  return pdfLink?.concat("#toolbar=0&navpanes=0&scrollbar=0");
};

export const calculateTableRecordByIndex = (
  index: number,
  page: number | undefined = undefined,
) => {
  return index + 1 + ((page ?? 1) - 1) * 10;
};
