import { useQueryClient } from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useAllColleges } from "@/jotai-atoms/user";

import EventForm, { IEventUpdate } from "../../-components/Form/EventForm";
import {
  useDeleteEvent,
  useEventDetails,
  useEventTypes,
  useListSpeaker,
  useUpdateEvent,
} from "../../-data/hooks";
import { eventDetailLoader } from "../../-data/loaders";
import { eventUpdateResolver } from "../../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/_singleEvent/$id/",
)({
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
  loader: eventDetailLoader,
  component: () => <EventDetailPage />,
});

const defaultValues: IEventUpdate = {
  title: "",
  created_by: -1,
  content: "",
  en_content: "",
  start_at: new Date().toISOString(),
  summary: "",
  en_title: "",
  contact: "",
  url: "",
  en_url: "",
  en_summary: "",
  speakers: [],
  thumbnail: "",
  agendas: [],
};

function EventDetailPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/events/_singleEvent/$id/",
  });
  const { data: eventDetail } = useEventDetails(id);
  const { data: eventTypes } = useEventTypes();
  const { data: eventSpeakers } = useListSpeaker();
  const navigate = useNavigate();
  const { mutate: updateEvent, isPending: isUpdateEventLoading } =
    useUpdateEvent();
  const { mutate: deleteEvent, isPending: isDeleteEventLoading } =
    useDeleteEvent();
  const queryClient = useQueryClient();
  const { data: colleges } = useAllColleges();

  const form = useForm<IEventUpdate>({
    defaultValues: {
      ...defaultValues,
      ...eventDetail,
      status: eventDetail?.status === "CANCELLED" ? "CANCELLED" : "ACTIVE",
      agendas: eventDetail?.agendas,
      thumbnail: eventDetail?.thumbnail ?? "",
      speakers: eventDetail?.speakers?.map((speaker) => speaker.id) ?? [],
      event_types:
        eventDetail?.event_types?.map((eventType) => eventType.id) ?? [],
      created_by: eventDetail?.created_by?.id,
    },
    resolver: eventUpdateResolver as unknown as Resolver<IEventUpdate, unknown>,
    mode: "all",
  });
  const { t } = useTranslation("dashboard");

  function onSubmit(data: IEventUpdate) {
    updateEvent(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.events.events"),
          }),
        });
        void queryClient.invalidateQueries({
          queryKey: ["event-detail", +(data.id ?? 0)],
        });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: t("menu.events.events"),
          }),
        });
      },
    });
  }

  const handleConfirmDelete = () => {
    deleteEvent(+id, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: "Delete event successfully",
        });
        void navigate({ to: "/events" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: "There was a problem with your request.",
        });
      },
    });
  };

  return (
    <MainDashboard>
      <EventForm
        type="view"
        form={form}
        colleges={colleges ?? []}
        title={t("menu.details", { ns: "dashboard" })}
        eventSpeakers={eventSpeakers?.results ?? []}
        eventTypes={eventTypes?.results ?? []}
        createByEmail={eventDetail?.created_by?.email ?? ""}
        submitAction={{ onSubmit, isLoading: isUpdateEventLoading }}
        deleteAction={{
          onDelete: handleConfirmDelete,
          isLoading: isDeleteEventLoading,
        }}
      />
    </MainDashboard>
  );
}
