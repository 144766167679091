import { createFileRoute } from "@tanstack/react-router";

import { globalStore } from "@/jotai-atoms";
import { authAtom } from "@/jotai-atoms/oidc";

export const Route = createFileRoute("/_public/auth/logout")({
  beforeLoad: () => {
    const auth = globalStore.get(authAtom);
    auth.signoutRedirect().catch((e: unknown) => {
      console.error(e);
    });
  },
});
