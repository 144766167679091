import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { upperFirstLetter } from "@/lib/utils";
import {
  SkillAdd,
  WebAdvisingGroupAdd,
  WebAdvisorAdd,
  Workshop,
  WorkshopAdd,
} from "@/types/Api";

const currentYear = new Date().getFullYear();
export const COHORT_OPTIONS = Array.from(
  { length: currentYear - 2019 + 1 },
  (_, index) => {
    const year = 2019 + index;
    return { label: String(year), value: String(year) };
  },
).reverse();

export const OASIS_WORKSHOPS_TYPES: Record<string, Workshop["type"]> = {
  colleges: "COLLEGE_READINESS",
  career: "CAREER_READINESS",
};

export const OASIS_WORKSHOPS_OPTIONS = Object.entries(
  OASIS_WORKSHOPS_TYPES,
).map(([_, value]) => ({
  label: upperFirstLetter(value.replace("_", "-")),
  value,
}));

const AddSkillSchema = yup.object().shape({
  name: yup.string().required("Title is required!"),
  en_name: yup.string().required("Title (English) is required!"),
  cohort: yup.string().required("Cohort is required"),
});

const AddWorkshopSchema = yup.object().shape({
  name: yup.string().required("Title is required!"),
  en_name: yup.string().required("Title (English) is required!"),
  type: yup.string().required("Type is required!"),
  date: yup.string().required("Date is required!"),
});

const AdvisorSchema = yup.object().shape({
  name: yup.string().required("Name is required!"),
  email: yup.string().email("Must be a valid email!").nullable(),
});

const AdvisingGroupSchema = yup.object().shape({
  start_time: yup.string().required("Start time is required!"),
  end_time: yup.string().required("End time is required!"),
  date: yup.string().required("Date is required!"),
  en_session_name: yup.string().required("Session name is required!"),
  location: yup.string().required("Location is required!"),
  requirements: yup.string().required("Requirements are required!"),
  advising_members: yup
    .array()
    .of(yup.number())
    .min(1, "At least one advising member is required!"),
  generic_advisor: yup.number().required("Generic advisor is required!"),
});

export const addSkillResolver = yupResolver(
  AddSkillSchema,
) as unknown as Resolver<SkillAdd>;

export const addWorkshopResolver = yupResolver(
  AddWorkshopSchema,
) as unknown as Resolver<WorkshopAdd>;

export const advisorResolver = yupResolver(
  AdvisorSchema,
) as unknown as Resolver<WebAdvisorAdd>;

export const advisingGroupResolver = yupResolver(
  AdvisingGroupSchema,
) as unknown as Resolver<WebAdvisingGroupAdd>;
