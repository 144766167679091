import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useAllColleges, useUserInfo } from "@/jotai-atoms/user";

import EventForm, { IEventUpdate } from "../-components/Form/EventForm";
import { useCreateEvent, useEventTypes, useListSpeaker } from "../-data/hooks";
import { addSingleEventLoader } from "../-data/loaders";
import { eventUpdateResolver } from "../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/events/_singleEvent/add",
)({
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  loader: addSingleEventLoader,
  component: () => <CreateEventPage />,
});

const defaultValues: IEventUpdate = {
  title: "",
  created_by: -1,
  start_at: new Date().toISOString(),
  summary: "",
  en_title: "",
  speakers: [],
  agendas: [],
};

const CreateEventPage = () => {
  const { data: eventTypes } = useEventTypes();
  const { data: eventSpeakers } = useListSpeaker();
  const userInfo = useUserInfo();
  const { mutate, isPending } = useCreateEvent();
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { data: colleges } = useAllColleges();

  const form = useForm<IEventUpdate>({
    defaultValues: {
      ...defaultValues,
      created_by: userInfo.data?.user.user ?? 1,
    },
    resolver: eventUpdateResolver as unknown as Resolver<IEventUpdate, unknown>,
    mode: "all",
  });

  function onSubmit(data: IEventUpdate) {
    mutate(data, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: "Create event successfully",
        });
        window.scrollTo(0, 0);
        void navigate({ to: `/events` });
      },
      onError: (err) => {
        notification.error({
          message: t("menu.error"),
          description: `Create event failed: ${(err as Error | undefined)?.message ?? ""}`,
        });
      },
    });
  }

  return (
    <MainDashboard>
      <EventForm
        type="create"
        form={form}
        createByEmail={userInfo.data?.user.email ?? ""}
        eventSpeakers={eventSpeakers?.results ?? []}
        eventTypes={eventTypes?.results ?? []}
        submitAction={{
          onSubmit,
          isLoading: isPending,
        }}
        title="Add Event"
        colleges={colleges ?? []}
      />
    </MainDashboard>
  );
};
