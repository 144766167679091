import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Upload,
  Button,
  Card,
  UploadFile,
  Divider,
  Typography,
  UploadProps,
  Modal,
  message,
} from "antd";
import { RuleObject } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker, Editor, TimePicker } from "@/components";
import { useImage, useResizeImage } from "@/hooks";
import { SUPPORTED_IMAGE_TYPES } from "@/lib/constants";
import { cn, filterSelectOptions, transformHtmlContent } from "@/lib/utils";
import {
  Event,
  EventList,
  EventType,
  EventUpdate,
  SpeakerList,
  TargetCustomerColleges,
} from "@/types/Api";

const { Title } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;
export interface IEventUpdate extends Omit<EventUpdate, "agendas"> {
  agendas?: EventList["agendas"];
  end_at?: string;
  read_only?: boolean;
}
export interface LayoutDetailPageProps<TFormData extends FieldValues> {
  children?: ReactNode;
  title?: string;
  form: UseFormReturn<TFormData>;
  // onSubmitValid: (data: TFormData) => void;
  className?: string;
  // handleConfirmDelete?: () => void;
  // shouldRenderDeleteButton?: boolean;
  submitAction: {
    onSubmit: (data: TFormData) => void;
    isLoading: boolean;
  };
  deleteAction?: {
    onDelete: () => void;
    isLoading: boolean;
  };
}
interface EventForm extends LayoutDetailPageProps<IEventUpdate> {
  type?: "view" | "create";
  createByEmail: string;
  eventSpeakers: SpeakerList[];
  eventTypes: EventType[];
  colleges: TargetCustomerColleges[];
}

const EventForm = ({
  form,
  type,
  submitAction,
  eventSpeakers,
  eventTypes,
  title,
  colleges,
}: EventForm) => {
  const navigate = useNavigate();
  const defaultValues = form.formState.defaultValues;
  const [isEditMode, setEditMode] = useState(type === "create");
  const optionsSpeakers = eventSpeakers.map((speaker) => ({
    label: speaker.name,
    value: speaker.id,
  }));
  const optionsEventTypes = eventTypes.map((eventType) => ({
    label: eventType.en_name ?? "",
    value: eventType.id,
  }));
  const { getOptimizedImageUrl } = useImage();
  const { t } = useTranslation("dashboard");

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    const hasThumailDefault = defaultValues?.thumbnail;
    if (defaultValues?.external_id && defaultValues.external_thumbnail_url) {
      setFileList([
        {
          uid: "-1",
          name: defaultValues.external_thumbnail_url,
          status: "done",
          url: defaultValues.external_thumbnail_url,
          thumbUrl: defaultValues.external_thumbnail_url,
        },
      ]);
    } else if (hasThumailDefault) {
      setFileList([
        {
          uid: "-1",
          name: defaultValues.thumbnail,
          status: "done",
          url: defaultValues.thumbnail,
          thumbUrl: defaultValues.thumbnail,
        },
      ]);
    }
  }, [defaultValues]);
  const { uploadImage } = useImage();
  const { mutate: resizeImage } = useResizeImage();

  const transformedAgendas = (defaultValues?.agendas ?? []).map((agenda) => ({
    ...agenda,
    start_time: dayjs(agenda?.start_time, "HH:mm:ss"),
    end_time: dayjs(agenda?.end_time, "HH:mm:ss"),
  }));

  const beforeUpload = (file: UploadFile) => {
    const isImageType = SUPPORTED_IMAGE_TYPES.includes(file.type ?? "");
    if (!isImageType) {
      void message.error(`${file.name} is not a image file`);
    }
    return isImageType || Upload.LIST_IGNORE;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length === 0) {
      setFileList([]);
      return;
    }

    const newFile = newFileList[newFileList.length - 1].originFileObj as File;

    const url = getOptimizedImageUrl(newFile.name);
    setFileList([
      {
        uid: "-1",
        percent: 50,
        name: newFile.name,
        status: "uploading",
      },
    ]);

    void uploadImage(
      { file: newFile },
      {
        onSuccess: (thumbnailUrl) => {
          setFileList([
            {
              uid: "-1",
              name: newFile.name,
              status: "done",
              url,
              thumbUrl: url,
            },
          ]);

          const filename = new URL(thumbnailUrl).pathname.replace(
            "/files/",
            "",
          );
          resizeImage({ images: [{ path: filename }] });

          if (warningThumnbnail) {
            setWarningThumnbnail(false);
          }
        },
        onError: (err) => {
          void message.error(
            `Error uploading file: ${(err as Error | undefined)?.message ?? ""}`,
          );
          setFileList([]);
        },
      },
    );
  };

  // Handle State
  const [viDescription, setViDescription] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [contactContent, setContactContent] = useState("");
  const handleViEditorChange = (data: string) => {
    setViDescription(data);
  };
  const handleEnEditorChange = (data: string) => {
    setEnDescription(data);
  };
  const handleContactEditorChange = (data: string) => {
    setContactContent(data);
  };

  //Handle preview thumnail
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [warningThumnbnail, setWarningThumnbnail] = useState(false);

  const handlePreview = () => {
    if (fileList[0].url) {
      setPreviewImage(fileList[0].url);
      setPreviewOpen(true);
    }
  };
  const handleCancel = () => {
    setPreviewOpen(false);
  };

  // Handle submit form
  const onFinish = (values: IEventUpdate) => {
    if (!viTitle && !enTitle) {
      window.scrollTo(0, 0);
      setIsWarmingTitle(true);
      return;
    }
    if (fileList.length > 0 && fileList[0].url) {
      const updatedAgendas = values.agendas?.map((agenda) => {
        const start = dayjs(agenda.start_time);
        const end = dayjs(agenda.end_time);

        const formattedStart = start.format("YYYY-MM-DDTHH:mm:ss");
        const formattedEnd = end.format("YYYY-MM-DDTHH:mm:ss");

        return {
          ...agenda,
          detail: transformHtmlContent(agenda.detail),
          en_detail: transformHtmlContent(agenda.en_detail),
          start_time: formattedStart,
          end_time: formattedEnd,
        };
      });
      const newThumbnail = fileList[0].url.replace(
        /^https?:\/\/[^\\/]+\/files\//,
        "",
      );
      const updatedValues = {
        ...values,
        start_at: dayjs(values.start_at).format("YYYY-MM-DDThh:mm"),
        end_at: dayjs(values.end_at).format("YYYY-MM-DDThh:mm"),
        agendas: updatedAgendas,
        id: defaultValues?.id,
        created_by: defaultValues?.created_by ?? 0,
        thumbnail: newThumbnail,
        content: transformHtmlContent(values.content),
        en_content: transformHtmlContent(values.en_content),
        contact: transformHtmlContent(values.contact),
        location: transformHtmlContent(values.location),
      };
      if (values.status) {
        confirm({
          title: "Are you sure you want to cancel this event?",
          icon: <ExclamationCircleOutlined />,
          content: "This action cannot be undone.",
          onOk() {
            submitAction.onSubmit({
              ...updatedValues,
              status: "CANCELLED",
            });
            setEditMode(false);
            return;
          },
          onCancel() {
            return;
          },
        });
      } else {
        submitAction.onSubmit({
          ...updatedValues,
          status: "ACTIVE",
        });
        if (hasDefaultValue) {
          setEditMode(false);
        }
      }
    } else {
      setWarningThumnbnail(true);
    }
  };
  const [formData] = Form.useForm();

  const validateEndDate = (_: RuleObject, value: Dayjs | undefined) => {
    const startDate = formData.getFieldValue("start_at")
      ? dayjs(formData.getFieldValue("start_at") as string)
      : undefined;
    if (value && startDate && dayjs(value).isBefore(startDate)) {
      return Promise.reject(new Error("End date must be after start date"));
    }
    return Promise.resolve();
  };

  const hasDefaultValue = type !== "create";

  const [viTitle, setViTitle] = useState(
    hasDefaultValue && defaultValues?.title ? defaultValues.title : undefined,
  );
  const [enTitle, setEnTitle] = useState(
    hasDefaultValue && defaultValues?.en_title
      ? defaultValues.en_title
      : undefined,
  );

  const [isWarningTitle, setIsWarmingTitle] = useState(false);

  const onChangeViTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViTitle(e.target.value);
  };
  const onChangeEnTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnTitle(e.target.value);
  };
  return (
    <>
      <style>
        {`
      .ant-picker-input input[disabled] {
        color: black !important;
      }
      .ant-checkbox-disabled + span {
      color: black !important;  
      font-weight: 600;
      }
      .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
      color: black !important;
      }
    `}
      </style>
      <div className="bg-white">
        <div className="flex justify-between px-6 pb-1.5 pt-4">
          <Title level={4}>{title}</Title>
          {!isEditMode && (
            <Button
              type="link"
              className="text-brand"
              icon={<EditOutlined />}
              onClick={() => {
                setEditMode(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <Divider className="!m-0" />
        <div className="p-6">
          <Form
            form={formData}
            layout="vertical"
            name="normal_login"
            className="grid grid-cols-2 gap-x-4"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="en_title"
              label="EN-Title"
              rules={
                enTitle
                  ? [
                      {
                        required: true,
                        message: "EN Title is require!",
                      },
                    ]
                  : undefined
              }
              initialValue={
                hasDefaultValue && defaultValues?.en_title
                  ? defaultValues.en_title
                  : undefined
              }
            >
              <Input
                value={enTitle}
                onChange={onChangeEnTitle}
                className={hasDefaultValue ? "!text-gray-800" : ""}
                disabled={!isEditMode}
                placeholder="Edit here..."
              />
            </Form.Item>
            <Form.Item
              name="title"
              label="VI-Title"
              initialValue={
                hasDefaultValue && defaultValues?.title
                  ? defaultValues.title
                  : undefined
              }
              rules={
                viTitle
                  ? [
                      {
                        required: true,
                        message: "Vietnamese Title is require!",
                      },
                    ]
                  : undefined
              }
            >
              <Input
                className="!text-gray-800"
                value={viTitle}
                onChange={onChangeViTitle}
                disabled={!isEditMode}
                placeholder="Edit here..."
              />
            </Form.Item>
            <div className="col-span-2 -mt-2 mb-4">
              {!viTitle && !enTitle && isWarningTitle && (
                <span className="text-red-600">
                  Either VN title or EN title is required!
                </span>
              )}
            </div>
            <Form.Item
              label="Start Date"
              name="start_at"
              rules={[{ required: true, message: "Start date is required!" }]}
              initialValue={
                hasDefaultValue && defaultValues?.start_at
                  ? dayjs(defaultValues.start_at)
                  : undefined
              }
            >
              <DatePicker
                className="w-full"
                disabled={!isEditMode}
                showTime
                onChange={(value) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const endAt = formData.getFieldValue("end_at");
                  if (!endAt) {
                    formData.setFieldsValue({ end_at: value }); // Reset end_at when start_at changes
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label="End Date"
              name="end_at"
              dependencies={["start_at"]}
              rules={[
                { validator: validateEndDate },
                {
                  required: true,
                  message: "End date is required!",
                },
              ]}
              initialValue={
                hasDefaultValue && defaultValues?.end_at
                  ? dayjs(defaultValues.end_at)
                  : undefined
              }
            >
              <DatePicker
                className="w-full"
                disabled={!isEditMode}
                showTime
                disabledDate={(current: Dayjs | null) => {
                  const startAt = formData.getFieldValue("start_at") as
                    | Dayjs
                    | undefined;
                  return !!(
                    current &&
                    startAt &&
                    current.isBefore(dayjs(startAt).startOf("day"))
                  );
                }}
                disabledTime={(current: Dayjs | null) => {
                  const startAt = formData.getFieldValue("start_at") as
                    | Dayjs
                    | undefined;
                  if (startAt && current?.isSame(startAt, "day")) {
                    return {
                      disabledHours: () => [...Array(startAt.hour()).keys()],
                      disabledMinutes: () => [
                        ...Array(startAt.minute() + 1).keys(),
                      ],
                      disabledSeconds: () => [
                        ...Array(startAt.second() + 1).keys(),
                      ],
                    };
                  }
                  return {};
                }}
              />
            </Form.Item>

            {defaultValues?.external_id && (
              <>
                <Form.Item
                  name="en_url"
                  label="EN-URL"
                  initialValue={
                    hasDefaultValue && defaultValues.en_url
                      ? defaultValues.en_url
                      : undefined
                  }
                >
                  <TextArea
                    className="!text-gray-800"
                    rows={4}
                    disabled={!isEditMode}
                  />
                </Form.Item>
                <Form.Item
                  name="url"
                  label="VN-URL"
                  rules={[
                    { required: true, message: "Vietnam URL is require!" },
                  ]}
                  initialValue={
                    hasDefaultValue && defaultValues.url
                      ? defaultValues.url
                      : undefined
                  }
                >
                  <TextArea
                    rows={4}
                    className="!text-gray-800"
                    disabled={!isEditMode}
                  />
                </Form.Item>
              </>
            )}

            {!defaultValues?.external_id && (
              <>
                <Form.Item
                  className="col-span-2"
                  name="speakers"
                  label="Speakers"
                  initialValue={
                    hasDefaultValue && defaultValues?.speakers
                      ? defaultValues.speakers
                      : undefined
                  }
                >
                  <Select
                    mode="multiple"
                    disabled={!isEditMode}
                    placeholder="Please select speakers"
                    options={optionsSpeakers}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>

                <Form.Item
                  label="EN-Description"
                  name="en_content"
                  rules={
                    enTitle
                      ? [
                          {
                            required: true,
                            message: "EN Description is require!",
                          },
                        ]
                      : undefined
                  }
                  initialValue={
                    hasDefaultValue && defaultValues?.en_content
                      ? defaultValues.en_content
                      : undefined
                  }
                >
                  <Editor
                    initialData={
                      hasDefaultValue && defaultValues?.en_content
                        ? defaultValues.en_content
                        : undefined
                    }
                    onChange={handleEnEditorChange}
                    data={enDescription}
                    disabled={!isEditMode}
                  ></Editor>
                </Form.Item>
                <Form.Item
                  label="VI-Description"
                  name="content"
                  rules={
                    viTitle
                      ? [
                          {
                            required: true,
                            message: "Vietnamese Description is require!",
                          },
                        ]
                      : undefined
                  }
                  initialValue={
                    hasDefaultValue && defaultValues?.content
                      ? defaultValues.content
                      : undefined
                  }
                >
                  <Editor
                    initialData={
                      hasDefaultValue && defaultValues?.content
                        ? defaultValues.content
                        : undefined
                    }
                    data={viDescription}
                    onChange={handleViEditorChange}
                    disabled={!isEditMode}
                  ></Editor>
                </Form.Item>
                <div className="col-span-2">
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      { required: true, message: "Location is require!" },
                    ]}
                    initialValue={
                      hasDefaultValue && defaultValues?.location
                        ? defaultValues.location
                        : undefined
                    }
                  >
                    <TextArea
                      className="!text-gray-800"
                      rows={3}
                      disabled={!isEditMode}
                    />
                    {/*
                    <Editor
                      initialData={
                        hasDefaultValue && defaultValues?.location
                          ? defaultValues.location
                          : undefined
                      }
                      data={locationContent}
                      onChange={handleLocationEditorChange}
                      disabled={!isEditMode}
                    />
                    */}
                  </Form.Item>
                </div>
              </>
            )}

            {hasDefaultValue && !isEditMode && (
              <div className="col-span-2 flex gap-x-4">
                <Form.Item
                  name="event_source"
                  label="Event Source"
                  initialValue={
                    defaultValues?.external_id ? "External" : "Internal"
                  }
                  className="flex-1"
                >
                  <Input className="!text-gray-800" disabled={true} />
                </Form.Item>
              </div>
            )}

            <Form.Item
              name="event_types"
              label="Event Type"
              initialValue={
                hasDefaultValue && defaultValues?.event_types
                  ? defaultValues.event_types
                  : undefined
              }
            >
              <Select
                mode="multiple"
                className={hasDefaultValue ? "!text-gray-800" : ""}
                placeholder="Select event type"
                disabled={!isEditMode}
                options={optionsEventTypes}
                filterOption={filterSelectOptions}
              />
            </Form.Item>

            <Form.Item
              name="colleges"
              label={t("menu.college")}
              className="flex-1"
              initialValue={
                (
                  defaultValues as
                    | (Event & {
                        colleges?: TargetCustomerColleges[];
                      })
                    | undefined
                )?.colleges
              }
            >
              <Select
                mode="multiple"
                placeholder={t("menu.select", { what: t("menu.college") })}
                disabled={!isEditMode}
                options={colleges.map((item) => ({
                  label: item.label,
                  value: item.label,
                }))}
                filterOption={filterSelectOptions}
              />
            </Form.Item>

            <div className="col-span-2 flex gap-x-20">
              <Form.Item
                name="published"
                valuePropName="checked"
                initialValue={defaultValues?.published}
              >
                <Checkbox className="!text-gray-800" disabled={!isEditMode}>
                  Published
                </Checkbox>
              </Form.Item>
              {!defaultValues?.external_id && (
                <Form.Item
                  name="read_only"
                  valuePropName="checked"
                  initialValue={defaultValues?.read_only}
                >
                  <Checkbox
                    className={hasDefaultValue ? "!text-gray-800" : ""}
                    disabled={!isEditMode}
                  >
                    Read-Only
                  </Checkbox>
                </Form.Item>
              )}
              <Form.Item
                name="is_highlight"
                valuePropName="checked"
                initialValue={defaultValues?.is_highlight}
              >
                <Checkbox
                  className={hasDefaultValue ? "!text-gray-800" : ""}
                  disabled={!isEditMode}
                >
                  Hightlight
                </Checkbox>
              </Form.Item>
              {hasDefaultValue && (
                <Form.Item
                  name="status"
                  valuePropName="checked"
                  initialValue={defaultValues?.status === "CANCELLED"}
                >
                  <Checkbox className="!text-gray-800" disabled={!isEditMode}>
                    Cancel
                  </Checkbox>
                </Form.Item>
              )}
            </div>
            {!defaultValues?.external_id && (
              <Form.Item
                name="agendas"
                initialValue={hasDefaultValue ? transformedAgendas : undefined}
                className="col-span-2 mb-4"
              >
                <Form.List name="agendas">
                  {(fields, { add, remove }) => (
                    <>
                      <Card
                        title="Within-Day Agendas"
                        size="small"
                        extra={
                          isEditMode ? (
                            <Button
                              className="border-brand bg-brand"
                              type="primary"
                              onClick={() => {
                                add();
                              }}
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          ) : undefined
                        }
                      >
                        {fields.length < 1 && (
                          <div className="flex justify-center">
                            <img src="/NoData.svg" alt="No Data" />
                          </div>
                        )}
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <div key={key} className="grid grid-cols-2 gap-x-6">
                              <Form.Item
                                {...restField}
                                label="Start Time"
                                className="w-full"
                                name={[name, "start_time"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing start time",
                                  },
                                ]}
                              >
                                <TimePicker
                                  showNow={false}
                                  disabled={!isEditMode}
                                  className="w-full"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="End Time"
                                className="w-full"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing end time",
                                  },
                                ]}
                                name={[name, "end_time"]}
                              >
                                <TimePicker
                                  showNow={false}
                                  disabled={!isEditMode}
                                  className="w-full"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="VN-Subject"
                                className="w-full"
                                name={[name, "activity"]}
                                rules={
                                  viTitle
                                    ? [
                                        {
                                          required: true,
                                          message: "Missing VN subject",
                                        },
                                      ]
                                    : undefined
                                }
                              >
                                <Input
                                  className={
                                    hasDefaultValue ? "!text-gray-800" : ""
                                  }
                                  disabled={!isEditMode}
                                  placeholder="Edit here..."
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="EN-Subject"
                                className="w-full"
                                name={[name, "en_activity"]}
                                rules={
                                  enTitle
                                    ? [
                                        {
                                          required: true,
                                          message: "Missing EN subject",
                                        },
                                      ]
                                    : undefined
                                }
                              >
                                <Input
                                  className={
                                    hasDefaultValue ? "!text-gray-800" : ""
                                  }
                                  disabled={!isEditMode}
                                  placeholder="Edit here..."
                                />
                              </Form.Item>

                              <Form.Item
                                {...restField}
                                label="VN-Description"
                                className="w-full"
                                name={[name, "detail"]}
                              >
                                <Editor
                                  initialData={
                                    hasDefaultValue
                                      ? transformedAgendas[key]?.detail
                                      : undefined
                                  }
                                  disabled={!isEditMode}
                                ></Editor>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="EN-Description"
                                className="w-full"
                                name={[name, "en_detail"]}
                              >
                                <Editor
                                  initialData={
                                    hasDefaultValue
                                      ? transformedAgendas[key]?.en_detail
                                      : undefined
                                  }
                                  disabled={!isEditMode}
                                ></Editor>
                              </Form.Item>
                              {isEditMode && (
                                <div className="col-span-2">
                                  <Button
                                    className="float-right"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      remove(name);
                                    }}
                                    type="primary"
                                    danger
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                            {fields.length > 1 && (
                              <Divider className="mb-4" dashed />
                            )}
                          </>
                        ))}
                      </Card>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}

            <div className="col-span-2 w-fit">
              <Form.Item
                label={
                  <div>
                    <span className="text-red-600">*</span> Thumbnail
                  </div>
                }
              >
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  disabled={!isEditMode || !!defaultValues?.external_id}
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                  accept={SUPPORTED_IMAGE_TYPES.join(",")}
                  onChange={handleChange}
                  onRemove={() => {
                    setFileList([]);
                  }}
                >
                  {isEditMode && !defaultValues?.external_id && (
                    <div>
                      {fileList.length > 0 ? (
                        <>
                          <EditOutlined />
                          <div>Edit</div>
                        </>
                      ) : (
                        <>
                          <PlusOutlined />
                          <div>Upload</div>
                        </>
                      )}
                    </div>
                  )}
                </Upload>
              </Form.Item>
              {fileList.length === 0 && (
                <div className="-mt-4 mb-4">
                  {!isEditMode ? (
                    <img className="-mt-8" src="/NoThumbnail.svg" alt="" />
                  ) : (
                    <div>
                      {warningThumnbnail && (
                        <span className="text-red-600">
                          Thumbnail is require!
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}

              <Modal
                open={previewOpen}
                title="Preview Thumbnail"
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="previewImage"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            {!defaultValues?.external_id && (
              <div className="col-span-2">
                <Form.Item
                  name="contact"
                  label="Contact"
                  rules={[{ required: true, message: "Contact is required!" }]}
                  initialValue={
                    hasDefaultValue && defaultValues?.contact
                      ? defaultValues.contact
                      : undefined
                  }
                >
                  <Editor
                    initialData={
                      hasDefaultValue && defaultValues?.contact
                        ? defaultValues.contact
                        : undefined
                    }
                    onChange={handleContactEditorChange}
                    data={contactContent}
                    disabled={!isEditMode}
                  ></Editor>
                </Form.Item>
              </div>
            )}

            <div className="col-span-2">
              <Form.Item className="float-end">
                <Button
                  onClick={() => {
                    void navigate({ to: "/events" });
                  }}
                  className={cn(!isEditMode ? "bg-brand" : "", "w-[114px]")}
                  type={isEditMode ? "default" : "primary"}
                  size="large"
                >
                  {t("menu.close")}
                </Button>
                {isEditMode && (
                  <Button
                    className="ml-5 border-brand bg-brand w-[114px]"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    {t("menu.save")}
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EventForm;
