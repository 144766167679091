import { createFileRoute } from "@tanstack/react-router";
import { t } from "i18next";

export const Route = createFileRoute(
  "/_auth/_dashboard/oasis/skill/_workshops",
)({
  beforeLoad: () => {
    return {
      title: t("menu.OASIS.workshops", { ns: "dashboard" }),
    };
  },
});
