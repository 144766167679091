import { keepPreviousData } from "@tanstack/react-query";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { OASISCohort } from "@/types";
import { OasisProgramParams } from "@/types/ApiParams";

type ListFilterState = OasisProgramParams<"getListCohorts">;
type DetailCohortFilterState = OasisProgramParams<"getOasisProgramProfiles">;
type OrientationFilterState =
  OasisProgramParams<"getListOrientationTestsWithPagination">;

export type AdvisorFilterState =
  OasisProgramParams<"getListAdvisorsWithPagination">;
export type AdvisingGroupsFilterState =
  OasisProgramParams<"getListAdvisingGroupsWithPagination">;
export type WorkshopsFilterState =
  OasisProgramParams<"getListWorkshopsWithPagination">;
export type SkillsFilterState =
  OasisProgramParams<"getListSkillsWithPagination">;

const OASIS = "OASIS";
const createOASISQuery = createQuery(OASIS);

export const oasisQueries = {
  list: createOASISQuery(fetchApiClient.oasisPrograms.getListCohorts, {
    placeholderData: keepPreviousData,
  }),
  details: createOASISQuery(
    fetchApiClient.oasisPrograms.getOasisProgramProfiles,
    { placeholderData: keepPreviousData },
  ),
  orientation: createOASISQuery(
    fetchApiClient.oasisPrograms.getListOrientationTestsWithPagination,
    (params) => ({
      placeholderData: keepPreviousData,
      enabled: !!params?.cohort,
    }),
  ),
  skills: createOASISQuery(
    fetchApiClient.oasisPrograms.getListSkillsWithPagination,
    { placeholderData: keepPreviousData },
  ),
  workshops: createOASISQuery(
    fetchApiClient.oasisPrograms.getListWorkshopsWithPagination,
    { placeholderData: keepPreviousData },
  ),
  allAdvisors: createOASISQuery(fetchApiClient.oasisPrograms.getAllAdvisors),
  advisors: createOASISQuery(
    fetchApiClient.oasisPrograms.getListAdvisorsWithPagination,
    { placeholderData: keepPreviousData },
  ),
  advisorDetail: createOASISQuery(fetchApiClient.oasisPrograms.getAnAdvisor),
  advisingGroups: createOASISQuery(
    fetchApiClient.oasisPrograms.getListAdvisingGroupsWithPagination,
    { placeholderData: keepPreviousData },
  ),
  advisingGroup: createOASISQuery(
    fetchApiClient.oasisPrograms.getAnAdvisingGroup,
  ),
  allMembers: createOASISQuery(fetchApiClient.accounts.getAllPersonalProfiles),
};

export const oasisProgramAtomFilter = atomWithDebounce<ListFilterState>({
  page: 1,
  search: "",
});

export const oasisProgramDetailAtomFilter =
  atomWithDebounce<DetailCohortFilterState>({
    page: 1,
  });

export const oasisOrientationFilter = atomWithDebounce<OrientationFilterState>({
  page: 1,
});

export const oasisSkillsFilter = atomWithDebounce<SkillsFilterState>({
  page: 1,
});

export const oasisWorkshopsFilter = atomWithDebounce<
  Omit<NonNullable<WorkshopsFilterState>, "skill"> | undefined
>({
  page: 1,
});

export const oasisAdvisorsFilter = atomWithDebounce<AdvisorFilterState>({
  page: 1,
});

export const oasisAdvisingGroupsFilter =
  atomWithDebounce<AdvisingGroupsFilterState>({
    page: 1,
  });

export const oasisAtoms = {
  listFilter: oasisProgramAtomFilter,
  list: atomWithQuery((get) =>
    oasisQueries.list(get(oasisProgramAtomFilter.debouncedValueAtom)),
  ),
  orientationFilter: oasisOrientationFilter,
  detailsFilter: oasisProgramDetailAtomFilter,
  details: atomFamily((cohort: number) =>
    atomWithQuery((get) => {
      const { page = 1, search = "" } =
        get(oasisProgramDetailAtomFilter.debouncedValueAtom) ?? {};
      return oasisQueries.details({ cohort, page, search });
    }),
  ),
  orientation: atomWithQuery((get) =>
    oasisQueries.orientation(get(oasisOrientationFilter.debouncedValueAtom)),
  ),
  cohort: atom<OASISCohort>(),
  skillsFilter: oasisSkillsFilter,
  skills: atomWithQuery((get) =>
    oasisQueries.skills(get(oasisSkillsFilter.debouncedValueAtom)),
  ),
  workshopsFilter: oasisWorkshopsFilter,
  workshops: atomFamily((skill: number) => {
    return atomWithQuery((get) => {
      return oasisQueries.workshops({
        skill,
        ...get(oasisWorkshopsFilter.debouncedValueAtom),
      });
    });
  }),
  allAdvisors: atomWithQuery(() => oasisQueries.allAdvisors(undefined)),
  advisorsFilter: oasisAdvisorsFilter,
  advisors: atomWithQuery((get) =>
    oasisQueries.advisors(get(oasisAdvisorsFilter.debouncedValueAtom)),
  ),
  advisorDetails: atomFamily((id: string) =>
    atomWithQuery(() => oasisQueries.advisorDetail(Number(id))),
  ),
  advisingGroupsFilter: oasisAdvisingGroupsFilter,
  advisingGroups: atomWithQuery((get) =>
    oasisQueries.advisingGroups(
      get(oasisAdvisingGroupsFilter.debouncedValueAtom),
    ),
  ),
  advisingGroup: atomFamily((id: string) =>
    atomWithQuery(() => oasisQueries.advisingGroup(Number(id))),
  ),
  allMembers: atomWithQuery(() => oasisQueries.allMembers(undefined)),
};
