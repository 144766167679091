import { LoaderType } from "@/main";

import { feedbackAtoms, feedbackQueries } from "./store";

export const feedbackListLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    feedbackQueries.list(
      globalStore.get(feedbackAtoms.filter.currentValueAtom),
    ),
  );
};

export const feedbackDetailLoader = ({
  context: { queryClient },
  params: { id },
}: LoaderType<{ id: string }>) => {
  void queryClient.ensureQueryData(feedbackQueries.details(id));
};
