import { LoaderType } from "@/main";

import { serviceRequestAtoms, serviceRequestQueries } from "./store";

export const serviceRequestLoader = ({
  context: { queryClient, globalStore },
}: LoaderType) => {
  void queryClient.ensureQueryData(
    serviceRequestQueries.list(
      globalStore.get(serviceRequestAtoms.filter.currentValueAtom),
    ),
  );
};
