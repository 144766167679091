import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WritableAtom } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/react/utils";
import { type ReactNode } from "react";

import { queryClient } from "@/lib/config/queryClient";

function AtomsHydrator({
  atomValues,
  children,
}: {
  atomValues: Iterable<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  >;
  children: ReactNode;
}) {
  // Binding  useAuth0() to globalStore for usages outside of React components
  useHydrateAtoms([...atomValues]);
  return children;
}

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AtomsHydrator atomValues={[[queryClientAtom, queryClient]]}>
        {children}
      </AtomsHydrator>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
