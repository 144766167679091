import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { WebAdvisorAdd } from "@/types/Api";

import AdvisorDetailForm from "../-components/DetailsForms/AdvisorDetailForm";
import { advisorResolver } from "../-data";
import { useCreateOASISAdvisor } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/oasis/advisor/add")({
  component: () => <AdvisorCreatePage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function AdvisorCreatePage() {
  const form = useForm<WebAdvisorAdd>({
    defaultValues: {
      name: "",
      email: "",
    },
    resolver: advisorResolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOASISAdvisor();

  const handleSubmit = (params: WebAdvisorAdd) => {
    mutate(params, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.OASIS.advisor", "advisor").toLowerCase(),
          }),
        });
        void navigate({ to: "/oasis/advisor" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.OASIS.advisor", "advisor").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <AdvisorDetailForm
        isEdit
        onClose={() => void navigate({ to: "/oasis/advisor" })}
        title={t("menu.add")}
        form={form}
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
      />
    </MainDashboard>
  );
}
