import React, { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

type Props = {
  children?: React.ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Stack = ({ children, className, ...props }: Props) => {
  return (
    <div
      className={cn("flex items-center justify-center", className)}
      {...props}
    >
      {children}
    </div>
  );
};
