import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "@tanstack/react-router";
import { Button, Modal, notification, Table } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader } from "@/components";
import { useOnRowTable } from "@/hooks/useOnRowTable";

import {
  useDeleteSpeaker,
  useListSpeaker,
  useListSpeakerFilter,
} from "../../-data/hooks";

const SpeakerList = () => {
  const { confirm } = Modal;
  const { data, isFetching } = useListSpeaker();
  const { mutate: deleteSpeaker } = useDeleteSpeaker();
  const onRow = useOnRowTable({ to: "/events/speakers/$id" });

  const handleDeleteSpeaker = (id: number | undefined) => {
    if (id) {
      confirm({
        title: "Are you sure delete this task?",
        icon: <ExclamationCircleOutlined />,
        content: "This action will permanently remove a speaker.",
        onOk() {
          deleteSpeaker(+id, {
            onSuccess: () => {
              notification.success({
                message: "Success",
                description: "Delete speaker successfully",
              });
              void navigate({ to: "/events/speakers" });
            },
            onError: (error) => {
              notification.error({
                message: "Error",
                description: `Delete event speaker failed: ${(error as Error | undefined)?.message ?? ""}`,
              });
              void navigate({ to: "/events/speakers" });
            },
          });
          return;
        },
        onCancel() {
          return;
        },
      });
    }
  };
  const { setEventSpeakerFilter, eventSpeakerFilter } = useListSpeakerFilter();
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <div className="bg-white p-6 py-4">
      <ListHeader
        search={eventSpeakerFilter?.search}
        onChange={(search) => {
          setEventSpeakerFilter((prev) => {
            return {
              ...prev,
              page: 1,
              search,
            };
          });
        }}
        onAddNewClick={() => {
          void navigate({ to: "/events/speakers/add" });
        }}
        title={t("menu.speaker.list")}
        placeholder="Search Name"
      />
      <Table
        dataSource={dataSource}
        onRow={onRow}
        loading={isFetching}
        columns={[
          {
            title: "ID",
            key: "id",
            width: "1ch",
            render: (_, record) => {
              return (
                <Link to={`/events/speakers/${record.id?.toString() ?? ""}`}>
                  {record.id}
                </Link>
              );
            },
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => {
              return <span>{record.name}</span>;
            },
          },
          {
            title: "Email",
            key: "email",
            render: (_, record) => {
              return <span>{record.email}</span>;
            },
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => {
              return (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteSpeaker(record.id);
                  }}
                  icon={<DeleteOutlined />}
                  danger
                ></Button>
              );
            },
          },
        ]}
        pagination={{
          position: ["bottomCenter"],
          total: data?.count,
          showSizeChanger: false,
          disabled: isFetching,
          current: eventSpeakerFilter?.page,
          onChange: (page) => {
            setEventSpeakerFilter((prev) => {
              return {
                ...prev,
                page,
              };
            });
          },
        }}
      />
    </div>
  );
};
export default SpeakerList;
