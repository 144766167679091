import { Upload } from "antd";
import { UploadProps } from "antd/lib/upload";

export const UploadPdf = ({ fileList, ...props }: UploadProps) => {
  return (
    <div>
      <Upload accept="application/pdf" fileList={fileList} {...props} />
    </div>
  );
};
