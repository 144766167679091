import { createFileRoute } from "@tanstack/react-router";

import { groupEventLoader } from "../-data/loaders";
import { MainDashboard } from "../../../../../components/MainDashboard";
import GroupEventList from "./-components/GroupEventList";

export const Route = createFileRoute("/_auth/_dashboard/events/groups/")({
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  component: () => (
    <MainDashboard>
      <GroupEventList />
    </MainDashboard>
  ),
  loader: groupEventLoader,
});
