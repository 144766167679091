import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import * as React from "react";

import { DatePicker } from "./DatePicker";

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker"> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TimePicker = React.forwardRef<any, TimePickerProps>(
  (props, ref) => {
    return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
  },
);

TimePicker.displayName = "TimePicker";
