import { createFileRoute, Link } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";

import FeedbackListHeader from "../-components/FeedbackListHeader";
import { FEEDBACK_RATING, FEEDBACK_SUBJECT_SET } from "../-data";
import { useFeedbacks, useFeedbacksFilter } from "../-data/hooks";
import { feedbackListLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/feedbacks/_list/")({
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: feedbackListLoader,
  component: () => <FeedBackPage />,
});

function FeedBackPage() {
  const { data, isFetching } = useFeedbacks();
  const { feedbackListState, setFeedbackListState } = useFeedbacksFilter();
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/feedbacks/$id" });
  const { handleSearchChange, handlePaginateChange } =
    useCreateHandleFilterFunc(setFeedbackListState);

  const feedbackSubject = feedbackListState?.subject ?? [];
  const feedbackRating = feedbackListState?.rating ?? [];
  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <FeedbackListHeader
          isLoading={isFetching}
          onSearch={handleSearchChange}
          onChange={handleSearchChange}
          onApplyFilter={(filter) => {
            setFeedbackListState((prev) => {
              return {
                ...prev,
                subject: [...(filter.feedbackType ?? [])],
                rating: [...(filter.rating ?? [])],
              };
            });
          }}
          onResetFilter={() => {
            setFeedbackListState((prev) => {
              return {
                ...prev,
                subject: undefined,
                rating: undefined,
                page: 1,
              };
            });
          }}
          shouldDisableResetBtn={
            feedbackSubject.length === 0 && feedbackRating.length === 0
          }
          search={feedbackListState?.search}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: feedbackListState?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/feedbacks/$id"
                    params={{ id: record.id?.toString() ?? "" }}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.feedback.description"),
              key: "requestTitle",
              width: "50%",
              render: (_, record) => {
                return <Typography.Text>{record.description}</Typography.Text>;
              },
            },
            {
              title: t("menu.subject"),
              key: "subject",
              render: (_, record) => {
                return (
                  <span>{FEEDBACK_SUBJECT_SET.get(record.subject)?.label}</span>
                );
              },
            },
            {
              title: t("menu.feedback.rating"),
              key: "rating",
              render: (_, record) => {
                return (
                  <span>
                    {record.rating && FEEDBACK_RATING[record.rating - 1].label}
                  </span>
                );
              },
            },
            {
              title: t("menu.feedback.sentDate"),
              key: "sent_date",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.created_at)}</span>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
