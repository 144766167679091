import { createFileRoute, Link } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex } from "@/lib/utils";

import SkillsHeader from "../-components/ListHeaders/SkillsHeader";
import { useOASISSkills, useOASISSkillsFilter } from "../-data/hooks";
import { OASISSkillsLoader } from "../-data/loaders";

export const Route = createFileRoute("/_auth/_dashboard/oasis/skill/")({
  component: () => <OASISSkillPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
  loader: OASISSkillsLoader,
});

function OASISSkillPage() {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useOASISSkills();
  const { filter, setFilter } = useOASISSkillsFilter();
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const onRow = useOnRowTable({ to: "/oasis/skill/$id" });

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <SkillsHeader onSearchChange={handleSearchChange} />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/skill/$id"
                    params={{ id: String(record.id) }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.name"),
              key: "name",
              render: (_, record) => {
                return <Typography.Text>{record.name ?? "-"}</Typography.Text>;
              },
            },
            {
              title: t("menu.cohort"),
              key: "cohort",
              render: (_, record) => {
                return <Typography.Text>{record.cohort}</Typography.Text>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
