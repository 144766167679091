import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Table, Typography } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { formatFullDateTime } from "@/lib/utils";
import { NewCategory, News } from "@/types/Api";

import NewListHeader from "../-components/NewListHeader";
import { useAllCategories, useListNews } from "../-data/hooks";
import { newsListLoader } from "../-data/loaders";
import { newsAtoms } from "../-data/store";

export const Route = createFileRoute("/_auth/_dashboard/news/_list/")({
  loader: newsListLoader,
  component: () => <ListNewsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function ListNewsPage() {
  const { data, isFetching } = useListNews();
  const { data: categories } = useAllCategories();
  const setDebounceListFilter = useSetAtom(newsAtoms.filter.debouncedValueAtom);
  const newsListFilterState = useAtomValue(newsAtoms.filter.currentValueAtom);
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/news/$id" });

  const handlePaginateClick = (page: number) => {
    setDebounceListFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const handleSearch = (value: string) => {
    setDebounceListFilter((prev) => {
      return {
        ...prev,
        search: value,
        page: 1,
      };
    });
  };

  const dataSource = (data?.results ?? []).map((item) => ({
    ...item,
    key: item.id,
  }));

  const mapCategoriesToString = (categories: News["categories"]) => {
    return categories?.map((item) => item.en_name).join(", ");
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <NewListHeader
          isLoading={isFetching}
          onChange={handleSearch}
          onApplyFilter={(filter) => {
            setDebounceListFilter((prev) => {
              return {
                ...prev,
                categories: [...(filter.categories ?? [])],
              };
            });
          }}
          onResetFilter={() => {
            setDebounceListFilter((prev) => {
              return {
                ...prev,
                page: 1,
                category: undefined,
              };
            });
          }}
          shouldDisableResetBtn={
            newsListFilterState?.categories?.length === 0 &&
            newsListFilterState.source?.length === 0
          }
          search={newsListFilterState?.search}
          categories={categories as NewCategory[]}
        />
        <Table
          loading={isFetching}
          onRow={onRow}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: newsListFilterState?.page,
            onChange: handlePaginateClick,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "ID",
              key: "id",
              width: "1ch",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to={`/news/${record.id?.toString() ?? ""}`}
                  >
                    {record.id}
                  </Link>
                );
              },
            },
            {
              title: t("menu.news.newsTitle"),
              key: "title",
              width: "30%",
              render: (_, record) => {
                return (
                  <Typography.Text>
                    {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                    {record.en_title || record.title || "-"}
                  </Typography.Text>
                );
              },
            },
            {
              title: t("menu.news.author"),
              key: "author",
              render: (_, { created_by }) => {
                return (
                  <span className="text-nowrap">
                    {(created_by?.first_name ?? "") +
                      " " +
                      (created_by?.last_name ?? "")}
                  </span>
                );
              },
            },
            {
              title: t("menu.news.categories"),
              key: "categories",
              render: (_, record) => {
                const shouldEllipse = (record.categories?.length ?? 0) > 3;
                return (
                  <span>
                    {mapCategoriesToString(
                      shouldEllipse
                        ? record.categories?.slice(0, 2)
                        : record.categories,
                    )?.concat(shouldEllipse ? "..." : "")}
                  </span>
                );
              },
            },
            {
              title: t("menu.news.source"),
              key: "source",
              render: (_, record) => {
                return (
                  <span>
                    {record.external_id ?? record.en_external_id
                      ? t("menu.external")
                      : t("menu.internal")}
                  </span>
                );
              },
            },
            {
              title: t("menu.updatedDate"),
              key: "updated_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.updated_at)}</span>;
              },
            },
            {
              title: t("menu.published"),
              key: "published",
              className: "!text-center",
              render: (_, record) => {
                return (
                  <div className="flex justify-center">
                    {record.published ? (
                      <CheckCircleFilled className="text-2xl text-[#52C41A]" />
                    ) : (
                      <CloseCircleFilled className="text-2xl text-[#999999]" />
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
