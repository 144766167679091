import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { UpdateTargetCustomerRequest } from "@/types/Api";

import TargetCustomerForm from "../../../-components/TargetCustomerForm";
import {
  useTargetCustomerForm,
  useUpdateTargetCustomer,
} from "../../../-data/hooks";
import { targetCustomerDetailsLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/customers/_list/(details)/$id/edit",
)({
  loader: targetCustomerDetailsLoader,
  component: () => <TargetCustomerEditPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function TargetCustomerEditPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/customers/_list/$id/edit",
  });
  const { targetCustomerDetails, form, cohorts, college, users } =
    useTargetCustomerForm(id);
  const { mutate: updateTargetCustomer, isPending } = useUpdateTargetCustomer();
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const handleClose = () => {
    void navigate({ to: "/customers" });
  };

  const handleSubmit = (data: UpdateTargetCustomerRequest) => {
    if (targetCustomerDetails?.id) {
      updateTargetCustomer(
        {
          ...data,
          colleges: data.colleges ?? [],
          roles: data.roles ?? [],
          id: targetCustomerDetails.id.toString(),
        },
        {
          onSuccess: () => {
            notification.success({
              message: t("menu.success"),
              description: t("menu.update.success", {
                what: t("menu.targetCustomer.targetCustomer"),
              }),
            });
            void navigate({ to: "/customers/$id", params: { id } });
          },
          onError: () => {
            notification.error({
              message: t("menu.error"),
              description: t("menu.update.error", {
                what: t("menu.targetCustomer.targetCustomer"),
              }),
            });
          },
        },
      );
    }
  };

  return (
    <MainDashboard>
      <TargetCustomerForm
        created_by={targetCustomerDetails?.created_by?.email ?? ""}
        title={t("menu.edit", { ns: "dashboard" })}
        form={form}
        colleges={college ?? []}
        cohorts={cohorts ?? []}
        users={users ?? []}
        isSubmitLoading={isPending}
        onClose={handleClose}
        onSubmit={handleSubmit}
        isEdit
      />
    </MainDashboard>
  );
}
