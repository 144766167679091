import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";

import { validateThumbnail } from "@/lib/utils";
import { NotificationFormType, NotiRecurrenceForm } from "@/types";
import { NotificationType } from "@/types/Api";

const notificationSchema = yup.object().shape(
  {
    title: yup.string().required("VN - Title is required!"),
    en_title: yup.string().required("EN - Title is required!"),
    content: yup.string().required("VN - Content is required!"),
    en_content: yup.string().required("EN - Content is required!"),
    type: yup.number().required("Notification Type is required!"),
    related_event: yup.number().when(["to_all_user", "target_customer"], {
      is: (to_all_user?: boolean, target_customer?: number) =>
        !to_all_user && !target_customer,
      then: (schema) => schema.required("Related event is required!"),
      otherwise: (schema) => schema.nullable(),
    }),
    target_customer: yup.number().when(["to_all_user", "related_event"], {
      is: (to_all_user?: boolean, target_customer?: number) =>
        !to_all_user && !target_customer,
      then: (schema) => schema.required("Target customer is required!"),
      otherwise: (schema) => schema.nullable(),
    }),
    to_all_user: yup.bool().nullable(),
    start_date: yup.string().required("Start date is required!"),
    time: yup.string().required("Time is required!"),
  },
  [
    ["related_event", "to_all_user"],
    ["related_event", "target_customer"],
    ["to_all_user", "target_customer"],
  ],
);

const notiTypesSchema = yup.object().shape({
  name: yup.string().required("Title is required!"),
  en_name: yup.string().required("EN - Title is required!"),
  icon: yup
    .string()
    .required("Icon is required!")
    .test(
      "is-file-or-uri",
      "Icon must be a valid file or a valid URI",
      validateThumbnail,
    ),
});

const notiRecurrenceFormSchema = yup.object().shape({
  repeatEvery: yup
    .number()
    .required("Repeat every is required")
    .min(1, "Repeat every must be at least 1"),
  type: yup.string().required("Recurrence type is required"),
  weekdays: yup.array().required("Weekdays are required"),
  start: yup.string().required("Start date is required!"),
  end: yup
    .string()
    .required("End date is required!")
    .test(
      "start-greater-than-end",
      "Start date must be greater than end date",
      function (end) {
        const { start } = this.parent as NotiRecurrenceForm;
        if (start && end) {
          return new Date(start) < new Date(end);
        }
        return true;
      },
    ),
});

export const notificationResolver = yupResolver(
  notificationSchema,
) as unknown as Resolver<NotificationFormType>;
export const notiTypesResolver = yupResolver(
  notiTypesSchema,
) as Resolver<NotificationType>;
export const notiRecurrenceResolver = yupResolver(
  notiRecurrenceFormSchema,
) as Resolver<NotiRecurrenceForm>;
