import {
  createFileRoute,
  Navigate,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SupportRequest } from "@/types/Api";

import SupportRequestForm from "../-component/SupportRequestForm";
import { useListServiceRequest } from "../-data/hooks";
import { MainDashboard } from "../../../../../components/MainDashboard";

export const Route = createFileRoute(
  "/_auth/_dashboard/service-requests/_list/$id",
)({
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
  component: () => <SupportRequestEditPage />,
});

function SupportRequestEditPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/service-requests/_list/$id",
  });
  const { data } = useListServiceRequest();
  const supportRequestData = data?.results.find((item) => item.id === +id);

  const form = useForm<SupportRequest>({
    defaultValues: supportRequestData,
  });
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const handleClose = () => {
    void navigate({ to: "/service-requests" });
  };

  if (!supportRequestData) {
    return <Navigate to="/service-requests" />;
  }

  return (
    <MainDashboard>
      <SupportRequestForm
        form={form}
        title={t("menu.details")}
        onClose={handleClose}
      />
    </MainDashboard>
  );
}
