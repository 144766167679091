import { BadgeProps } from "antd";

import { SupportRequest } from "@/types/Api";

const StatusMapping: Record<
  NonNullable<SupportRequest["status"]> | "Canceled",
  BadgeProps["status"]
> = {
  "In-Progress": "processing",
  Closed: "default",
  Resolved: "success",
  New: "processing",
  Canceled: "error",
};

export const ProxyStatus = new Proxy(StatusMapping, {
  get(target, prop: keyof typeof StatusMapping) {
    const value = target[prop];
    return value ? value : target["In-Progress"];
  },
});
