import { useAtomValue, useSetAtom } from "jotai";

import { systemAtoms } from "../store";

export const useSystemCache = () => {
  return useAtomValue(systemAtoms.cache);
};

export const useSystemCacheFilter = () => {
  const setSystemCacheFilter = useSetAtom(
    systemAtoms.cacheFilter.debouncedValueAtom,
  );
  const systemCacheFilter = useAtomValue(
    systemAtoms.cacheFilter.currentValueAtom,
  );
  return { setSystemCacheFilter, systemCacheFilter };
};
