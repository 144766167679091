import { createFileRoute, redirect } from "@tanstack/react-router";

import { globalStore } from "@/jotai-atoms";
import { authAtom } from "@/jotai-atoms/oidc";

export const Route = createFileRoute("/_public/auth/loginCallback")({
  beforeLoad: async () => {
    // Auth guard
    const auth = globalStore.get(authAtom);
    await auth.signinCallback().catch((e: unknown) => {
      console.error(e);
    });

    return redirect({
      to: "/",
      from: location.pathname,
    });
  },
});
